import React, { Component } from 'react';
import Moment from 'moment';
import { Container, Modal } from 'react-bootstrap';
import { FaRegCalendarDays, FaRegClock } from 'react-icons/fa6';
import { GetText, ReplaceText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import CourseBlock from '../inc/course/Block';
import Loading from '../inc/Loading';
import '../config/global';

const authKey = global.config.kindcode.training[2]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[4]+global.config.authBearerKey; // tutor

function getCourseRelated(courseId,tags) {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","category":"'+tags+'","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json()
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      var inum=0;
      for (var i in resultJson.values) {
        var content = resultJson.values[i].content;
        content.id = resultJson.values[i].docid;
        if (content.id !== courseId) {
          resultData.push(content);
        }
        inum++;
        if(inum === resultJson.values.length) {
          resolve(resultData);
        }
      }
    });
  });
}
function getAgendaInfo(dataAgenda,courseDatez) {
  return new Promise((resolve) => {
    var agendas = dataAgenda;
    var contents = [];
    // var sTimez = "", eTimez = "";
      
    if (agendas.length > 0) {
      agendas.forEach((agenda, i) => {
        var content2 = agenda.subcontent;
        var date = DateTimeFormats(courseDatez._seconds,"date-long");
        var time = DateTimeFormats(content2.agendaTime._seconds,"time-24");
        var last = DateTimeFormats((content2.agendaTime._seconds + (content2.agendaDuration * 60)),"time-24");
        content2.id = agenda.subdocid;
        contents.push(content2);
        // if (i === 0) {
        //   sTimez = Moment(date + ' ' + time).format('YYYYMMDD[T]HHmmss[Z]');
        // }
        // if (i === (agendas.length - 1)) {
        //   eTimez = Moment(date + ' ' + last).format('YYYYMMDD[T]HHmmss[Z]');
        // }
        if((i+1) === agendas.length) {
          var agendaSend = {};
          agendaSend.agendacontent = contents;
          // agendaSend.startTime = sTimez;
          // agendaSend.endTime = eTimez;
          resolve(agendaSend);
        }
      });
    } else {
      var agendaSend = {};
      agendaSend.agendacontent = contents;
      // agendaSend.startTime = sTimez;
      // agendaSend.endTime = eTimez;
      resolve(agendaSend);
    }
  });
}
function getTutorInfo(tutors) {
  return new Promise((resolve) => {
    var tutorIds = tutors.split(',');
    var arrTutors = [];
    if (tutorIds.length > 0) {
      tutorIds.forEach((tutorId, i) => {
        fetch(global.config.urlLink+"/tutorview/" + tutorId, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2
          }
        })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log('Something happened wrong');
          }
        })
        .then(resultJson => {
          var tutor = resultJson.values[0].content[0].maincontent;
          tutor.tutorBio = ReplaceText(tutor.tutorBio);
          arrTutors.push(tutor);
          if((i+1) === tutorIds.length) {
            resolve(arrTutors);
          }
        });
      });
    } else {
      resolve(arrTutors);
    }
  });
}
async function getAllDatas(id,agendas,courseDatez,category,tutorsids) {
  const agendaresult = getAgendaInfo(agendas,courseDatez);
  const relatedresult = getCourseRelated(id,category);
  const tutorresult = getTutorInfo(tutorsids);
  
  const [thing3, thing4, thing5] = await Promise.all([agendaresult,relatedresult,tutorresult]);
  var arrReturnValue = {};
  arrReturnValue.agendadata = thing3;
  arrReturnValue.relateddata = thing4;
  arrReturnValue.tutordata = thing5;
  
  return arrReturnValue;
}

export default class CourseDetailGuest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      id: '',
      title: '',
      category: '',
      date: '',
      time: '',
      excerpt: '',
      video: '',
      image: '',
      price: '',
      link: '',
      tutors: [],
      agenda: [],
      courseRelated: [],
      button_expired: false,
      modal_show: false,
      isLoad: false
    }
  }

  openLanguagePage(contentLang) {
    var language = localStorage.getItem('language');
    if (language !== contentLang) {
      this.setState({
        modal_show: true
      });
    }
  }
  changeLanguage() {
    var currentLanguage = localStorage.getItem('language');
    var newLanguage = currentLanguage === "Indonesian" ? "English" : "Indonesian";
    localStorage.setItem('language', newLanguage);
    window.location.reload();
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);
    
    fetch(global.config.urlLink+"/courseview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      this.openLanguagePage(content.courseLanguage);

      getAllDatas(id,resultJson.values[0].scontent,content.courseDate,content.categoryName,content.tutorId)
      .then((response) => {
      
        var currentTime = Moment();
        var courseTime = Moment.unix(content.courseEndTime._seconds);
        var isExpired = currentTime.isAfter(courseTime);

        this.setState({
          id: resultJson.values[0].docid,
          title: content.courseTitle,
          category: content.categoryName,
          date: DateTimeFormats(content.courseDate._seconds,"date-long"),
          time: DateTimeFormats(content.courseStartTime._seconds,"time-24"),
          excerpt: ReplaceText(content.courseShortDesc),
          video: content.courseVideo,
          image: content.coursePicture,
          currency: content.courseCurrency,
          price: NumberFormats(content.coursePrice),
          link: '/course/' + resultJson.values[0].docid,
          tutors: response.tutordata,
          agenda: response.agendadata.agendacontent,
          courseRelated: response.relateddata,
          button_expired: isExpired,
          isLoad: true
            
          // agenda: contentAgenda
        });

      })

      // var agendas = resultJson.values[0].scontent;
      // var contentAgenda = [];
      // if (agendas.length > 0) {
      //   agendas.forEach(agenda => {
      //     var contentAg = agenda.subcontent;
      //     contentAg.id = agenda.subdocid;
      //     contentAgenda.push(contentAg);
      //   });                
      // }

      
    });
  }
  
  render() {
    return (
      <div className="course-detail">
        <Modal animation={false} show={this.state.modal_show}>
          <Modal.Header>
            <Modal.Title>{GetText() ? "Konten tidak tersedia!" : "Content not found!"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{GetText() ? "Konten ini hanya tersedia dalam bahasa Inggris. Ubah bahasa atau kembali ke Beranda." : "This content is only available on Indonesian version. Change language or back to Home."}</Modal.Body>
          <Modal.Footer>
            <a className="btn btn-primary" href="#!" onClick={this.changeLanguage.bind(this)}>{GetText() ? "Ubah bahasa" : "Change language"}</a>
            <a className="btn btn-outline-primary" href="/">{GetText() ? "Kembali ke Beranda" : "Back to Home"}</a>
          </Modal.Footer>
        </Modal>
      { this.state.isLoad === true? 
        <section>
          <Container>
            <div className="row align-items-start mx-0">
              <div className="col-md-5 bg-light p-5 br-8">
                <div className="row">
                  <div className="col">
                    <h4>Workshop</h4>                
                  </div>
                  <div className="col-auto text-end">              
                    <p className="text-red mt-1"><strong>{this.state.category}</strong></p>
                  </div>
                </div>
                <div className="text-center">
                  <img className="mxw br-8" src={this.state.image} alt={this.state.title}/>
                  <h5 className="my-4">{this.state.title}</h5>
                  <p>
                    <FaRegCalendarDays className="me-1"/>
                    {this.state.date}
                    <FaRegClock className="ms-3 me-1"/>
                    {this.state.time}
                  </p>
                  <p className="fs-3 font-weight">{this.state.currency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]} {this.state.price}</p>
                  {
                    this.state.button_expired === true ? 
                      <div className="d-inline-flex bg-secondary text-white p-2"><span className="font-weight">{GetText() ? "Workshop telah berakhir" : "The workshop has ended" }</span></div>
                    : <a href={"/login/?redirect=" + encodeURIComponent("course/"+this.state.id)} className="btn btn-lg btn-primary mb-3">{GetText() ? "Pesan Tiket" : "Book Ticket"}</a>
                  }
                  
                </div>
              </div>
              <div className="col-md-7 agenda p-5 br-8">
                <h4 className="mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                {
                  (this.state.video !== undefined &&  this.state.video !== "") ? (
                    <div className="embed-wrapper mb-3">
                      <div className="embed-container">
                        <video className="embed-content" name="video_intro" autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
                          <source src={this.state.video} type="video/mp4"></source>
                        </video>
                      </div>
                    </div>
                  ) : ''
                }
                <p className="mb-5">{this.state.excerpt}</p>
                {
                  this.state.tutors.length > 0 ? (
                    <div className="mb-5">
                      <h4 className="mb-4">{GetText() ? "Pembicara" : "Trainer"}</h4>
                      <div className="overflow-auto d-flex pb-3">
                      {
                        this.state.tutors
                        .sort((a, b) => a.tutorName > b.tutorName)
                        .map((tutor, i) => {
                          return (
                            <div key={i} className="text-center p-2">
                              <div><img className="rounded-circle shadow-4-strong" width="100px" src={tutor.tutorPicture} alt={tutor.tutorName}/></div>
                              <div style={{width:"170px"}} className="fw-bold mt-3 fs-6">{tutor.tutorName}</div>
                              <div>{tutor.tutorTitle}</div>
                              
                            </div>
                          )
                        })
                      }
                      </div>
                      
                    </div>
                  ): ''
                }
                {
                  this.state.agenda.length > 0 ? (
                    <div>
                      <h4 className="mb-4">Agenda</h4>
                      {
                        this.state.agenda
                        .sort((a, b) => a.agendaTime._seconds - b.agendaTime._seconds)
                        .map((agenda, i) => {
                          return (
                            <div className="row agenda-item" key={i}>
                              <div className="col-md-3">
                                <label className="label-rounded-2">{DateTimeFormats(agenda.agendaTime._seconds,"time-24")}</label>
                              </div>
                              <div className="col-md-9 text-start">
                                <h5>{agenda.agendaTitle}</h5>
                                <p className="clamp-3">{ReplaceText(agenda.agendaDesc)}</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ): ''
                }
              </div>
            </div>
            <div className="align-items-start mx-0">
              {
                this.state.courseRelated.length > 0 ? (
                  <div className="mt-3">                      
                    <div className="bg-light p-4 br-8">
                      <h4 className="text-start mb-4">{GetText() ? "Workshops Terkait" : "Related Workshops"}</h4>
                      <div className="row">
                        {
                          this.state.courseRelated
                          .sort((a,b) => b.courseDate._seconds - a.courseDate._seconds)
                          .slice(0, 4)
                          .map((related, i) => {
                            return (
                              <div className="col-md-3" key={"block-" + i}>
                                <CourseBlock value={related} key={"related-" + i}/>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                ) : ''
              }
            </div>
          </Container>
        </section>
        : <Loading value={{id:'course-area', type: 'screen'}}/>
      }
      </div>
    );
  }
}