import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaPlus, FaPen, FaFileLines, FaTrash, FaToggleOn, FaToggleOff } from "react-icons/fa6";

// import EditIcon from '@mui/icons-material/Edit';
// import DescriptionIcon from '@mui/icons-material/Description';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';

const authKey = global.config.kindcode.training[5]+global.config.authBearerKey;

export default class CTFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      pageShow: '10',
      keyword: '',
      data: []
    }
    this.loadData = this.loadData.bind(this);
  }

  urladdFeedback(currpage) {
    window.location.href=global.config.urlCms+"/"+currpage+'/add';
  }
  handlePublish(valId,content,nextstat) {
    document.getElementById('loading-area').style.display = 'block';
    // var datatext ='{"feedback_text":"'+content.feedbackText+'","feedback_number":"'+content.feedbackNumber+'","feedback_type":"'+content.feedbackType+'","feedback_status":"'+nextstat+'","option1":"'+content.option1+'","option2":"'+content.option2+'","option3":"'+content.option3+'","option4":"'+content.option4+'","option5":"'+content.option5+'","feedback_docid":"'+valId+'","form_id":"form-edit"}';
    var datatext ='{"feedback_status":"'+nextstat+'","feedback_docid":"'+valId+'","form_id":"form-publish"}';
    var urlFetch = global.config.urlLink+"/feedbackedit";
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            // var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink;
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urldelete(valId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/feedbackdelete";
      var datatext ='{"docid":"'+valId+'","form_id":"delete-feedback"}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              window.location.reload();
          } else {
              console.log('Something happened wrong delete');
          }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
      });
    }
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/feedbacklist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        // var styleTb = 'table';
        // if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  componentDidMount() {
    var url = window.location.href;
    var arrUrl = url.split("/");
    // var tabKey = url.substring(url.lastIndexOf('/') + 1);
    var tabKey = arrUrl[arrUrl.length-1];
    if(tabKey === "") {
      tabKey = arrUrl[arrUrl.length-2];
    }
    this.setState({
      activelink: tabKey
    });
    this.loadData();
  }

  render() {
    // console.log(this.state.activelink);
    var feedbackList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
      this.state.data.values.sort((a, b) => a.content.feedbackNumber - b.content.feedbackNumber);
      for(var ls=0; ls < this.state.data.values.length; ls++) {
          var retResult = this.state.data.values[ls];
          var editURL = global.config.urlCms+"/"+this.state.activelink+"/edit/"+retResult.docid;
          var viewURL = global.config.urlCms+"/"+this.state.activelink+"/view/"+retResult.docid;
          var iconPub = <FaToggleOff style={{color:"#999999"}} className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, retResult.content, 1)}/>;
          if(retResult.content.feedbackStatus === 1 ) { iconPub =  <FaToggleOn className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, retResult.content, 0)}/> }

          feedbackList.push(<tr key={retResult.docid}>
              <th scope="row-field">{retResult.content.feedbackNumber}</th>
              <td>
                {retResult.content.feedbackText}
                <ol className="list-alpha">
                  {retResult.content.option1 ? <li>{retResult.content.option1}</li> : ''}
                  {retResult.content.option2 ? <li>{retResult.content.option2}</li> : ''}
                  {retResult.content.option3 ? <li>{retResult.content.option3}</li> : ''}
                  {retResult.content.option4 ? <li>{retResult.content.option4}</li> : ''}
                  {retResult.content.option5 ? <li>{retResult.content.option5}</li> : ''}
                </ol>
              </td>
              <td>{retResult.content.feedbackType}</td>
              <td>
                <a href={editURL}><FaPen className="styleicons" title="edit" /></a>
                <a href={viewURL}><FaFileLines className="styleicons" title="view" /></a>
                {iconPub}
                <FaTrash className="styleicons" aria-label="delete" onClick={this.urldelete.bind(this, retResult.docid)} />
              </td>
            </tr>)
      }
    } else {
      feedbackList.push(<tr key="1">
          <th scope="row"></th>
          <td colspan={2} className="d-flex p-3">- no data -</td>
          <td></td>
          <td></td>
        </tr>)

    }
    return (
      <div className="setting">
       <Container>
        {
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    this.state.activelink !== "none" && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3>{GetText() ? "Feedback" : "Feedback"}</h3>
                      <span className="text-muted">{GetText() ? "Daftar Pertanyaan" : "List of Questions"}</span>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"></div>
                          <div className="col-6 d-flex justify-content-end">
                              {this.state.activelink !== "" && <Button className="mt-4" variant="secondary" size="sm" onClick={this.urladdFeedback.bind(this, this.state.activelink)}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />Add New Question</Button> }
                          </div>
                      </div>
                      <div className="mt-3">
                          <table className="table">
                            <thead className="table-dark">
                              <tr key="0">
                              <th width="50" className="text-center" scope="col">#</th>
                              <th scope="col">Question</th>
                              <th scope="col" width="200">Type</th>
                              <th width="180" scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                                {feedbackList}
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
