import React, { Component } from "react";
import QRCode from 'react-qr-code';
import { GetText } from '../config/helper';
import ReplaceAllContent from '../config/replace-all';
import '../config/global';

// https://docs.xendit.co/id/xenpayments/virtual-account/making-payments#bagaimana-cara-membayar-virtual-account
const howtopayVA = require('../config/va-tutorial.json');
const howtopayBT = require('../config/bt-tutorial.json');

export default class InstructionPayments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			payment_method: '',
			payment_currency: '',
			payment_currency_symbol: '',
			payment_account: '',
			payment_expired_date: '',
			payment_bank: '',
			payment_amount: 0
		}
	}
	componentDidMount() {
		var content = this.props.value.data;
		var contentInstruction = {};
		if(content.payment_method === global.config.paymentMethod.IDN[1].value) { // BANK TRANSFER
			contentInstruction = howtopayBT[content.payment_bank][localStorage.getItem("language")];
		} else if(content.payment_method === global.config.paymentMethod.IDN[2].value) { // VIRTUAL ACCOUNT
			contentInstruction = howtopayVA[content.payment_bank][localStorage.getItem("language")];
			// contentInstruction = howtopayBT[content.payment_bank][localStorage.getItem("language")];
		} 
		
		this.setState({
			payment_method: content.payment_method,
			payment_currency: content.payment_currency,
			payment_currency_symbol: (content.payment_currency === global.config.currency.name[1])? global.config.currency.symbol[1] : global.config.currency.symbol[0],
			payment_account: content.payment_account,
			payment_expired_date: "",
			payment_bank: content.payment_bank,
			payment_amount: content.payment_amount,
			content_instruction: contentInstruction
		});
	}

	
	render() {
		if(this.state.payment_bank !== "" && localStorage.getItem("language") !== "") {
		// console.log(howtopayVA[this.state.payment_bank][localStorage.getItem("language")]);
		}
		// console.log(howtopayVA[this.state.payment_bank][localStorage.getItem("language")][0].value);
		// console.log(localStorage.getItem("language"));
		// console.log(howtopayVA.BNI.Indonesian[0].value);
		return (
			<div>
				{
				  (this.state.payment_method !== "" && this.state.payment_account !== "" && localStorage.getItem("language") !== "") && 
					<div>
						<h4 className="card-title"><strong>{GetText() ? "Instruksi Pembayaran" : "Payment Instruction"}</strong></h4>
								
						{	
							(this.state.payment_method === global.config.paymentMethod.IDN[1].value || this.state.payment_method === global.config.paymentMethod.IDN[2].value)?  
								//  BANK TRANSFER : VIRTUAL ACCOUNT
								<div className="accordion" id="accordionTab">
								{ 
								this.state.content_instruction.map((contents, i) => {
									return(
										<div className="accordion-item">
											<h2 className="accordion-header" id={"heading-" + i}>
												<button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + i} aria-expanded="false" aria-controls={"collapse-" + i}>
													<span className="me-1 lh-base">{contents.label}</span>
												</button>
											</h2>
											<div id={"collapse-" + i} className="accordion-collapse collapse" aria-labelledby={"heading-" + i} data-bs-parent="#accordionTab">
												<div className="accordion-body">
													{	
														(contents.value.length > 0)?
															<ol>
															{
																contents.value.map((contentdatas) => 	
																	<li>{ReplaceAllContent('<<[account_number]>>',this.state.payment_account,contentdatas)}</li>
																)
															}
															</ol>
														: ''
													}
												</div>
											</div>
										</div>
									)
								})
								}
								</div>
								
							: (this.state.payment_method === global.config.paymentMethod.IDN[3].value)?  
								//  QRIS
								<div>
									<div className="mb-4 mt-2">{GetText() ? "Silakan scan QR Code dibawah ini untuk melakukan pembayaran" : "Please scan this QR Code to make the payment."}</div>
    	                              <div id="qris" className="text-center">
        	                            <div className="qr-container">
            	                          <QRCode className="qr-code" value={this.state.payment_account}/>
                	                    </div>
                    	              </div>
								</div>
							: <></>
						}
					</div>
						
			  } 
			</div>
			
		);
	}
}
