import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FormErrors } from '../inc/error-form';
import ReplaceAllContent from '../config/replace-all';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

export default class CTLibraryQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library_id: '',
      library_title: '',
      libexam_id: '',
      libexam_title: '',
      libquestion_id: '',
      libquestion_text: '',
      libquestion_type: '',
      libquestion_number: '',
      libquestion_answer: '',
      libquestion_option1: '',
      libquestion_option2: '',
      libquestion_option3: '',
      libquestion_option4: '',
      libquestion_option5: '',
      formErrors: { libquestion_text: '', libquestion_type: '', libquestion_number: '', libquestion_answer: '', libquestion_option1: '', libquestion_option2: '', libquestion_option3: '', libquestion_option4: '', libquestion_option5: '' },
      libquestionTextValid: false,
      libquestionTypeValid: false,
      libquestionNumberValid: false,
      libquestionAnswerValid: false,
      libquestionOption1Valid: false,
      libquestionOption2Valid: false,
      libquestionOption3Valid: false,
      libquestionOption4Valid: false,
      libquestionOption5Valid: false,
      formValid: false,
      input_disabled: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }

  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name; 
    this.setState( prevState => {
        return {  
          ...prevState[inputname], [inputname]: value
        }
    }, () => { this.validateField(inputname, value)
    })   
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let libquestionTextValid = this.state.libquestionTextValid;
    let libquestionTypeValid = this.state.libquestionTypeValid;
    let libquestionNumberValid = this.state.libquestionNumberValid;
    let libquestionAnswerValid = this.state.libquestionAnswerValid;
    let libquestionOption1Valid = this.state.libquestionOption1Valid;
    let libquestionOption2Valid = this.state.libquestionOption2Valid;
    
    switch(fieldName) {
        case 'libquestion_text': 
            libquestionTextValid = value.trim().length > 0;
            fieldValidationErrors.libquestion_text = libquestionTextValid ? '' : 'question need to be filled';
            break;
        case 'libquestion_type': 
            libquestionTypeValid = value.trim().length > 0;
            fieldValidationErrors.libquestion_type = libquestionTypeValid ? '' : 'type need to be filled';
            break;
        case 'libquestion_number': 
            libquestionNumberValid = value.trim().length > 0;
            fieldValidationErrors.libquestion_number = libquestionNumberValid ? '' : 'number need to be filled';
            break;
        case 'libquestion_answer': 
            libquestionAnswerValid = value.trim().length > 0;
            fieldValidationErrors.libquestion_answer = libquestionAnswerValid ? '' : 'answer need to be filled';
            break;
        case 'libquestion_option1': 
            libquestionOption1Valid = value.trim().length > 0;
            fieldValidationErrors.libquestion_option1 = libquestionOption1Valid ? '' : 'at least 2 option need to be filled';
            break;
        case 'libquestion_option2': 
            libquestionOption2Valid = value.trim().length > 0;
            fieldValidationErrors.libquestion_option2 = libquestionOption2Valid ? '' : 'at least 2 option need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
        libquestionTextValid: libquestionTextValid,
        libquestionTypeValid: libquestionTypeValid,
        libquestionNumberValid: libquestionNumberValid,
        libquestionAnswerValid: libquestionAnswerValid,
        libquestionOption1Valid: libquestionOption1Valid,
        libquestionOption2Valid: libquestionOption2Valid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.libquestionTextValid && this.state.libquestionTypeValid && this.state.libquestionNumberValid && this.state.libquestionAnswerValid && this.state.libquestionOption1Valid && this.state.libquestionOption2Valid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  handleSubmit(e) {
    e.preventDefault();
    
    if(this.state.libquestion_text !== '' && this.state.libquestion_type !== '' && this.state.libquestion_number !== '' && this.state.libquestion_answer !== '' && this.state.libquestion_option1 !== '' && this.state.libquestion_option2 !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var libquestionText, libquestionType, libquestionNumber, libquestionAnswer;
        var libquestionOption1, libquestionOption2, libquestionOption3, libquestionOption4, libquestionOption5;

        if(this.textInputLibquestionText.value === '') { libquestionText = this.state.libquestion_text; } else { libquestionText = this.textInputLibquestionText.value; }
        libquestionText = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',libquestionText));
        if(this.textInputLibquestionType.value === '') { libquestionType = this.state.libquestion_type; } else { libquestionType = this.textInputLibquestionType.value; }
        if(this.textInputLibquestionNumber.value === '') { libquestionNumber = this.state.libquestion_number; } else { libquestionNumber = this.textInputLibquestionNumber.value; }
        if(this.textInputLibquestionAnswer.value === '') { libquestionAnswer = this.state.libquestion_answer; } else { libquestionAnswer = this.textInputLibquestionAnswer.value; }
        if(this.textInputLibquestionOption1.value === '') { 
            libquestionOption1 = this.state.libquestion_option1;
            libquestionOption1 = libquestionOption1 === undefined ? "" : libquestionOption1;
        } else { libquestionOption1 = this.textInputLibquestionOption1.value; }
        if(this.textInputLibquestionOption2.value === '') { 
            libquestionOption2 = this.state.libquestion_option2; 
            libquestionOption2 = libquestionOption2 === undefined ? "" : libquestionOption2;
        } else { libquestionOption2 = this.textInputLibquestionOption2.value; }
        if(this.textInputLibquestionOption3.value === '') { 
            libquestionOption3 = this.state.libquestion_option3; 
            libquestionOption3 = libquestionOption3 === undefined ? "" : libquestionOption3;
        } else { libquestionOption3 = this.textInputLibquestionOption3.value; }
        if(this.textInputLibquestionOption4.value === '') { 
            libquestionOption4 = this.state.libquestion_option4; 
            libquestionOption4 = libquestionOption4 === undefined ? "" : libquestionOption4;
        } else { libquestionOption4 = this.textInputLibquestionOption4.value; }
        if(this.textInputLibquestionOption5.value === '') { 
            libquestionOption5 = this.state.libquestion_option5; 
            libquestionOption5 = libquestionOption5 === undefined ? "" : libquestionOption5;
        } else { libquestionOption5 = this.textInputLibquestionOption5.value; }

        var datatext, urlFetch;
        if (this.state.libquestion_id !== 'undefined' && this.state.libquestion_id !== "" && this.state.libquestion_id !== undefined) {
            datatext ='{"library_id":"'+this.state.library_id+'","libexam_id":"'+this.state.libexam_id+'","libquestion_id":"'+this.state.libquestion_id+'","libquestion_text":"'+libquestionText+'","libquestion_type":"'+libquestionType+'","libquestion_number":'+libquestionNumber+',"libquestion_answer":"'+libquestionAnswer+'","libquestion_option1":"'+libquestionOption1+'","libquestion_option2":"'+libquestionOption2+'","libquestion_option3":"'+libquestionOption3+'","libquestion_option4":"'+libquestionOption4+'","libquestion_option5":"'+libquestionOption5+'","form_id":"form-editquestion"}';
            urlFetch = global.config.urlLink+"/libraryquestionedit";
        } else {
            datatext ='{"library_id":"'+this.state.library_id+'","libexam_id":"'+this.state.libexam_id+'","libquestion_text":"'+libquestionText+'","libquestion_type":"'+libquestionType+'","libquestion_number":"'+libquestionNumber+'","libquestion_answer":"'+libquestionAnswer+'","libquestion_option1":"'+libquestionOption1+'","libquestion_option2":"'+libquestionOption2+'","libquestion_option3":"'+libquestionOption3+'","libquestion_option4":"'+libquestionOption4+'","libquestion_option5":"'+libquestionOption5+'","form_id":"form-addquestion"}';
            urlFetch = global.config.urlLink+"/libraryquestionadd";
        }
        var data = JSON.parse(datatext);
            
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href=global.config.urlCms+"/ctlibrary/detailedit/"+this.state.library_id;
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
        });
        
    } else {
        var arrfieldName = ['libquestion_text', 'libquestion_type', 'libquestion_number', 'libquestion_answer', 'libquestion_option1', 'libquestion_option2'];
        var arrvalue = [this.textInputLibquestionText.value, this.textInputLibquestionType.value, this.textInputLibquestionNumber.value, this.textInputLibquestionAnswer.value, this.textInputLibquestionOption1.value, this.textInputLibquestionOption2.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }

  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    if(othersContent.examId !== '') {
        document.getElementById('loading-area').style.display = 'block';
        this.setState({
            library_id: othersContent.libraryId,
            library_title: othersContent.libraryTitle,
            libexam_id: othersContent.examId,
            libexam_title: othersContent.examTitle,
            libquestion_id: othersContent.questionId,
            libquestion_text: othersContent.questionText,
            libquestion_type: othersContent.questionType,
            libquestion_number: othersContent.questionNumber,
            libquestion_answer: othersContent.questionAnswer,
            libquestion_option1: othersContent.questionOption1,
            libquestion_option2: othersContent.questionOption2,
            libquestion_option3: othersContent.questionOption3,
            libquestion_option4: othersContent.questionOption4,
            libquestion_option5: othersContent.questionOption5,
            formErrors: { libquestion_text: '', libquestion_type: '', libquestion_number: '', libquestion_answer: '', libquestion_option1: '', libquestion_option2: '', libquestion_option3: '', libquestion_option4: '', libquestion_option5: '' },
            libquestionTextValid: false,
            libquestionTypeValid: false,
            libquestionNumberValid: false,
            libquestionAnswerValid: false,
            libquestionOption1Valid: false,
            libquestionOption2Valid: false,
            libquestionOption3Valid: false,
            libquestionOption4Valid: false,
            libquestionOption5Valid: false,
            formValid: false,
            input_disabled: false
        });
        document.getElementById('loading-area').style.display = 'none'; 
    }
  }

  render() {
    return (
        <div className="container mb-4">
          <div id="question-detail-form">
            <form onSubmit={this.handleSubmit}>
              {/* <div>
                  <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                  <input type="hidden" id="libexam_id" name="libexam_id" value={this.state.libexam_id} />
                  <input type="hidden" id="libquestion_id" name="libquestion_id" value={this.state.libquestion_id} />
              </div> */}
              <div className="mt-3"><h5>{this.state.library_title}</h5></div>
              
              <div className="mt-4">
                    <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_number">Number</label>
                      <div className="col-md-9">
                          <input type="number" className="form-control" name="libquestion_number"
                              placeholder={this.state.libquestion_number}
                              value={this.state.libquestion_number}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionNumber = input}
                              disabled={this.state.input_disabled}
                              />
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_number}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_type">Type</label>
                      <div className="col-md-9">                            
                          <select name="libquestion_type" className="form-control" 
                              value={this.state.libquestion_type} 
                              onChange={this.handleUserSelect}
                              ref={(input) => this.textInputLibquestionType = input}
                              disabled={this.state.input_disabled}
                              >
                              <option value="" key="">-- please select --</option>
                              <option value="Multiple Choice" key="multiple_choice">Multiple Choice</option>
                              <option value="Multiple Answer" key="multiple_answer">Multiple Answer</option>
                          </select>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_type}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_text">Question</label>
                      <div className="col-md-9">
                          <textarea name="libquestion_text" className="form-control" 
                              rows={4} cols={30} 
                              value={this.state.libquestion_text} 
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionText = input}
                              disabled={this.state.input_disabled}
                              />
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_text}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_option1">Option 1</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_option1"
                              placeholder={this.state.libquestion_option1}
                              value={this.state.libquestion_option1}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionOption1 = input}
                              disabled={this.state.input_disabled}
                              />
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_option1}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_option2">Option 2</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_option2"
                              placeholder={this.state.libquestion_option2}
                              value={this.state.libquestion_option2}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionOption2 = input}
                              disabled={this.state.input_disabled}
                              />
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_option2}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_option3">Option 3</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_option3"
                              placeholder={this.state.libquestion_option3}
                              value={this.state.libquestion_option3}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionOption3 = input}
                              disabled={this.state.input_disabled}/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_option3}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_option4">Option 4</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_option4"
                              placeholder={this.state.libquestion_option4}
                              value={this.state.libquestion_option4}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionOption4 = input}
                              disabled={this.state.input_disabled}/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_option4}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_option5">Option 5</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_option5"
                              placeholder={this.state.libquestion_option5}
                              value={this.state.libquestion_option5}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionOption5 = input}
                              disabled={this.state.input_disabled}/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_option5}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_answer">Answer</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_answer"
                              placeholder={this.state.libquestion_answer}
                              value={this.state.libquestion_answer}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionAnswer = input}
                              disabled={this.state.input_disabled}
                              />
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_answer}/>  }
                          </div>
                          <div className="mt-2 fieldComments">
                              Only input the option number, comma (with space) separated if multiple
                          </div>
                      </div>
                  </div>
                  
                  <div className="row mt-4 mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                  </div>
                   
              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
