import React, { Component } from 'react';
// import '../ctinc/ct-Style-2.css';
// import '../ctinc/ct-Style.css';
// import '../ctinc/ct-Global';
import DateTimeFormats from '../config/date-time-format';

// const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[13];


export default class CTFeedbackAnswer extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            doc_id: "",
            content_title: "",
            feedback_id: "",
            member_name: "",
            member_email: "",
            submit_date: "",
            answer_list: [],
        }
    }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'none'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
          doc_id: othersContent.docid,
          content_title: othersContent.content_title,
          feedback_id: othersContent.feedback_docid,
          member_name: othersContent.member_name,
          member_email: othersContent.member_email,
          submit_date: DateTimeFormats(othersContent.submit_date._seconds,"date-long"),
          answer_list: othersContent.answer_list
        });
    }
    render() {
      return (
        <div className="container mb-4">
            <div>
              <div className="mt-3">
                <div><h5>{this.state.content_title}</h5></div>
                <div>
                  <span className="text-muted mt-2">Submitted by : {this.state.member_name}</span> 
                  <span className="ms-2 text-muted">( {this.state.member_email} )</span>
                  <span className="ms-4 text-muted">on : {this.state.submit_date}</span>
                </div>
              </div>
              <div className="mt-4">
                <div id="feedback-detail-info">
                    {/* <div className="fw-bold">Feedback Detail</div> */}
                    <div>
                        {
                            this.state.answer_list.map(answer => {
                                return(
                                    <div className="mt-1">
                                        <div>
                                            {answer.subsubcontent.feedbackNumber + ". " + answer.subsubcontent.feedbackText}
                                        </div>
                                        <div><strong>&emsp;{answer.subsubcontent.feedbackAnswer}</strong></div>
                                    </div>
                                )       
                            })
                        }
                    </div>                   
                </div>
              </div>
              
            </div>
            <div id="loading-area" className="d-none loading"></div> 
        </div>
      );
    }
 }
