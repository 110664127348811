import React, { Component } from 'react';
import Moment from 'moment';
import { FaRegCalendarDays, FaRegFolder } from 'react-icons/fa6';
import NAimage from '../../img/pic-na.png';

export default class Purchased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      date: '',
      image: '',
      link: ''
    }
  }

  componentDidMount() {
    var content = this.props.value;
    var catName="",courseDatez="";
    if(content.categoryName !== undefined) { catName = content.categoryName; }
    if(content.courseDate !== undefined) { courseDatez = Moment.unix(content.courseDate._seconds).format("D MMMM Y"); }
    this.setState({
      id: content.courseId,
      title: content.courseTitle,
      category: catName,
      date: courseDatez,
      image: (content.coursePicture !== "" && content.coursePicture !== undefined)? content.coursePicture : NAimage,
      link: '/course/' + content.courseId
    });
  }

  render() { 
    return (
			<a href={this.state.link}>
        <div className="course-list">
          <div className="row">
            <div className="col-md-3">
              <img className="mxw br-8" src={this.state.image} alt={this.state.title} style={{maxWidth:"120px",maxHeight:"110px"}} />
            </div>
            <div className="col-md-9">
              <h5 className="list-title">{this.state.title}</h5>
              <p>
              {this.state.date !== ""? 
                <span className="me-4">
                  <FaRegCalendarDays className="me-1"/>
                  {this.state.date}
                </span>
                : ''
              }
              {this.state.category !== ""? 
                <span className="me-4">
                  <FaRegFolder className="me-1"/>
                  {this.state.category}
                </span>
                : ''
              }
              </p>
            </div>
          </div>
        </div>
      </a>
      )
    }
  
  
}