import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import DateTimeFormats from '../config/date-time-format';
import ReplaceAllContent from '../config/replace-all';
import NAimage from '../img/pic-na.png';

const authKey = global.config.kindcode.training[4]+global.config.authBearerKey;

export default class CTTutorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      tutor_docid: '',
      tutor_createby: '',
      tutor_createdate: '',
      tutor_name: '',
      tutor_email: '',
      tutor_title: '',
      tutor_bio: '',
      tutor_linkedin: '',
      tutor_picture: '',
      tutor_status: '',
    }
    this.loadDetailData = this.loadDetailData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  
  loadDetailData(currpage,typelink,docid) {
    if(docid !== '') { 
        document.getElementById('loading-area').style.display = 'block';
        fetch(global.config.urlLink+"/tutorview/"+docid, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
            }).then(res => {
            if(res.status === 200) 
                return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none';
                if(resJson.status === '200') {
                if(resJson.values[0].content[0].maincontent !== undefined) {
                
                    var resultContent = resJson.values[0].content[0].maincontent;
                    var lName="",lEmail="",lTitle="",lBio="",lLinkedin="",lPicture="",lStatus="",cDate="";
                    if(resJson.values[0].content[0].createdate !== undefined) {
                        cDate = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"datetime-short");
                    }
                    if(resultContent.tutorName !== undefined) { lName = resultContent.tutorName; }
                    if(resultContent.tutorEmail !== undefined) { lEmail = resultContent.tutorEmail; }
                    if(resultContent.tutorTitle !== undefined) { lTitle = resultContent.tutorTitle; }
                    if(resultContent.tutorBio !== undefined) {
                        lBio = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.tutorBio));
                    }
                    if(resultContent.tutorLinkedin !== undefined) { lLinkedin = resultContent.tutorLinkedin; }
                    if(resultContent.tutorPicture !== undefined && resultContent.tutorPicture !== "") { 
                        lPicture = resultContent.tutorPicture;
                    } else { 
                        lPicture = NAimage; 
                    }
                    if(resultContent.tutorStatus !== undefined) { lStatus = resultContent.tutorStatus === 1 ? 'Active' : 'Inactive'; }
                    this.setState({
                        activelink: currpage,
                        stTitle: GetText() ? "Detil Informasi Pelatih" : "Detail Trainer Information",
                        tutor_docid: resJson.values[0].docid,
                        tutor_createby: resJson.values[0].content[0].maincontent.createByName,
                        tutor_createdate: cDate,
                        tutor_name: lName,
                        tutor_email: lEmail,
                        tutor_title: lTitle,
                        tutor_bio: lBio,
                        tutor_linkedin: lLinkedin,
                        tutor_picture: lPicture,
                        tutor_status: lStatus,
                    }) 
                } else {
                    window.location.href=global.config.urlCms+"/"+currpage+"/add";
                }
                } else {
                console.log('Something wrong happened');
                }
            }).catch(err => err);
    } else {
        window.location.href=global.config.urlCms+"/"+currpage+"/add";
    }
   
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadDetailData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                        <h3 className="mb-4">{GetText() ? " Pelatih" : " Trainers"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} </h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row">
                        <span className="text-muted">created by : {this.state.tutor_createby} <span className="ms-4">on : {this.state.tutor_createdate}</span></span>
                      </div>
                      <div className="row mt-3">
                            <div className="row mt-3">
                                <div className="col-md-3"><h6>Name</h6></div>
                                <div className="col-md-9">{this.state.tutor_name}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3"><h6>Email</h6></div>
                                <div className="col-md-9">{this.state.tutor_email}</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3"><h6>Academic / Job Title</h6></div>
                                <div className="col-md-9">{this.state.tutor_title}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3"><h6>Bio </h6></div>
                                <div className="col-md-9">{this.state.tutor_bio}</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3"><h6>Linkedin</h6></div>
                                <div className="col-md-9">{this.state.tutor_linkedin}</div>
                            </div>
                            <div className="row mt-3">
                                <div><h6>Picture </h6></div>
                                <div><img width="150" src={this.state.tutor_picture} alt=""/></div>
                            </div>

                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
