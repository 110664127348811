import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { GetText } from './config/helper';

export default class Error404 extends Component {

  render() {
    return (
      <div className="error404">
        <Container>
          <h1 className="mb-4">{GetText() ? "Halaman tidak ditemukan" : "Page does not exist"}</h1>
          <p></p>
          <div className="mt-4">
            <p>{GetText() ? "Maaf, tampaknya alamat situs web yang Anda masukkan salah." : "We're sorry, but it appears the website address you entered was incorrect."}</p>
            <a href="#!" className="link-more" onClick={() => window.history.back()}>{GetText() ? "Kembali" : "Go back"} <FaArrowRight/></a>
          </div>
        </Container>
      </div>
    );
  }
}