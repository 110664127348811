import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaPen, FaFileLines } from "react-icons/fa6";
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import defCompanyLogo from '../img/pic-company.png';
import DateTimeFormats from '../config/date-time-format';
import Popup from '../inc/PopUp';
import CTUserCompanyDetail from './ct-companytraining-pop-user-detail';
import CTUserCompanyEdit from './ct-companytraining-pop-user-edit';

const authKey = global.config.kindcode.training[6]+global.config.authBearerKey;


function getCompanyDetail(id) {
  return new Promise((resolve) => {
    fetch(global.config.urlLink+"/trainingcompanyview/"+id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resultJson => {
      if(resultJson.status === '200') { 
        resolve(resultJson.values);
      } else {
        resolve([]);
      }
    });
  });
}
function getLibraryList(contents,uid,libraryArr) {
  return new Promise((resolve) => {
    var objIndex = "";
    var libraryResult = contents;
    if(libraryResult.length > 0) {
      libraryResult.forEach((datalib, lr) => {
        var enroll = datalib.subcontent;
        objIndex = libraryArr.findIndex((el) => el.libId === enroll.libraryId);
        
        if(objIndex < 0) {
          libraryArr.push({
            libId: enroll.libraryId,
            title: enroll.libraryTitle,
            countEnroll: 1,
            countComplete: enroll.completion === 100? 1 : 0
          })
        } else {
          var currLibrary = libraryArr[objIndex];
          libraryArr[objIndex].countEnroll = Number(currLibrary.countEnroll)+1;
          if(enroll.completion === 100) { 
            libraryArr[objIndex].countComplete = Number(currLibrary.countComplete)+1;
          }
        }
        if(lr===(libraryResult.length-1)) {
          resolve(libraryArr)
        }
      })

    } else {
      resolve(libraryArr);
    }

  })
}
function getCourseList(contents2,uid,courseArr) {
  return new Promise((resolve) => {
    var objIndex2 = "";
    var courseResult = contents2;
    if(courseResult.length > 0) {
      courseResult.forEach((datacrs, cr) => {
        var joinedcourse = datacrs.subcontent;
        objIndex2 = courseArr.findIndex((el2) => el2.crsId === joinedcourse.courseId);
        
        if(objIndex2 < 0) {
          courseArr.push({
            crsId: joinedcourse.courseId,
            courseTitle: joinedcourse.courseTitle,
            courseDate: joinedcourse.courseDate,
            courseSTime: joinedcourse.courseStartTime,
            courseETime: joinedcourse.courseEndTime,
            countCheckinCourse: joinedcourse.checkInDate !== undefined? 1 : 0,
            countPurchaseCourse: 1
          })
        } else {
          var currCourse = courseArr[objIndex2];
          courseArr[objIndex2].countPurchaseCourse = Number(currCourse.countPurchaseCourse)+1;
          if(joinedcourse.countCheckinCourse !== undefined) { 
            courseArr[objIndex2].countCheckinCourse = Number(currCourse.countCheckinCourse)+1;
          }
        }
        if(cr===(courseResult.length-1)) {
          resolve(courseArr)
        }
      })

    } else {
      resolve(courseArr);
    }

  })
}
function getCompanyListUser(val) {
  return new Promise((resolve) => {
    var dataSend = {
      "companydomain": val
    }
    fetch(global.config.urlLink+"/traininguserclient/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, body: JSON.stringify(dataSend),
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resultJson2 => {
      if(resultJson2.status === '200') { 
        var userList = [];
        var libraryList = [];
        var courseList = [];
        var totalUsers = 0, activeUsers = 0, takenUsers = 0, activeUsersPersentage = 0;
            
        var userJson = resultJson2.values.memberListData;
        if(userJson.length > 0) {
          userJson.forEach(async (listuserjson, u) => {
          // for (var u = 0; u < userJson.length; u++) {
            // var userResult = userJson[u].content[0].maincontent;
            var userResult = listuserjson.content[0].maincontent;
            if(userResult.flagActive === true) { totalUsers++; }
            if(userResult.flagActive === true && userResult.lastLogin !== undefined) { activeUsers++; }
            if(userResult.flagActive === true && userResult.lastLogin !== undefined && listuserjson.subcontent3.length > 0) { takenUsers++; }
            userList.push(
              {
                docId: listuserjson.docid,
                memberName: userResult.memberName,
                memberEmail: userResult.memberEmail,
                memberCompany: userResult.memberCompany,
                expiredDate: userResult.expiredSubscribeMember,
                lastLogin: userResult.lastLogin,
                registeredDate: userResult.registeredDate,
                userLevel: userResult.userLevel,
                flagActive: userResult.flagActive,
                statSubscribe: userResult.statusSubscribeMember,
              }
            );
            // var [resultFunction1,resultFunction2] = await Promise.all([getLibraryList(userJson[u].subcontent3),getCourseList(userJson[u].subcontent2)]);
            var [resultFunc1,resultFunc2] = await Promise.all([getLibraryList(listuserjson.subcontent3,listuserjson.docid,libraryList),getCourseList(listuserjson.subcontent2,listuserjson.docid,courseList)]);
            
            if(u === (userJson.length-1)) {
              if(activeUsers > 0 && totalUsers > 0) {
                activeUsersPersentage = activeUsers/totalUsers;
              } 
              resolve([{
                totalUsers: totalUsers,
                activeUsers: activeUsers,
                takenUsers: takenUsers,
                activeUsersPersentage: activeUsersPersentage,
                userList: userList,
                libraryList: resultFunc1,
                courseList: resultFunc2
              }])
            }
          })
        } else {
          resolve([{
            totalUsers: totalUsers,
            activeUsers: activeUsers,
            takenUsers: takenUsers,
            activeUsersPersentage: activeUsersPersentage,
            userList: userList,
            libraryList: libraryList,
            courseList: courseList
          }])
        }
      } else {
        resolve([]);
      }
    });
  });
}

export default class CTCompanyTrainingsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      company_docid: '',
      company_name: '',
      company_domain: '',
      company_logo: '',
      company_logo_shape: '',
      expired_date: "",
      is_expired: true,
      created_on: "",
      created_by: "",
      userList: [],
      detailList: [],
      detail2List: [],
      detailRows: [],
      totalUsers: 0,
      activeUsers: 0,
      takenUsers: 0,
      activeUsersPersentage: 0,
      isOpen: false,
      otherContent: "",
      isOpen2: false,
      otherContent2: "",
    }
    this.loadData = this.loadData.bind(this);
  }
  handleBack = (currpage) => {
    window.location.href=global.config.urlCms+"/"+currpage;
  }

  togglePopClose() {
    this.setState({
      isOpen: false,
    })
  }

  togglePopClose2() {
    this.setState({
      isOpen2: false,
    })
  }

  urldetailUser(member_id) {
    this.setState({
      isOpen: true,
      otherContent: '{"company_id":"' + this.state.company_docid + '","member_id":"' + member_id + '","activelink":"'+this.state.activelink+'"}',
    })  
  }

  urleditUser(member_id) {
    this.setState({
      isOpen2: true,
      otherContent2: '{"company_id":"' + this.state.company_docid + '","member_id":"' + member_id + '","activelink":"'+this.state.activelink+'"}',
    })  
  }

  libraryContent = (dataLibrary) => {
    var dataLibraryList = [];
    if(dataLibrary !== undefined && dataLibrary.length > 0) {
      for(var ls=0; ls < dataLibrary.length; ls++) {
        var detailData = dataLibrary[ls];
        var percentage = Math.round((detailData.countComplete/detailData.countEnroll)*100);
        dataLibraryList.push(
          <tr key={ls+1}>
              <th scope="row">{ls+1}</th>
              <td><a href={global.config.urlCms+"/ctlibrary/view/"+detailData.libId}>{detailData.title}</a></td>
              <td className="text-center">{detailData.countEnroll}</td>
              <td className="text-center">{detailData.countComplete}</td>
              <td className="text-center">{percentage}%</td>
          </tr>
        )
      }
    } else {
      dataLibraryList.push(<tr key="1">
        <th scope="row"></th>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [dataLibraryList];
  }

  courseContent = (dataCourse) => {
    var dataCourseList = [];
    if(dataCourse !== undefined && dataCourse.length > 0) {
      for(var ls3=0; ls3 < dataCourse.length; ls3++) {
        var courseData = dataCourse[ls3];
          dataCourseList.push(
            <tr key={ls3+1}>
                <th scope="row">{ls3+1}</th>
                <td><a href={global.config.urlCms+"/ctcourse/view/"+courseData.crsId}>{courseData.courseTitle}</a></td>
                <td>
                  {courseData.courseDate !==undefined? DateTimeFormats(courseData.courseDate._seconds,"date-short") : ''}<br/>
                  ({courseData.courseSTime !==undefined? DateTimeFormats(courseData.courseSTime._seconds,"time-24") : ''} - 
                  {courseData.courseETime !==undefined? DateTimeFormats(courseData.courseETime._seconds,"time-24") : ''})
                </td>
                <td className="text-center">{courseData.countPurchaseCourse}</td>
                <td className="text-center">{courseData.countCheckinCourse}</td>
            </tr>
          )
      }
    } else {
      dataCourseList.push(<tr key="1">
        <th scope="row"></th>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [dataCourseList];
  }

  userContent = (dataUser) => {
    var dataUserList = [];
    if(dataUser !== undefined && dataUser.length > 0) {
      for(var ls2=0; ls2 < dataUser.length; ls2++) {
        var userData = dataUser[ls2];
        if (userData.memberCompany === this.state.company_domain) {
          var expired_date = (userData.expiredDate !== undefined && userData.expiredDate !== "") ? DateTimeFormats(userData.expiredDate._seconds,"date-short") : '';
          var has_joined = userData.lastLogin !== undefined ? true : false;
          dataUserList.push(
            <tr key={ls2+1}>
                <th scope="row">{ls2+1}</th>
                <td>
                  {userData.memberName}
                  <br/>{userData.memberEmail}
                  {userData.userLevel === 'Admin' && (<><br/><label className="bg-info text-white ps-2 pe-2 pt-1 pb-1">Admin</label></>)}
                </td>
                <td>{expired_date}</td>
                <td>{has_joined === true ? DateTimeFormats(userData.lastLogin._seconds,"datetime-short") : '-'}</td>
                <td>{userData.statSubscribe === global.config.subscribeMemberStatus[2] ? "Subscriber" : "User"}</td>
                <td>
                  <FaFileLines className="styleicons" onClick={this.urldetailUser.bind(this, userData.docId)} />
                  <FaPen className="styleicons" onClick={this.urleditUser.bind(this, userData.docId)} />
                </td>
            </tr>
          )
        }
      }
    } else {
      dataUserList.push(<tr key="1">
        <th scope="row"></th>
        <td></td>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [dataUserList];
  }
  
  async loadData(currpage,typelink,docid) {
    
    if(typelink === 'view') {
        if(docid !== '') { 
          var userList = [], detailList = [], detail2List = [];
          var companyLogo = '', expiredDate = '', companyLogoShape = '';
          var companyId = '', companyName = '', companyDomain = '', companyCreateOn = '', companyCreateBy = '';
          var totalUsers = 0, activeUsers = 0, takenUsers = 0, activeUsersPersentage = 0;
          var result1 = await getCompanyDetail(docid);
          if(result1) {
            var isExpired = true;
              if(result1[0].content[0].maincontent.companyLogo !== undefined && result1[0].content[0].maincontent.companyLogo !== '') {
                companyLogo = result1[0].content[0].maincontent.companyLogo;
              } else { 
                companyLogo = defCompanyLogo; 
              }
              if(result1[0].content[0].maincontent.companyLogoShape !== undefined && result1[0].content[0].maincontent.companyLogoShape !== '') {
                companyLogoShape = result1[0].content[0].maincontent.companyLogoShape;
              } 
              if(result1[0].content[0].maincontent.expiredSubscription !== undefined && result1[0].content[0].maincontent.expiredSubscription !== "") {
                var today = new Date().getTime() / 1000;
                if (parseInt(result1[0].content[0].maincontent.expiredSubscription._seconds) > Math.floor(parseInt(today))) {
                  isExpired = false;
                }
                expiredDate = DateTimeFormats(result1[0].content[0].maincontent.expiredSubscription._seconds,"date-short");
              }
              companyId = result1[0].docid;
              companyName = result1[0].content[0].maincontent.companyName; 
              companyDomain = result1[0].content[0].maincontent.companyDomain;
              companyCreateOn = DateTimeFormats(result1[0].content[0].maincontent.createDate._seconds,"date-long");
              companyCreateBy = result1[0].content[0].maincontent.createByName;
      
              /* ======== */
              var result2 = await getCompanyListUser(companyDomain);
              if(result2 && result2.length > 0) {
                userList=result2[0].userList;
                detailList=result2[0].libraryList;
                detail2List=result2[0].courseList;
                totalUsers=result2[0].totalUsers;
                activeUsers=result2[0].activeUsers;
                takenUsers=result2[0].takenUsers;
                activeUsersPersentage=result2[0].activeUsersPersentage;
              }
          }

          if(result1 && result2) {
            this.setState({
              activelink: currpage,
              stTitle: 'Detail Company',
              company_docid: companyId,
              company_name: companyName,
              company_domain: companyDomain,
              company_logo: companyLogo,
              company_logo_shape: companyLogoShape,
              expired_date: expiredDate,
              is_expired: isExpired,
              created_on: companyCreateOn,
              created_by: companyCreateBy,
              userList: userList,
              detailList: detailList,
              detail2List: detail2List,
              totalUsers: totalUsers,
              activeUsers: activeUsers,
              takenUsers: takenUsers,  
              activeUsersPersentage: activeUsersPersentage,      
              isOpen: false,
              otherContent: ''
            })
            document.getElementById('loading-area').style.display = 'none';
          } 
        } else {
          this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle: 'New Company'
            }
          })
        }
    } else if(typelink === 'add') {
        this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle: 'New Company'
            }
        })
    }    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
      
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Perusahaan" : " Companies"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div id="profile-detail" className="row mt-3">
                        <div className="row">
                          <div className="col-4">
                            {
                              this.state.company_logo !== '' ? (
                                this.state.company_logo_shape === 'round'? 
                                  <img src={this.state.company_logo} alt={this.state.company_name} className="wh-180 mb-4"/>
                                : <img src={this.state.company_logo} alt={this.state.company_name} className="mb-4" style={{maxWidth:"200px"}}/>
                                
                              ) : ''
                            }
                          </div>
                          <div className="col-8">
                            <div><h4>{this.state.company_name}</h4></div>
                            <div><label className="text-muted">Created On {this.state.created_on}, by { this.state.created_by }</label></div>
                            <div className="row mt-3">
                                <div className="col-md-4">Company Domain</div>
                                <div className="col-md-8"><span className="me-1">:</span>{this.state.company_domain}</div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-md-4">Expired On </div>
                                <div className="col-md-8"><span className="me-1">:</span>{this.state.expired_date}</div>
                            </div>
                            
                            <div className="row mt-3">
                              <div className="col-md-9">
                                {
                                    this.state.expired_date === true ? (
                                      <label className="d-inline-flex bg-danger text-white p-2 rounded">Expired</label> 
                                    ) : (
                                      <label className="d-inline-flex bg-success text-white p-2 rounded">Active</label> 
                                    )
                                }
                                </div>
                                <div className="col-md-3 justify-content-end d-flex align-bottom">
                                      <a className="linkText" href={global.config.urlCms+"/"+this.state.activelink+"/edit/"+this.state.company_docid}><FaPen className="me-1" style={{fontSize:"14pt"}} /><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a>
                                </div>
                            </div>
                          </div>
                        </div>
                        {/* ======= */}
                        <div className="row mt-5" id="summary-area">
                          <div className="col-md-12 d-flex">
                            <div className="fw-bold"><h6>Enroll Summary</h6></div>
                          </div>
                          <div className="mt-3">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="card card-progress blue">
                                  <div className="card-header text-center fw-bold">Total Member</div>
                                  <div className="card-body">
                                    <div className="progress progress100">
                                      <span className="progress-left"><span className="progress-bar"></span></span>
                                      <span className="progress-right"><span className="progress-bar"></span></span>
                                      <div className="progress-value">{this.state.totalUsers}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card card-progress green">
                                  <div className="card-header text-center fw-bold">Active Member</div>
                                  <div className="card-body">
                                    <div className={"progress progress" + (Math.floor(this.state.activeUsersPersentage*10)*10)}>
                                      <span className="progress-left"><span className="progress-bar"></span></span>
                                      <span className="progress-right"><span className="progress-bar"></span></span>
                                      <div className="progress-value">{Math.floor(this.state.activeUsersPersentage*100)}%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card card-progress red">
                                  <div className="card-header text-center fw-bold">Member Progress</div>
                                  <div className="card-body">
                                    {/* <div className={"progress progress" + (Math.floor(this.state.takenUsers/this.state.activeUsers*10)*10)}> */}
                                    <div className={"progress progress" + Math.floor(this.state.takenUsers/10)*10}>
                                      <span className="progress-left"><span className="progress-bar"></span></span>
                                      <span className="progress-right"><span className="progress-bar"></span></span>
                                      {/* <div className="progress-value">{Math.floor(this.state.takenUsers/this.state.activeUsers*100)}%</div> */}
                                      <div className="progress-value">{this.state.takenUsers}%</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==== start - Company elemen =============== */}
                        <div className="row mt-3">
                          <div className="accordion" id="accordionTab">
                          {/* === #1  */}
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading-1">
                                <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                  <span className="me-1 lh-base">Library Enrolled List</span>
                                </button>
                              </h2>
                              <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionTab">
                                <div className="accordion-body">
                                      <div id="library-area">
                                        <div className="mt-3">
                                        { this.state.detailList.length > 0?
                                          <table className="table">
                                            <thead className="table-light">
                                                <tr key="0">
                                                  <th scope="col text-wrap">#</th>
                                                  <th scope="col text-wrap">Library Name</th>
                                                  <th scope="col text-wrap">Total Enrolled</th>
                                                  <th scope="col text-wrap">Total Completed</th>
                                                  <th scope="col text-wrap">Completion</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {this.libraryContent(this.state.detailList)}
                                            </tbody>
                                          </table>
                                        : 
                                          <div className="w-100 text-center">- no data -</div> 
                                        }
                                        </div>
                                      </div>  
                                </div>
                              </div>
                            </div>
                            {/* === #1  */}

                            {/* === #2  */}
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading-2">
                                <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                  <span className="me-1 lh-base">Appplied Workshop List</span>
                                </button>
                              </h2>
                              <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionTab">
                                <div className="accordion-body">
                                      <div id="course-area">
                                        <div className="mt-3">
                                          {this.state.detail2List.length> 0 ? 
                                          <table className="table">
                                            <thead className="table-light">
                                                <tr key="0">
                                                  <th scope="col text-wrap">#</th>
                                                  <th scope="col text-wrap">Workshop Name</th>
                                                  <th scope="col text-wrap" width={150}>Workshop Date</th>
                                                  <th scope="col text-wrap" width={150}>Total Registered</th>
                                                  <th scope="col text-wrap" width={120}>Total Joined</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {this.courseContent(this.state.detail2List)}
                                            </tbody>
                                          </table>
                                          :
                                          <div className="w-100 text-center">- no data -</div> 
                                          }
                                        </div>
                                      </div>   
                                </div>
                              </div>
                            </div>
                            {/* === #2  */}

                            {/* === #3  */}
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading-3">
                                <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                  <span className="me-1 lh-base">User List</span>
                                </button>
                              </h2>
                              <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordionTab">
                                <div className="accordion-body">
                                      <div id="user-area">
                                        <div className="mt-3">
                                        {(this.state.userList.length > 0)? 
                                          <table className="table">
                                            <thead className="table-light">
                                                <tr key="0">
                                                  <th scope="col text-wrap">#</th>
                                                  <th scope="col text-wrap">User</th>
                                                  <th scope="col text-wrap">Expired Date</th>
                                                  <th scope="col text-wrap">Last Login</th>
                                                  <th scope="col text-wrap" width={100}>Status</th>
                                                  <th scope="col text-wrap" width={100}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {this.userContent(this.state.userList)}
                                            </tbody>
                                          </table>
                                        :
                                          <div className="w-100 text-center">- no data -</div> 
                                        }
                                        </div>
                                      </div>   
                                </div>
                              </div>
                            </div>
                            {/* === #3  */}
                          </div>

                        </div>
                        {/* ==== end - Company elemen =============== */}

                        {/* ======= */}

                      </div>

                    </div>
                    <div>
                    {
                      this.state.isOpen && <Popup 
                        content={<CTUserCompanyDetail/>}
                        id={this.state.company_id} 
                        title={"History User Enrollment"}
                        others={this.state.otherContent}
                        styles={"70%"}
                        handleClose={this.togglePopClose.bind(this, this.state.company_id)}
                      />
                    }
                    {
                      this.state.isOpen2 && <Popup 
                        content={<CTUserCompanyEdit/>}
                        id={this.state.company_id} 
                        title={"Edit User Access"}
                        others={this.state.otherContent2}
                        styles={"70%"}
                        handleClose={this.togglePopClose2.bind(this, this.state.company_id)}
                      />
                    }
                  </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
