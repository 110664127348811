import React, { Component } from 'react';
import { FaFileCirclePlus, FaDownload } from "react-icons/fa6";
// import NoteAddIcon from '@mui/icons-material/NoteAdd';
// import DownloadIcon from '@mui/icons-material/Download';

import '../config/global';
import DateTimeFormats from '../config/date-time-format';
import defMemberPicture from '../img/pic-profile-sq.gif';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;

export default class CTUserCompanyDetail extends Component {
  constructor (props) {
    super(props);
      this.state = {
      stPopTitle: '',
      doc_id: '',
      member_id: '',
      member_name: '',
      member_email: '',
      member_status_subscribe: '',
      member_expired_subscription: '',
      member_picture: '',
      company_name: '',
      member_info: [],
      library_list: [],
      course_list: [],
      certificate_list: [],
      isOpen: false,
      library_id_c: '',
      otherContent: ''
    }
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.loadFormPurchase = this.loadFormPurchase.bind(this);
  }
  togglePopClose() {
    this.setState({
      isOpen: false,
    })
  }
  createCertificate(memberinfo,content) {
    var othContent =JSON.stringify({"member_name": memberinfo.memberName ,"content_member": memberinfo, "content_lib": content }); 
    this.setState({
      isOpen: true,
      library_id_c: content.subcontent.libraryId,
      otherContent: othContent
    }) 
  }

  libraryContent = (dataLibrary) => {
    var dataLibraryList = [];
    if(dataLibrary !== undefined && dataLibrary.length > 0) {
      dataLibrary.forEach((library, ls) => {
        dataLibraryList.push(
          <tr key={ls+1}>
              <th scope="row">{ls+1}</th>
              <td><a href={global.config.urlCms+"/ctlibrary/view/"+library.subcontent.libraryId}>{library.subcontent.libraryTitle}</a></td>
              <td>{DateTimeFormats(library.subcontent.lastVisit._seconds,"date-short")}</td>
              <td>{"Module " + library.subcontent.lastLesson}</td>
              <td>{library.subcontent.completion + "%"}</td>
              <td>
                <div className="flex-container">
                  <div style={{width:"30px"}}>{library.subcontent.certificateUrl ? <a href={library.subcontent.certificateUrl} className="iconGrey cursor-on" target="_blank" rel="noreferrer"><FaDownload className="mx-2 styleicons" style={{height:"30px", cursor:"Pointer"}}/></a> : '-'}</div>
                  <div style={{width:"30px"}}>{(library.subcontent.certificateUrl && localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) ? <FaFileCirclePlus onClick={this.createCertificate.bind(this, this.state.member_info, library)} className="styleicons" /> : <></> }</div>
                </div>
              </td>
          </tr>
        )
      })
      
    } else {
      dataLibraryList.push(<tr key="1">
        <th scope="row"></th>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [dataLibraryList];
  }

  courseContent = (dataCourse) => {
    var dataCourseList = [];
    if(dataCourse !== undefined && dataCourse.length > 0) {
      dataCourse.forEach((course, ls2) => {
        dataCourseList.push(
          <tr key={ls2+1}>
              <th scope="row">{ls2+1}</th>
              <td>
                <span className="fw-bold">{course.subcontent.courseTitle}</span>
                {course.subcontent.courseStartTime? <span><br/>Event Start on :  {DateTimeFormats(course.subcontent.courseStartTime._seconds,"datetime-short")}</span> : ''}
                {course.subcontent.courseEndTime? <span><br/>Event End on :  {DateTimeFormats(course.subcontent.courseEndTime._seconds,"datetime-short")}</span> : ''}
              </td>
              <td>{DateTimeFormats(course.subcontent.createdDate._seconds,"date-short")}</td>
              <td>{course.subcontent.ticketId}</td>
              <td>
                {course.subcontent.checkInDate? DateTimeFormats(course.subcontent.checkInDate._seconds,"datetime-short") : ''}
              </td>
              <td>
                <div className="flex-container">
                  <div style={{width:"30px"}}>{course.subcontent.certificateUrl ? <a href={course.subcontent.certificateUrl} className="iconGrey cursor-on" target="_blank" rel="noreferrer"><FaDownload className="mx-2 styleicons" style={{height:"30px", cursor:"Pointer"}}/></a> : '-'}</div>
                  <div style={{width:"30px"}}>{(course.subcontent.certificateUrl && localStorage.getItem('ctulevel') === global.config.userLevel[0]) ? <FaFileCirclePlus onClick={this.createCertificate.bind(this, this.state.member_info, course)} className="styleicons" /> : <></> }</div>
                </div>
              </td>
          </tr>
        )
      })

    } else {
      dataCourseList.push(<tr key="1">
        <th scope="row"></th>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [dataCourseList];
  }

  componentDidMount() {
    document.getElementById('loading-area').style.display = 'none'; 
    var getContent = JSON.parse(document.getElementById("contentoth").innerText);
    var dataSend = { docid: getContent.member_id }
    fetch(global.config.urlLink+"/traininguserview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      },
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resJson => {
        
      if(resJson.status === "200") {
        document.getElementById('loading-area').style.display = 'none';
        var mainContent = resJson.values[0].content[0].maincontent;
        var library_list = resJson.values[0].scontent3;
        var course_list = resJson.values[0].scontent2;
        // var certificate_list = resJson.values[0].scontent4;
        // for (var i = 0; i < library_list.length; i++) {
        //   for (var j = 0; j < certificate_list.length; j++) {
        //     if (library_list[i].subcontent.libraryId === certificate_list[j].subcontent.courseId) {
        //       library_list[i].subcontent.certificateUrl = certificate_list[j].subcontent.fileUrl;
        //       library_list[i].subcontent.certificateInfo = certificate_list[j].subcontent;
        //     }
        //   }
        // }
        this.setState({
          member_id: getContent.member_id,
          member_name: mainContent.memberName,
          member_email: mainContent.memberEmail,
          member_status_subscribe: mainContent.statusSubscribeMember,
          member_expired_subscription: mainContent.expiredSubscribeMember !== undefined? DateTimeFormats(mainContent.expiredSubscribeMember._seconds,"date-short") : '',
          member_picture: (mainContent.memberPicture !== undefined && mainContent.memberPicture !== "") ? mainContent.memberPicture : defMemberPicture,
          company_name: mainContent.memberCompany,
          member_info: mainContent,
          library_list: library_list,
          course_list: course_list
          // certificate_list: certificate_list
        });
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    })
  }
  render() {
    return (
      <div className="container mb-4">
        <div>
          <div className="mt-3 row">
              <div className="col-md-4"><img src={this.state.member_picture} alt="Member Pictures" className="wh-180 mb-4"/></div>
              <div className="col-md-8">
                <h5>{this.state.member_name}</h5>
                {this.state.member_email}
                <div className="mt-2">
                  <label className="fw-bold">Status Subscription</label><br/>
                  <label className={`${
                      (this.state.member_status_subscribe === global.config.subscribeMemberStatus[2]) ? "bg-success " : "bg-secondary "
                    } rounded text-white ps-2 pe-2 pt-1 pb-1 mt-2`}>
                      {this.state.member_status_subscribe}
                  </label> <br/>
                  <label className="mt-1">{this.state.member_status_subscribe === global.config.subscribeMemberStatus[2]? "(Expired on : "+ this.state.member_expired_subscription+")"  : ''}</label>
                </div>
              </div>
              
          </div>
          <div className="mt-4">
              <div className="row">
                <div className="col-9 fw-bold"><h6>Enrolled Library</h6></div>
                <div className="col-3"></div>
              </div>
              <div className="mt-2">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Library</th>
                      <th scope="col">Last Visited</th>
                      <th scope="col">Last Module</th>
                      <th scope="col">Progress</th>
                      <th scope="col">Certificate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.libraryContent(this.state.library_list)}
                  </tbody>
                </table>
              </div>
          </div>
          <div className="mt-5">
              <div className="row">
                <div className="col-9 fw-bold"><h6>Applied Workshops</h6></div>
                <div className="col-3"></div>
              </div>
              <div className="mt-2">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Workshop Name</th>
                      <th scope="col">Registered Date</th>
                      <th scope="col">Ticket ID</th>
                      <th scope="col">Checkin Date</th>
                      <th scope="col">Certificate</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.courseContent(this.state.course_list)}
                  </tbody>
                </table>
              </div>
          </div>

          
        </div>
        <div id="loading-area" className="d-none loading"></div> 
      </div>
    );
  }
}
