import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import { FaFileLines, FaDownload } from "react-icons/fa6";
// import DescriptionIcon from '@mui/icons-material/Description';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import DownloadIcon from '@mui/icons-material/Download';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import DateTimeFormats from '../config/date-time-format';
import defMemberPicture from '../img/pic-profile-sq.gif';
import Popup from '../inc/PopUp';
import CTWorkshopDetail from './ct-usertraining-pop-course';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;

export default class CTUserTrainingsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      memberdoc_id: "",
      member_email: "",
      member_fullname: "",
      member_picture: "",
      member_jobs: "",
      member_regdate: "",
      member_status_subscribe: "",
      member_plan: "",
      training_plan: "",
      status_active: "",
      member_company:"",
      certificate_bg: "",
      subscribe_list: [],
      membercourse_list: [],
      memberlibrary_list: [],
      certificate_list: [],
      isOpen: false,
      otherContent: "",
      isOpen2: false,
      otherContent2: "",
    }
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
    // this.agendaContent = this.agendaContent.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
      }
    })
  }
  togglePopClose2() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen2, isOpen2: false,
      }
    })
  }
  urldetailSubscribe(valId) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: true,
        ...prevState.otherContent, otherContent: '{"subscribe_id":"'+valId+'","member_id":"'+this.state.memberdoc_id+'","member_fullname":"'+this.state.member_fullname+'","member_email":"'+this.state.member_email+'","member_status_subscribe":"'+this.state.member_status_subscribe+'","member_plan":"'+this.state.member_plan+'"}'
      }
    })
  }

  urldetailCourse(valId,content) {
    var sendContents = {
      "docid": valId,
      "member_id": this.state.memberdoc_id,
      "member_fullname": this.state.member_fullname,
      "member_email": this.state.member_email,
      "joincourse_content": content
    }

    this.setState( prevState => {
      return {  
        ...prevState.isOpen2, isOpen2: true,
        ...prevState.otherContent2, otherContent2: JSON.stringify(sendContents)
        // ...prevState.otherContent2, otherContent2: '{"docid":"'+valId+'","member_id":"'+this.state.memberdoc_id+'","member_fullname":"'+this.state.member_fullname+'","member_email":"'+this.state.member_email+'","joincourse_content":'+content+'}'
      }
    })
  }

  memberSubscribeContent = (dataMemberSubscribe) => {
    var subscribeList = [];
    if(this.state.subscribe_list !== undefined && this.state.subscribe_list.length > 0) {
      for(var ls=0; ls < this.state.subscribe_list.length; ls++) {
        var retResult = this.state.subscribe_list[ls];
        var reqDatez = "", reqPrice=0;
        if(retResult.subcontent.requestSubscribeDate !== undefined && retResult.subcontent.requestSubscribeDate !== "") {
          reqDatez = DateTimeFormats(retResult.subcontent.requestSubscribeDate._seconds,"date-short");
        }
        if(retResult.subcontent.subscribeGTotal !== undefined && retResult.subcontent.subscribeGTotal !== "" && retResult.subcontent.subscribeGTotal !== null) {
          reqPrice = retResult.subcontent.subscribeGTotal.toLocaleString('en-US',{maximumFractionDigits:2});
        }
        subscribeList.push(
          <div className="subs-block" onClick={this.urldetailSubscribe.bind(this, retResult.subdocid)}>
            {
              retResult.subcontent.subscribeStatus === 'New Request' ? (
                retResult.subcontent.paymentConfirmationDate ? (
                  <label className="d-inline-flex bg-warning p-2 rounded">Verifying</label> 
                ) : (
                  <label className="d-inline-flex bg-info text-white p-2 rounded">New Request</label> 
                )
              ) : (
                <label className="d-inline-flex bg-success text-white p-2 rounded">Completed</label>
              )
            }
            <h4 className="subs-block-title">{retResult.subcontent.subscribePlan} Subscription</h4>
            <p className="subs-block-price">IDR {reqPrice}</p>
            <p className="subs-block-date">{reqDatez}</p>
            <FaFileLines className="subs-block-view iconGrey cursor-on" onClick={this.urldetailSubscribe.bind(this, retResult.subdocid)} />
          </div>
        )
      }
    } else {
      subscribeList.push(<p className="text-center mt-3">- no data -</p>)
    }
    return [subscribeList];
  }
  
  memberCourseContent = (dataMemberCourse) => {
    var membercourseList = [];
    if(dataMemberCourse !== undefined && dataMemberCourse.length > 0) {
      for(var ls2=0; ls2 < dataMemberCourse.length; ls2++) {
        var retResult2 = dataMemberCourse[ls2];
        membercourseList.push(
          <tr key={ls2+1}>
            <th scope="row">{ls2+1}</th>
            <td>
              <span className="fw-bold">{retResult2.subcontent.courseTitle}</span>
              {retResult2.subcontent.courseStartTime? <span><br/>Event Start on :  {DateTimeFormats(retResult2.subcontent.courseStartTime._seconds,"datetime-short")}</span> : ''}
              {retResult2.subcontent.courseEndTime? <span><br/>Event End on :  {DateTimeFormats(retResult2.subcontent.courseEndTime._seconds,"datetime-short")}</span> : ''}
            </td>
            <td>{DateTimeFormats(retResult2.subcontent.createdDate._seconds,"date-short")}</td>
            <td>{retResult2.subcontent.ticketId}</td>
            <td>
              {retResult2.subcontent.checkInDate? DateTimeFormats(retResult2.subcontent.checkInDate._seconds,"datetime-short") : ''}
            </td>
            <td>
              <FaFileLines className="styleicons" onClick={this.urldetailCourse.bind(this, retResult2.subdocid,retResult2)} />
            </td>
          </tr>
        )
      }
    } else {
      membercourseList.push(<tr key="1">
        <th scope="row"></th>
        <td></td>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [membercourseList];
  }
  memberLibraryContent = (dataMemberLibrary) => {
    var memberlibraryList = [];
    if(dataMemberLibrary !== undefined && dataMemberLibrary.length > 0) {
      for(var ls3=0; ls3 < dataMemberLibrary.length; ls3++) {
        var retResult3 = dataMemberLibrary[ls3];
        var certificateStat = "-";
          if(this.state.certificate_list.length > 0) {
            for(var ct=0; ct<this.state.certificate_list.length; ct++) {
              var certData = this.state.certificate_list[ct].subcontent;
                if (certData.courseType === "library" && certData.courseId === retResult3.subcontent.libraryId) {
                  certificateStat = <a className="text-secondary" href={certData.fileUrl} target="_blank" rel="noreferrer" download><FaDownload className="mx-2 styleicons" style={{height:"30px", cursor:"Pointer"}}/></a>;
                }
            }
          }
        memberlibraryList.push(
          <tr key={ls3+1}>
            <th scope="row">{ls3+1}</th>
            <td>
              {retResult3.subcontent.libraryTitle}
            </td>
            <td>{DateTimeFormats(retResult3.subcontent.lastVisit._seconds,"date-short")}</td>
            <td>{retResult3.subcontent.lastLesson > 0 ? (GetText() ?  'Modul ' : 'Module ') + retResult3.subcontent.lastLesson : (GetText() ?  'Pengantar ' : 'Introduction')}</td>
            <td>{retResult3.subcontent.completion}%</td>
            <td>{certificateStat}</td>
          </tr>
        )
      }
    } else {
      memberlibraryList.push(<tr key="1">
        <th scope="row"></th>
        <td></td>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      )
    }
    return [memberlibraryList];
  }

  loadContent = (docid) => {
    return new Promise((resolve) => {
      var dataSend = { docid: docid }
      fetch(global.config.urlLink+"/traininguserview", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        },
        body : JSON.stringify(dataSend)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        resolve(resJson);
      })
    })
  }
  async loadData(currpage,typelink,docid) {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result1] = await Promise.all([this.loadContent(docid)]);
        if(result1 !== undefined && result1.status === '200') { 
          document.getElementById('loading-area').style.display = 'none'; 
          if(result1.values[0].content[0].maincontent !== undefined) {

            var memberPicture = '', regDatez = '', certificateBg = '';
            if(result1.values[0].content[0].maincontent.memberPicture !== undefined && result1.values[0].content[0].maincontent.memberPicture !== '') {
              memberPicture = result1.values[0].content[0].maincontent.memberPicture;
            } else { 
              memberPicture = defMemberPicture; 
            }
            if(result1.values[0].content[0].maincontent.registeredDate !== undefined && result1.values[0].content[0].maincontent.registeredDate !== "") {
              regDatez = DateTimeFormats(result1.values[0].content[0].maincontent.registeredDate._seconds,"date-long");
            }
            if(result1.values[0].content[0].maincontent.certificateBg !== undefined && result1.values[0].content[0].maincontent.certificateBg !== "") {
              certificateBg = result1.values[0].content[0].maincontent.certificateBg;
            }
            this.setState({
              activelink: currpage,
              stTitle: GetText() ? "Detil Pengguna" : "Detail Users",
              memberdoc_id: result1.values[0].docid,
              member_email: result1.values[0].content[0].maincontent.memberEmail,
              member_fullname: result1.values[0].content[0].maincontent.memberName,
              member_picture: memberPicture,
              member_jobs: result1.values[0].content[0].maincontent.memberJobs,
              member_regdate: regDatez,
              member_status_subscribe: result1.values[0].content[0].maincontent.statusSubscribeMember,
              member_plan: result1.values[0].content[0].maincontent.memberPlan,
              training_plan: result1.values[0].content[0].maincontent.trainingPlan,
              status_active: result1.values[0].content[0].maincontent.flagActive,
              member_company:result1.values[0].content[0].maincontent.memberCompany,
              certificate_bg: certificateBg,
              subscribe_list: result1.values[0].scontent.sort((b, a) => a.subcontent.requestSubscribeDate._seconds - b.subcontent.requestSubscribeDate._seconds),
              membercourse_list: result1.values[0].scontent2.sort((b, a) => a.subcontent.courseDate._seconds - b.subcontent.courseDate._seconds),
              memberlibrary_list: result1.values[0].scontent3.sort((b, a) => a.subcontent.libraryTitle < b.subcontent.libraryTitle),
              certificate_list: result1.values[0].scontent4.sort((b, a) => a.subcontent.fileDate._seconds - b.subcontent.fileDate._seconds),
              isOpen: false,
              otherContent: ''
            })

          } else {
            // window.location.href=global.config.urlCms+"/"+currpage+"/add";
          }
        } else {
          // window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
      
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    var membercourseList = this.memberCourseContent(this.state.membercourse_list);
    var subscribeList = this.memberSubscribeContent(this.state.subscribe_list);
    var memberlibraryList = this.memberLibraryContent(this.state.memberlibrary_list);
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Pengguna" : " Users"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-9">
                            <h5>{this.state.member_fullname} </h5>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row">
                        <span className="text-muted">Joined On : {this.state.member_regdate}</span>
                        <span className="text-muted">status : {this.state.status_active === true? "Active":"Inactive"}</span>
                      </div>
                      {/* ==== start - detail member =============== */}
                      <div id="profile-detail" className="mt-3">
                        <div className="row">
                          <div id="profile-info" className="col-6">
                            {
                              this.state.member_picture !== '' ? (
                                <div className="mb-2"><img src={this.state.member_picture} alt={this.state.member_fullname} className="wh-180 mb-4"/></div>
                              ) : ''
                            }
                            <div className="row">
                              <div className="col-12"><span className="fw-bold fst-italic">{this.state.member_email}</span></div>
                            </div>
                            <div className="row">
                              <div className="col-12">{this.state.member_jobs}</div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-12">Status Subscription : {this.state.member_status_subscribe}</div>
                            </div>
                            <div className="row">
                              <div className="col-12">Join Workshops : { this.state.membercourse_list.length > 0 ? <span>YES</span> : <span>NO</span> }</div>
                            </div>
                            {this.state.member_company !== "" && 
                            <div className="row">
                              <div className="col-12">Company : { this.state.member_company }</div>
                            </div>
                            }
                            {
                              this.state.certificate_bg ? (    
                                <div className="row">
                                  <div className="col-12">Certificate Background : <a href={this.state.certificate_bg} target="_blank"><img className="d-block" src={this.state.certificate_bg} width="150px"/></a></div>
                                </div>
                              ) : ''
                            }
                          </div>
                          <div className="col-6">
                            <div className="row">
                                <div className="col-12 fw-bold"><h6>History User Subscription</h6></div>
                            </div>
                            <div className="col-12">
                              {subscribeList}
                            </div>                      
                          </div>
                        </div>
                          
                        <div className="mt-5" id="workshop-area">
                          <div className="row">
                            <div className="col-9 fw-bold"><h6>Applied Workshops</h6></div>
                            <div className="col-3"></div>
                          </div>
                          <div className="mt-2">
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Workshop Name</th>
                                  <th scope="col">Registered Date</th>
                                  <th scope="col">Ticket ID</th>
                                  <th scope="col">Checkin Date</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {membercourseList}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="mt-5" id="library-area">
                          <div className="row">
                            <div className="col-9 fw-bold"><h6>Enrolled Libraries</h6></div>
                            <div className="col-3"></div>
                          </div>
                          <div className="mt-2">
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Library</th>
                                  <th scope="col">Last Viewed</th>
                                  <th scope="col">Last Module</th>
                                  <th scope="col">Progress</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {memberlibraryList}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      

                      {/* ==== end - course elemen =============== */}
                    </div>
                  </div>
                </div>
              {/* === end - body */}
                {
                  // this.state.isOpen && <Popup 
                  //   content={<CTSubscribeDetail/>}
                  //   id={this.state.memberdoc_id} 
                  //   title={"History User Subscription"}
                  //   others={this.state.otherContent}
                  //   styles={"70%"}
                  //   handleClose={this.togglePopClose.bind(this, this.state.memberdoc_id)}
                  // />
                }
                {
                  this.state.isOpen2 && <Popup
                    content={<><CTWorkshopDetail/></>}
                    id={this.state.memberdoc_id} 
                    title={"History User Applied Workshop"}
                    others={this.state.otherContent2}
                    styles={"70%"}
                    handleClose={this.togglePopClose2.bind(this, this.state.memberdoc_id)}
                  />
                }
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
