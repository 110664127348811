import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaPlus, FaPen, FaFileLines, FaMagnifyingGlass } from "react-icons/fa6";

// import EditIcon from '@mui/icons-material/Edit';
// import DescriptionIcon from '@mui/icons-material/Description';
// import AddIcon from '@mui/icons-material/Add';
// import SearchIcon from '@mui/icons-material/Search';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import DateTimeFormats from '../config/date-time-format';


const authKey = global.config.kindcode.training[6]+global.config.authBearerKey;

export default class CTCompanyTrainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      pageShow: '10',
      keyword: '',
      data_all: [],
      data: []
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  urladdCompany(currpage) {
    window.location.href=global.config.urlCms+"/"+currpage+'/add';
  }
  handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    var keyw="";
    if(this.textInputKeyword.value === '') { keyw = this.state.keyword; } else { keyw = this.textInputKeyword.value; }
    var resultList = this.state.data_all.sort((a, b) => a.companyName > b.companyName);
    var resultID = resultList.filter((list) =>  {
      return (list.companyName.toLowerCase().match(keyw.toLowerCase()) || list.companyDomain.toLowerCase().match(keyw.toLowerCase()));
    });
    this.setState({
      data: resultID
    })
    this.loadContent(resultID)
  }
  loadContent(contents) {
    var companyList = [];
    if(contents.length > 0) {
      // contents.sort((a, b) => a.content.memberName > b.content.memberName);
      for(var ls=0; ls < contents.length; ls++) {
          var retResult = contents[ls];
          var editURL = global.config.urlCms+"/"+this.state.activelink+"/edit/"+retResult.docId;
          var viewURL = global.config.urlCms+"/"+this.state.activelink+"/view/"+retResult.docId;
          // var companyItem = retResult;
          companyList.push(<tr key={retResult.docId}>
              <th scope="row-field">{ls+1}</th>
              <td>{retResult.companyName}</td>
              <td>{retResult.companyDomain}</td>
              <td>{retResult.expiredDate !== undefined? DateTimeFormats(retResult.expiredDate._seconds, "date-long") : ''}</td>
              <td>{retResult.users}</td>
              <td>
                <a href={editURL}><FaPen className="styleicons" title="edit" /></a>
                <a href={viewURL}><FaFileLines className="styleicons" title="view" /></a>
              </td>
            </tr>)
      }
    } else {
      companyList.push(<tr key="1">
          <th scope="row"></th>
          <td colspan={2} className="d-flex p-3">- no data -</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>)
    }
    return companyList;
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","form_id":"company-with-numuser"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/trainingcompanylist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var resultdata = resJson.values.sort((a, b) => a.companyName > b.companyName);
        this.setState({
          data: resultdata,
          data_all: resultdata
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  componentDidMount() {
    var url = window.location.href;
    var arrUrl = url.split("/");
    // var tabKey = url.substring(url.lastIndexOf('/') + 1);
    var tabKey = arrUrl[arrUrl.length-1];
    if(tabKey === "") {
      tabKey = arrUrl[arrUrl.length-2];
    }
    this.setState({
      activelink: tabKey
    });
    this.loadData();
  }

  render() {
    var companyLists = this.loadContent(this.state.data);
    return (
      <div className="setting">
       <Container>
        {
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    this.state.activelink !== "none" && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3>{GetText() ? "Perusahaan" : "Companies"}</h3>
                      <span className="text-muted">{GetText() ? "Daftar Perusahaan di Novaio Academy" : "List of Companies in Novaio Academy"}</span>
                    </div>
                    <div className="mt-3">
                      <div className="row mt-2">
                          <div className="col-6">
                          <form onSubmit={this.handleSubmit} className=" d-flex justify-content-start">
                              <input type="text" className="form-control" name="keyword"
                                placeholder="search name"
                                value={this.state.keyword}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputKeyword = input}/>
                              <FaMagnifyingGlass style={{ fontSize: 35 }} className="styleicons ms-2" onClick={this.handleSubmit} />
                          </form>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                              {this.state.activelink !== "" && <Button variant="secondary" size="sm" onClick={this.urladdCompany.bind(this, this.state.activelink)}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />Add New Comopany</Button> }
                          </div>
                      </div>
                      <div className="mt-3">
                          <table className="table">
                            <thead className="table-dark">
                              <tr key="0">
                              <th width="50" className="text-center" scope="col">#</th>
                              <th scope="col">Company Name</th>
                              <th scope="col">Company Domain</th>
                              <th scope="col">Expiry Date</th>
                              <th scope="col">Users</th>
                              <th width="150" scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                                {companyLists}
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
