import axios from 'axios';
import '../config/global';

class MyUploadAdapter {
    constructor( loader , config) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.config = config._config;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {

                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                }); 
                
                return toBase64(file).then(cFile=>{
                    document.getElementById('loading-area').style.display = 'block'; 
                    const data = new FormData();
                    data.append('file', file);
                    data.append('docid', this.config.state.content_docid);
                    data.append('source', this.config.state.source);
                    console.log(this.config);
                    console.log(this.loader);
                    
                    return axios.post(global.config.urlLink+"/uploadfile", 
                        data, { 
                            headers: { 
                                'content-type': 'multipart/form-data',
                                'Authorization': this.config.key
                            }
                        }).then(res => { 
                            document.getElementById('loading-area').style.display = 'none'; 
                            if(res.data.status === "200") {
                                resolve( {
                                    default: res.data.values.linkfile1
                                } );
                            } else {
                                document.getElementById('loading-area').style.display = 'none';
                                reject(`Couldn't upload file: ${ file.name }.`)
                            }
                        }).catch(err => { 
                            document.getElementById('loading-area').style.display = 'none'; 
                            reject(`Couldn't upload file: ${ file.name }.`)
                        })
                    // return  Fetch("admin/uploadimage", {
                    //     imageBinary: cFile
                    // }).then((d) => {
                    //     if (d.status) {
                    //         this.loader.uploaded = true;
                    //         resolve( {
                    //             default: d.response.url
                    //         } );
                    //     } else {
                    //         reject(`Couldn't upload file: ${ file.name }.`)
                    //     }
                    // });
                })
                
            } ) );
    }

   
}

// ...

export default function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        console.log("loader");
        console.log(loader)
        console.log("editor");
        console.log(editor);
        return new MyUploadAdapter( loader , editor.config );
    };
}