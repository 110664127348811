import React, { Component } from "react";
import Moment from 'moment';
import { GetText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';

export default class InvoiceContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order_fullname: '',
			order_email: '',
			order_id: '',
			order_date: '',
			order_status: '',
			order_items: [],
			order_subtotal: 0,
			order_grandtotal: 0,
			order_voucheramount: 0,
			payment_currency: '',
			payment_currency_symbol: '',
			payment_method: '',
			payment_bank: '',
			payment_account: '',
			payment_date: '',
			confirmation_date: '',
			date_now: DateTimeFormats(0,"datetime-long")
		}
	}
	componentDidMount() {
		var content = this.props.value;
		var timezone = 'en-GB';
		var datetimeoption = '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
		// var date_ob = new Date(dtSeconds);
    	var resDateTimeFormat = new Intl.DateTimeFormat(timezone, JSON.parse(datetimeoption)).format(new Date());
    
		this.setState({
			order_fullname: content.order_fullname,
			order_email: content.order_email,
			order_id: content.order_id,
			order_date: content.order_date,
			order_status: content.order_status,
			order_items: content.order_items,
			order_subtotal: content.order_subtotal,
			order_grandtotal: content.order_grandtotal,
			order_voucheramount: content.order_voucheramount,
			payment_currency: content.payment_currency,
			payment_currency_symbol: (content.payment_currency  === global.config.currency.name[1])? global.config.currency.symbol[1] : global.config.currency.symbol[0],
			payment_method: content.payment_method,
			payment_bank: content.payment_bank,
			payment_account: content.payment_account,
			payment_date: content.payment_date,
			confirmation_date: content.confirmation_date,
			
		});
	}

	
	render() {
		
		return (
		<div className="position-relative">
		<div className="position-absolute top-0 start-0">
			<div className="card-invoice">
				<div>
				  <table style={{width:"100%"}}>
					<tr>
						<td width="50%"><img src={process.env.PUBLIC_URL + "/img/logo-novaio.png"} width="150" alt="Novaio Academy"/></td>
						<td width="50%" style={{textAlign:"end"}}>
							<h1 style={{marginTop:"10px",fontWeight:"bold"}}>INVOICE</h1>
							<span>{GetText() ? "Nomor Invoice" : "Invoice No"} : {this.state.order_id}</span>
						</td>
					</tr>
					<tr>
						<td width="50%"></td>
						<td width="50%">
							<table cellPadding="0" cellSpacing="0" width="100%" style={{marginTop:"20px"}}>
								<tr>
									<td width="40%" style={{fontWeight:"bold"}}>{GetText() ? "Tanggal Pesanan" : "Order Date"}</td>
									<td width="60%" >{this.state.order_date}</td>
								</tr>
								<tr>
									<td width="40%" style={{fontWeight:"bold",verticalAlign:"top"}}>{GetText() ? "Ditagihkan Kepada" : "Billed To"}</td>
									<td width="60%">{this.state.order_fullname}<br/>{this.state.order_email}</td>
								</tr>
							</table>
						</td>
					</tr>
				  </table>
				</div>
				<div>
				  <table style={{width:"100%", marginTop:"50px"}}>
					<thead>
						<tr>
							<td className="col-3 border-start-0 border-end-0 border-1 pt-3 pb-3"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></td>
							<td className="col-1 border-start-0 border-end-0 border-1 pt-3 pb-3 text-center"><strong>{GetText() ? "Jumlah" : "Qty"}</strong></td>
							<td className="col-2 border-start-0 border-end-0 border-1 pt-3 pb-3 text-end"><strong>{GetText() ? "Harga" : "Amount"}</strong></td>
						</tr>
					</thead>
					<tbody>
					{
						this.state.order_items.map((item, c) =>{
							var title = ["",""];
                            (item.subsubcontent.itemType === global.config.orderItemType[0]) ?
                              title = [
                                'Bergabung di Workshop dengan topik "'+item.subsubcontent.itemDescription.courseTitle+'"',
                                'Join workshop with topic "'+item.subsubcontent.itemDescription.courseTitle+'"'
                              ]
                            : (item.subsubcontent.itemDescription.subscriptionType === global.config.subscribePlan[0].type)?
                              title = [
                                'Satu Bulan Langganan Keanggotaan',
                                'One Month Membership Subscription',
                              ]
                              : title = [
                                'Satu Tahun Langganan Keanggotaan',
                                'One Year Membership Subscription',
                              ]
						  return (
							<tr key={c}>
								<td className="col-3 pt-1 pb-1">{GetText() ? title[0] : title[1]}</td>
								<td className="col-1 text-center pt-1 pb-1">{item.subsubcontent.quantity}</td>
								<td className="col-2 text-end pt-1 pb-1">{(item.subsubcontent.itemCurrency === global.config.currency.name[1])? global.config.currency.symbol[1] : global.config.currency.symbol[0] + NumberFormats(item.subsubcontent.itemPrice)}</td>
							</tr>
						  )
						})
					}
						{(this.state.order_subtotal !== this.state.order_grandtotal)? 
						<>
						<tr key="-1">
							<td colSpan="2" className="text-end border-bottom-0 border-end-0 border-start-0 border-1 pt-1 pb-1"><strong>Subtotal</strong></td>
							<td className="text-end border-bottom-0 border-end-0 border-start-0 border-1 pt-1 pb-1"><strong>{NumberFormats(this.state.order_subtotal)}</strong></td>
						</tr>
						<tr key="-2">
							<td colSpan="2" className="text-end border-0 pt-1 pb-1"><strong>Voucher</strong></td>
							<td className="text-end border-0 pt-1 pb-1"><strong>{NumberFormats(this.state.order_voucheramount)}</strong></td>
						</tr>
						</>
						: <></>
						}
						
						</tbody>
						<tfoot className="card-footer">
						<tr>
							<td colSpan="2" className="text-end border-bottom-0 border-end-0 border-start-0 border-1 pt-1 pb-1"><strong>Total</strong></td>
							<td className="text-end border-bottom-0 border-end-0 border-start-0 border-1 pt-1 pb-1"><strong>{this.state.payment_currency_symbol + NumberFormats(this.state.order_grandtotal)}</strong></td>
						</tr>
						</tfoot>
				  </table>
				</div>
				<div>
				  <table style={{width:"100%", marginTop:"50px"}}>
				  	<tr>
						<td width="50%"></td>
						<td width="50%">
							{
								(this.state.order_status === global.config.orderStatus[4] || this.state.order_status === global.config.orderStatus[3] || this.state.order_status === global.config.orderStatus[5])? 
									<table cellPadding="0" cellSpacing="0" width="100%">
										<tr style={{height:"40px"}}>
											<td width="40%" style={{fontWeight:"bold", verticalAlign:"top"}}>{GetText() ? "Status Pemesanan" : "Order Status"}</td>
											<td width="60%" style={{fontWeight:"bold", verticalAlign:"top"}}>{GetText() ? "DIBATALKAN" : "CANCELED"}</td>
										</tr>
									</table>
								: 
									<table cellPadding="0" cellSpacing="0" width="100%">
										<tr style={{height:"40px"}}>
											<td width="40%" style={{fontWeight:"bold", verticalAlign:"top"}}>{GetText() ? "Status Pembayaran" : "Payment Status"}</td>
											<td width="60%" style={{fontWeight:"bold", verticalAlign:"top"}}>{ (this.state.payment_date !== "")? (GetText() ? "LUNAS" : "PAID") : (GetText() ? "BELUM BAYAR" : "UNPAID") }</td>
										</tr>
										<tr>
											<td style={{fontWeight:"bold", verticalAlign:"top"}}> {GetText() ? "Metode Pembayaran" : "Payment Method"} </td>
											<td>
												{this.state.payment_method}
											</td>
										</tr>
										{ this.state.confirmation_date === "" && 
											(this.state.payment_method === global.config.paymentMethod.IDN[1].value || this.state.payment_method === global.config.paymentMethod.IDN[2].value) && 
											(this.state.payment_bank !== "" || this.state.payment_account !== "") && 
										<tr>
											<td></td>
											<td>{GetText() ? "Transfer ke" : "Transfer to"} <br/>{this.state.payment_bank} {this.state.payment_account}</td>
										</tr>
										}
									</table>

							}
								
						</td>
					</tr>
				  </table>
				</div>
				<div style={{width:"100%", marginTop:"100px"}}>
				  <span style={{fontSize:"10px", fontStyle:"italic"}}>{(GetText() ? "* Dalam kondisi apa pun Novaio Academy tidak bertanggung jawab atas konsekuensi khusus, atau kerusakan yang diakibatkan oleh pelanggaran garansi, keterlambatan atau kinerja Novaio Academy atau wanprestasi lainnya berdasarkan perjanjian ini." : "* In no event shall Seller be liable for any special consequential, or contingent damages resulting from Seller's Breach of Warranty, delay or performance or any other default hereunder.")}</span>
				</div>
				<div>
				  <table style={{width:"100%", marginTop:"70px"}}>
				  	<tr>
						<td width="50%"></td>
						<td width="50%" style={{textAlign:"end"}}>
							<span style={{fontSize:"10px", fontStyle:"italic"}}>{(GetText() ? "Terakhir diupdate " : "Last Update ")}  {this.state.date_now}</span>
						</td>
					</tr>
				  </table>
				</div>
				
			</div>


                  {/* <div className="row mt-4 mb-0">
                    {
                      GetText() ? (
                        <div className="col-md-12">
                          <h4>SYARAT &amp; KETENTUAN</h4>
                          <address>Dalam kondisi apa pun Novaio Academy tidak bertanggung jawab atas konsekuensi khusus, atau kerusakan yang diakibatkan oleh pelanggaran garansi, keterlambatan atau kinerja Novaio Academy atau wanprestasi lainnya berdasarkan perjanjian ini.</address>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <h4>TERMS &amp; CONDITIONS</h4>
                          <address>In no event shall Seller be liable for any special consequential, or contingent damages resulting from Seller's Breach of Warranty, delay or performance or any other default hereunder.</address>
                        </div>                        
                      )
                    }
                  </div> */}
                
		</div>
		</div>
		);
	}
}
