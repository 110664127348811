import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Container, Button } from 'react-bootstrap';
import '../config/global';
import defMemberPicture from '../img/pic-profile-sq.gif';
import { FormErrors } from '../inc/error-form';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;

export default class CTUserCompanyEdit extends Component {
  constructor (props) {
    super(props);
      this.state = {
      stPopTitle: '',
      activelink: '',
      company_id: '',
      member_id: '',
      member_name: '',
      member_email: '',
      member_picture: '',
      user_level: '',
      user_active: '',
      expiry_date: new Date(),
      user_status_subscribe: '',
      formErrors: {user_level: '', user_active: '', expiry_date: '', user_status_subscribe: ''},
      userlevelValid: false,
      useractiveValid: false,
      expirydateValid: false,
      userstatussubscribeValid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
    
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    }, () => { this.validateField(name, value)});
  }

  handleDateInput = date => {
    var new_expiry = false;
    if (date !== this.state.expiry_date) {
      new_expiry = true;
    }
    this.setState({
      expiry_date: date,
      new_expiry: new_expiry
    });
  }
  handleUserSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let userlevelValid = this.state.userlevelValid;
    let useractiveValid = this.state.useractiveValid;
    let expirydateValid = this.state.expirydateValid;
    let userstatussubscribeValid = this.state.userstatussubscribeValid;
    
    switch(fieldName) {
      case 'user_level': 
        userlevelValid = value.trim().length > 0;
        fieldValidationErrors.user_level = userlevelValid ? '' : 'user level need to be filled';
        break;
      case 'user_active': 
        useractiveValid = value.trim().length > 0;
        fieldValidationErrors.user_active = useractiveValid ? '' : 'status user need to be filled';
        break;
      case 'user_status_subscribe': 
        userstatussubscribeValid = value.trim().length > 0;
        fieldValidationErrors.user_status_subscribe = userstatussubscribeValid ? '' : 'status subscription need to be filled';
        break;
      default:
        break;
    }    
    this.setState({
      formErrors: fieldValidationErrors,
      userlevelValid: userlevelValid,
      useractiveValid: useractiveValid,
      expirydateValid: expirydateValid,
      userstatussubscribeValid: userstatussubscribeValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.userlevelValid && this.state.useractiveValid && this.state.expirydateValid && this.state.userstatussubscribeValid 
    });
  }
  
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.user_level !== '' && this.state.expiry_date !== '' && this.state.user_active !== '' && this.state.user_status_subscribe !== '') {
          var urlFetch, datatext;
          var userLevel,userActive,userStatusSubscribe,expiryDate;
          if(this.textInputUserRole.value === '') { userLevel = this.state.user_level; } else { userLevel = this.textInputUserRole.value; }
          if(this.textInputUserStatus.value === '') { userActive = this.state.user_active; } else { userActive = this.textInputUserStatus.value; }
          if(this.textInputUserStatusSubscribe.value === '') { userStatusSubscribe = this.state.user_status_subscribe; } else { userStatusSubscribe = this.textInputUserStatusSubscribe.value; }
          expiryDate = this.state.expiry_date;
          
          if(this.state.member_id !== null && this.state.member_id !== ''  && this.state.member_id !== undefined) {
            var user_active = userActive === 'Active' ? true : false;
            datatext ='{"member_docid":"' + this.state.member_id + '","user_level":"' + userLevel + '","flag_active":' + user_active + ',"expired_subscribe_member":"' + this.state.expiry_date + '","user_status_subscribe":"' + userStatusSubscribe + '","form_id":"user-access"}';
            urlFetch = global.config.urlLink+"/traininguseredit";
          // } else {
          //     datatext ='{"user_level":"' + userLevel + '","flag_active":' + user_active + ',"expired_subscribe_member":"' + this.state.expiry_date + '","user_status_subscribe":"' + userStatusSubscribe + '","form_id":"user-access"}';
          //     urlFetch = global.config.urlLink+"/trainingcompanyadd";
          }
          var data = JSON.parse(datatext);
          fetch(urlFetch, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey,
              }, body : JSON.stringify(data)
          }).then(res => {
              if(res.status === 200)
                  return res.json() 
          }).then( resJson => {
              if(resJson.status === '200') {
                  // var docId = resJson.values;
                  window.location.href=global.config.urlCms+"/"+this.state.activelink+"/view/"+this.state.company_id;
              } else {
                  console.log('Something happened wrong');
              }
              document.getElementById('loading-area').style.display = 'none'; 
              if(resJson.status === '200') {
              } else {
                  console.log('Something happened wrong');
              }
          }).catch(err => { 
              document.getElementById('loading-area').style.display = 'none'; 
          });
        
    } else {
        var arrfieldName = ['user_level','user_active','expiry_date','user_status_subscribe'];
        var arrvalue = [this.textInputUserRole.value,this.textInputUserStatus.value,this.state.expiry_date,this.textInputUserStatusSubscribe.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }

  componentDidMount() {
    var getContent = JSON.parse(document.getElementById("contentoth").innerText);
    var dataSend = { docid: getContent.member_id }
    fetch(global.config.urlLink+"/traininguserview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      },
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resJson => {
        
      if(resJson.status === "200") {
        document.getElementById('loading-area').style.display = 'none';
        var mainContent = resJson.values[0].content[0].maincontent;
        this.setState({
          activelink: getContent.activelink,
          company_id: getContent.company_id,
          member_id: getContent.member_id,
          member_name: mainContent.memberName,
          member_email: mainContent.memberEmail,
          member_picture: mainContent.memberPicture ? mainContent.memberPicture : defMemberPicture,
          user_level: mainContent.userLevel,
          user_status_subscribe: mainContent.statusSubscribeMember,
          user_active: mainContent.flagActive === true ? 'Active' : 'Inactive',
          expiry_date: mainContent.expiredSubscribeMember ? (new Date(mainContent.expiredSubscribeMember._seconds * 1000)) : null
        });
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    })
  }
  render() {
    return (
      <div className="container mb-4">
        <div>
          <div className="mt-3">
            <div className="col-md-4"><img src={this.state.member_picture} alt="Member Pictures" className="wh-180 mb-4"/></div>
          </div>
          <div className="mt-4">
            <Container>
            <div id="FormCourseInfo">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row mt-3">
                        <label className="col-md-3" htmlFor="user_level">Level</label>
                        <div className="col-md-9">
                          <select name="user_level" className="form-control"
                              value={this.state.user_level} 
                              onChange={this.handleUserSelect}
                              ref={(input) => this.textInputUserRole = input}
                          >
                            <option value="Admin" key="level-admin">Admin</option>
                            <option value="User" key="level-user">User</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-md-3" htmlFor="user_active">Status</label>
                        <div className="col-md-9">
                          <select name="user_active" className="form-control" 
                              value={this.state.user_active} 
                              onChange={this.handleUserSelect}
                              ref={(input) => this.textInputUserStatus = input}
                              disabled={this.state.input_disabled}
                          >
                            <option value="Active" key="status-active">Active</option>
                            <option value="Inactive" key="status-inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-md-3" htmlFor="user_status_subscribe">Status Subscription</label>
                        <div className="col-md-9">
                          <select name="user_status_subscribe" className="form-control" 
                              value={this.state.user_status_subscribe} 
                              onChange={this.handleUserSelect}
                              ref={(input) => this.textInputUserStatusSubscribe = input}
                          >
                            <option value={global.config.subscribeMemberStatus[0]} key="1">{global.config.subscribeMemberStatus[0]}</option>
                            <option value={global.config.subscribeMemberStatus[2]} key="2">{global.config.subscribeMemberStatus[2]}</option>
                            <option value={global.config.subscribeMemberStatus[3]} key="3">{global.config.subscribeMemberStatus[3]}</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-md-3" htmlFor="expiry_date">Expiry Date</label>
                        <div className="col-md-9">
                          <DatePicker
                                onChange={this.handleDateInput}
                                value={this.state.expiry_date}
                                selected={this.state.expiry_date}
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                                />
                            
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.expiry_date}/>  }</div>
                        </div>
                      </div>
                      <div className="row mt-4 mb-3">
                          <div className="col-md-3"></div>
                          <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                      </div>
                    </form>
            </div>
            </Container>
          </div>
          
        </div>
        <div id="loading-area" className="d-none loading"></div> 
      </div>
    );
  }
}
