import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';

const authKey = global.config.kindcode.training[5]+global.config.authBearerKey;

export default class CTFeedbackDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      feedback_docid: '',
      feedback_number: '',
      feedback_text: '',
      feedback_type: '',
      feedback_status: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: ''
    }
    this.loadDetailData = this.loadDetailData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  
  loadDetailData(currpage,typelink,docid) {
        if(docid !== '') { 
            document.getElementById('loading-area').style.display = 'block';
            fetch(global.config.urlLink+"/feedbackview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                  console.log(resJson);
                  if(resJson.status === '200') {
                    if(resJson.values[0].content[0].maincontent !== undefined) {
                    
                      var lText,lNumber,lType,lStatus;
                      var option1,option2,option3,option4,option5;
                      var resultContent = resJson.values[0].content[0].maincontent;
                      if(resultContent.feedbackText !== undefined) {
                          lText = ReplaceAllContent('[;]',',',resultContent.feedbackText);
                          lText = ReplaceAllContent('<br/>','\n',lText);
                      }
                      if(resultContent.feedbackNumber !== undefined) { lNumber = resultContent.feedbackNumber; }
                      if(resultContent.feedbackType !== undefined) { lType = resultContent.feedbackType; }
                      if(resultContent.feedbackStatus !== undefined) { lStatus = resultContent.feedbackStatus; }
                      if(resultContent.option1 !== undefined) { option1 = resultContent.option1; }
                      if(resultContent.option2 !== undefined) { option2 = resultContent.option2; }
                      if(resultContent.option3 !== undefined) { option3 = resultContent.option3; }
                      if(resultContent.option4 !== undefined) { option4 = resultContent.option4; }
                      if(resultContent.option5 !== undefined) { option5 = resultContent.option5; }
                      this.setState({
                          activelink: currpage,
                          stTitle: 'Detail Question',
                          feedback_docid: resJson.values[0].docid,
                          feedback_text: lText,
                          feedback_number: lNumber,
                          feedback_type: lType,
                          feedback_status: lStatus,
                          option1: option1,
                          option2: option2,
                          option3: option3,
                          option4: option4,
                          option5: option5
                      }); 
                      document.getElementById('loading-area').style.display = 'none';
                    } else {
                      window.location.href=global.config.urlCms+"/"+currpage+"/add";
                    }
                  } else {
                    console.log('Something wrong happened');
                    document.getElementById('loading-area').style.display = 'none';
                  }
                }).catch(err => err);
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "view")? "Detail " : ""
        });
    } else {
      this.loadDetailData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Feedback" : " Feedback"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} #{this.state.feedback_number}</h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                          <div className="row mt-3">
                            <label>Type : {this.state.feedback_type}</label><br/>
                            <label>Status : {this.state.feedback_status === 1? "Active": "Inactive"}</label>
                          </div>
                          <div className="row mt-3">
                            <label htmlFor="feedback_text">Question :</label>
                            <div>{this.state.feedback_text}</div>
                          </div>
                           
                          {this.state.feedback_type === "Multiple Choice" && 
                            <div className="row mt-3">
                                <label htmlFor="feedback_text">The answer for this question :</label>
                                <div>
                                  <ol className="list-alpha">
                                    {this.state.option1 ? <li>{this.state.option1}</li> : ''}
                                    {this.state.option2 ? <li>{this.state.option2}</li> : ''}
                                    {this.state.option3 ? <li>{this.state.option3}</li> : ''}
                                    {this.state.option4 ? <li>{this.state.option4}</li> : ''}
                                    {this.state.option5 ? <li>{this.state.option5}</li> : ''}
                                  </ol>
                                </div>
                            </div>
                          }
                          

                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
