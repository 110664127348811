import React, { Component } from 'react';
import moment from 'moment';
import { GetText } from '../../config/helper';

export default class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  }

  componentDidMount() {
    this.updateCountdown();
    this.interval = setInterval(this.updateCountdown, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateCountdown = () => {
    const targetDate = moment(this.props.value);
    const now = moment();
    const duration = moment.duration(targetDate.diff(now));

    const remainingTime = {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };

    this.setState({ remainingTime });

    if(remainingTime.seconds < 0) { window.location.reload(); }
  };

  render() {
    const { remainingTime } = this.state;

    return (
      <div className="d-inline-flex">
        {
          remainingTime.days > 0 && (
          <>
            <div className="p-2 bg-body rounded" style={{width:"100px"}}>
              <div className="fs-2 text-center text-secondary">{remainingTime.days} </div> 
              <div className="fs-5 text-center text-black-50">{GetText() ? "Hari" : "Days"}</div>
            </div>
            <div className=" text-center text-white" style={{width:"50px", marginTop:"30px"}}><span className="fs-3" style={{marginTop:"50px"}}>:</span></div>
          </>
          )
        }
        {
          remainingTime.hours > 0 && (
          <>
            <div className="p-2  bg-body rounded" style={{width:"100px"}}>
              <div className="fs-2 text-center text-secondary">{remainingTime.hours} </div> 
              <div className="fs-5 text-center text-black-50">{GetText() ? "Jam" : "Hours"}</div>
            </div>
            <div className=" text-center text-white" style={{width:"50px", marginTop:"30px"}}><span className="fs-3" style={{marginTop:"50px"}}>:</span></div>
          </>
          )
        }
            <div className="p-2 bg-body rounded" style={{width:"100px"}}>
              <div className="fs-2 text-center text-secondary">{remainingTime.minutes} </div> 
              <div className="fs-5 text-center text-black-50">{GetText() ? "Menit" : "Minutes"}</div>
            </div>
            <div className=" text-center text-white" style={{width:"50px", marginTop:"30px"}}><span className="fs-3" style={{marginTop:"50px"}}>:</span></div>
            <div className="p-2 bg-body rounded" style={{width:"100px"}}>
              <div className="fs-2 text-center text-secondary">{remainingTime.seconds} </div> 
              <div className="fs-5 text-center text-black-50">{GetText() ? "Detik" : "Seconds"}</div>
            </div>
        
      </div>

      // <p className="countdown">
      //   {
      //     remainingTime.days > 0 && (
      //       <span>{remainingTime.days} <span>{GetText() ? "Hari" : "Days"}</span></span> 
      //     )
      //   }
      //   {
      //     remainingTime.hours > 0 && (
      //       <span>{remainingTime.hours} <span>{GetText() ? "Jam" : "Hours"}</span></span> 
      //     )
      //   }
      //   <span>{remainingTime.minutes} <span>{GetText() ? "Menit" : "Minutes"}</span></span>
      //   <span>{remainingTime.seconds} <span>{GetText() ? "Detik" : "Seconds"}</span></span>
      // </p>
    );
  }
}