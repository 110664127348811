import React, { Component } from 'react';
// import Moment from 'moment';
import parse from 'html-react-parser';
import { Container, Tabs, Tab, Modal, Button, Form } from 'react-bootstrap';
import { FaRegCalendarDays, FaRegClock, FaLinkedin } from 'react-icons/fa6';
import { GetText, ReplaceText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import NumberLeadingZero from '../config/number-addzero';
import CourseBlock from '../inc/course/Block';
import Loading from '../inc/Loading';
// import ImgCertificate from '../img/certificate.png';
import '../config/global';

import Axios from 'axios';
import JsPdf from "jspdf";
import Html2Canvas from "html2canvas";
import CountDown from '../inc/course/CountDown';
import Certificate from "../Certificate";

const authKey = global.config.kindcode.training[2]+global.config.authBearerKey; // course
const authKey2 = global.config.kindcode.training[0]+global.config.authBearerKey; // user
const authKey3 = global.config.kindcode.training[4]+global.config.authBearerKey; // tutor

const authKey4 = global.config.kindcode.training[10]+global.config.authBearerKey; // add to cart
const authKey5 = global.config.kindcode.training[5]+global.config.authBearerKey; // feedback
const authKey6 = global.config.kindcode.training[9]+global.config.authBearerKey; // certificate
// const authKey7 = global.config.kindcode.training[12]+global.config.authBearerKey; // Add Purchase (Course)


const faqContentCourse = {
  "contentEn": [
    {"question":"What do the workshop include?","answer":"Each workshop is created, owned and managed by the instructor(s). The foundation of each workshop are its lectures, which can include videos, slides, and text. In addition, instructors can add resources and various types of practice activities, as a way to enhance the learning experience of students. ."},
    {"question":"How do I take a workshop?","answer":"Workshop are entirely on-demand. You can begin the workshop whenever you like, and there are no deadlines to complete it.<br/><br/>After you enroll in a workshop, you can access it by clicking on the workshop link you will receive in your confirmation email (provided you’re logged into your account). You can also begin the workshop by logging in and navigating to your My learning page."},
    {"question":"Do I have to start my workshop at a certain time? And how long do I have to complete it?","answer":"As noted above, there are no deadlines to begin or complete the workshop. Even after you complete the workshop you will continue to have access to it, provided that your account/'s in good standing, and we continues to have a license to the workshop."},
    {"question":"Where can I go for help?","answer":"If you find you have a question about a paid workshop while you/'re taking it, you can search for answers to your question in the Q&A or ask the instructor.<br/><br/>Our Help Center has extensive information regarding our various features, and articles with troubleshooting steps to help you quickly resolve any issues you encounter. Our Support Team is also happy to help."}
  ],
  "contentId": [
    {"question":"Apa saja yang termasuk dalam workshop tersebut?","answer":"Setiap workshop dibuat, dimiliki dan dikelola oleh instruktur. Landasan setiap workshop adalah ceramahnya, yang dapat mencakup video, slide, dan teks. Selain itu, instruktur dapat menambahkan sumber daya dan berbagai jenis kegiatan praktik, sebagai cara untuk meningkatkan pengalaman belajar siswa. ."},
    {"question":"Bagaimana cara saya mengambil workshop?","answer":"Workshop sepenuhnya berdasarkan permintaan. Anda dapat memulai workshop kapan pun Anda mau, dan tidak ada tenggat waktu untuk menyelesaikannya.<br/><br/>Setelah Anda mendaftar di workshop, Anda dapat mengaksesnya dengan mengeklik tautan workshop yang akan Anda terima di email konfirmasi Anda (asalkan Anda masuk ke akun Anda). Anda juga dapat memulai workshop dengan masuk dan membuka halaman Pembelajaran Saya."},
    {"question":"Apakah saya harus memulai workshop pada waktu tertentu? Dan berapa lama saya harus menyelesaikannya?","answer":"Seperti disebutkan di atas, tidak ada tenggat waktu untuk memulai atau menyelesaikan workshop. Bahkan setelah Anda menyelesaikan workshop, Anda akan terus memiliki akses ke workshop tersebut, asalkan akun Anda bereputasi baik, dan kami terus memiliki lisensi untuk workshop tersebut.<br/><br/>Pusat Bantuan kami memiliki informasi lengkap mengenai berbagai fitur kami, dan artikel berisi langkah-langkah pemecahan masalah untuk membantu Anda menyelesaikan masalah apa pun yang Anda temui dengan cepat. Tim Dukungan kami juga dengan senang hati membantu."},
    {"question":"Kemana saya bisa mencari bantuan?","answer":"Jika Anda memiliki pertanyaan tentang workshop berbayar saat Anda mengikutinya, Anda dapat mencari jawaban atas pertanyaan Anda di Tanya Jawab atau bertanya kepada instruktur."}
  ]
}
function getFeedbackList() {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/feedbacklist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey5
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json()
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      if(resultJson.values.length > 0) {
        for (var i=0; i<resultJson.values.length; i++) {
          var content = resultJson.values[i].content;
          content.id = resultJson.values[i].docid;
          resultData.push(content);
          if(i===(resultJson.values.length-1)) {
            resolve(resultData);
          }
        }
      }
    });
  });
}
function getCourseRelated(courseId,tags) {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","category":"'+tags+'","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json()
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      var inum=0;
      for (var i in resultJson.values) {
        var content = resultJson.values[i].content;
        content.id = resultJson.values[i].docid;
        if (content.id !== courseId) {
          resultData.push(content);
        }
        inum++;
        if(inum === resultJson.values.length) {
          resolve(resultData);
        }
      }
    });
  });
}
function getTutorInfo(tutors) {
  return new Promise((resolve) => {
    var tutorIds = tutors.split(',');
    var arrTutors = [];
    if (tutorIds.length > 0) {
      tutorIds.forEach((tutorId, i) => {
        fetch(global.config.urlLink+"/tutorview/" + tutorId, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey3
          }
        })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log('Something happened wrong');
          }
        })
        .then(resultJson => {
          var tutor = resultJson.values[0].content[0].maincontent;
          tutor.tutorBio = ReplaceText(tutor.tutorBio);
          arrTutors.push(tutor);
          if((i+1) === tutorIds.length) {
            resolve(arrTutors);
          }
        });
      });
    } else {
      resolve(arrTutors);
    }
  });
}
function getAgendaInfo(dataAgenda,courseDatez) {
  return new Promise((resolve) => {
    var agendas = dataAgenda;
    var contents = [];
    // var sTimez = "", eTimez = "";
      
    if (agendas.length > 0) {
      agendas.forEach((agenda, i) => {
        var content2 = agenda.subcontent;
        // var date = DateTimeFormats(courseDatez._seconds,"date-long");
        // var time = DateTimeFormats(content2.agendaTime._seconds,"time-24");
        // var last = DateTimeFormats((content2.agendaTime._seconds + (content2.agendaDuration * 60)),"time-24");
        content2.id = agenda.subdocid;
        contents.push(content2);
        // if (i === 0) {
        //   sTimez = Moment(date + ' ' + time).format('YYYYMMDD[T]HHmmss[Z]');
        // }
        // if (i === (agendas.length - 1)) {
        //   eTimez = Moment(date + ' ' + last).format('YYYYMMDD[T]HHmmss[Z]');
        // }
        if((i+1) === agendas.length) {
          var agendaSend = {};
          agendaSend.agendacontent = contents;
          // agendaSend.startTime = sTimez;
          // agendaSend.endTime = eTimez;
          resolve(agendaSend);
        }
      });
    } else {
      var agendaSend = {};
      agendaSend.agendacontent = contents;
      // agendaSend.startTime = sTimez;
      // agendaSend.endTime = eTimez;
      resolve(agendaSend);
    }
  });
}

function getUserOrder(courseId, type) {
  return new Promise((resolve) => {
    // fetch(global.config.urlLink+"/traininguserview/" + localStorage.getItem('doc_id'), {
    //   method: 'GET',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Authorization': authKey2
    //   }
    // })
    
    var dataSend = { docid: localStorage.getItem('doc_id') }
    fetch(global.config.urlLink+"/traininguserview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    }).then(resJson => {
      var avb_carts = false, avb_transaction={}, url_cert = "", historyCourseId="";

      // ########## type = "carts" ##########
      if(resJson.values[0].scontent5.length > 0) {
        var resultCart = resJson.values[0].scontent5.filter((list5) =>  {
          return list5.subcontent.courseId === courseId;
        });
        if(resultCart.length > 0) { avb_carts = true; }
      }
      // ########## type = "order history / transaksi" ##########
      if(resJson.values[0].scontent6.length > 0) {
        var historder = resJson.values[0].scontent6;
        for (var i in historder) {
          var historderItems = historder[i].sub2content;
          for (var j in historderItems) {
            if (historderItems[j].subsubcontent.itemId === courseId) {
              avb_transaction = {
                'orderId': historder[i].subcontent.orderId,
                'orderStatus': historder[i].subcontent.orderStatus,
                'paymentStatus': historder[i].subcontent.paymentStatus,
                'ticketId': historderItems[j].subsubcontent.ticketId,
                'ticketStatus': historderItems[j].subsubcontent.ticketStatus,
              };
            }
          }
        }
      }
      // ########## type = "certificate" ##########
      if(resJson.values[0].scontent4.length > 0) {
        var resultCert = resJson.values[0].scontent4.filter((list4) =>  {
          return list4.subcontent.courseId === courseId;
        });
        if(resultCert.length > 0) { url_cert = resultCert.subcontent.fileUrl; }
      }
      // ########## type = "course history" ##########
      if(resJson.values[0].scontent2.length > 0) {
        var histCourse = resJson.values[0].scontent2.filter((list2) =>  {
          return list2.subcontent.courseId === courseId;
        });
        if(histCourse.length > 0) { historyCourseId = histCourse[0].subdocid; }
      }
      resolve({
        status_cart : avb_carts,
        status_transaction :  avb_transaction,
        link_certificate : url_cert,
        history_course_id : historyCourseId
      })
      
    });
  });
}

async function getAllDatas(id,tutors,agendas,courseDatez,category) {
  const userorderresult = getUserOrder(id);
  const tutorsresult = getTutorInfo(tutors);
  const agendaresult = getAgendaInfo(agendas,courseDatez);
  const relatedresult = getCourseRelated(id,category);
  const feedbacklistresult = getFeedbackList();

  const [thing1, thing2, thing3, thing4, thing5] = await Promise.all([userorderresult,tutorsresult,agendaresult,relatedresult,feedbacklistresult]);
  var arrReturnValue = {};
  arrReturnValue.userorderdata = thing1;
  arrReturnValue.tutordata = thing2;
  arrReturnValue.agendadata = thing3;
  arrReturnValue.relateddata = thing4;
  arrReturnValue.feedbacklistdata = thing5;
  
  return arrReturnValue;
}

export default class CourseDetailMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      description: '',
      currency: '',
      price: 0,
      date: '',
      time: '',
      seconds: '',
      image: '',
      video: '',
      recording: '',
      tutor: [],
      tutor_name: '',
      agenda: [],
      purchase: {},
      purchase_id: '',
      purchase_status: '',
      purchase_confirmation: '',
      ticket: '',
      ticket_error: '',
      courseticketid: '',
      type: '',
      venue: '',
      venue_address: '',
      venue_maps: '',
      meet_link: '',
      meet_code: '',
      calendar_link: '',
      start_time: '',
      end_time: '',
      courseRelated: [],
      feedbackList: [],
      historyCourseId: '',
      // certificate: '',
      certificate_url: '',
      certificate_bg: '',
      formErrors: {ticket: ''},
      ticketValid: false,
      checked_in: false,
      filled_in: false,
      button_enable: false,
      button_expired: false,
      button_openreg: false,
      button_added: false,
      button_input: false,
      open_feedback: false,
      modal_show: false,
      modal_show_cart: false,
      faq: GetText() ? faqContentCourse.contentId : faqContentCourse.contentEn,
      qty: 1,
      isLoad: false
    }
    this.changeLanguage = this.changeLanguage.bind(this);
    // this.closeModal = this.closeModal.bind(this);
    // this.inputTicket = this.inputTicket.bind(this);
    this.checkTicket = this.checkTicket.bind(this);
    // this.openFeedback = this.openFeedback.bind(this);
    // this.closeFeedback = this.closeFeedback.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
		this.addCertificate = this.addCertificate.bind(this);
		this.uploadCertificate = this.uploadCertificate.bind(this);
  }

  openLanguagePage(contentLang) {
    var language = localStorage.getItem('language');
    if (language !== contentLang) {
      this.setState({
        modal_show: true
      });
    }
  }
  changeLanguage() {
    var currentLanguage = localStorage.getItem('language');
    var newLanguage = currentLanguage === "Indonesian" ? "English" : "Indonesian";
    localStorage.setItem('language', newLanguage);
    window.location.reload();
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }

  goToPage(link) {
    window.location.href= window.location.origin+link;
  }
  closeModal(name) {
    this.setState({
      [name]: false
    });
  }
  openFeedback() {
    this.setState({
      open_feedback: true
    });
  }

  closeFeedback() {
    this.setState({
      open_feedback: false
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let ticketValid = this.state.ticketValid;
    switch(fieldName) {
        case 'ticket':
            if(this.state.ticket === '') {
                ticketValid = value.trim().length > 0;
                fieldValidationErrors.ticket = ticketValid ? '' : GetText() ? 'Ticket perlu diisi' : 'Ticket need to be filled';
            } else {
                ticketValid = true;
                fieldValidationErrors.ticket = '';
            }
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
                    ticketValid: ticketValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.ticketValid});
  }

  uploadCertificate() {
		return new Promise((resolve, reject) => {
			const certificate = document.getElementById('certificate');
			certificate.style.display = "block";
			Html2Canvas(certificate, {logging: true, letterRendering: 1, useCORS: true}).then(canvas => {
				const data = canvas.toDataURL('img/png');
				certificate.style.display = "none";
				const pdf = new JsPdf({
					orientation: "landscape",
					unit: "px",
					hotfixes: "px_scaling",
					format: [900, 506]
				});
				pdf.addImage(data, 'PNG', 0, 0, 900, 506);			
				var blob = pdf.output('blob');
				var name = localStorage.getItem("doc_id") + this.state.id + '.pdf';
				const form = new FormData();
				form.append('file', blob, name);
				form.append('source', 'certificate');
				Axios.post(global.config.urlLink+ "/uploadcertificate",
					form, { 
						headers: { 
							'content-type': 'multipart/form-data',
							'Authorization': authKey6
						}
					}
				)
				.then(result => {
					if (result.data.values !== undefined) {
						resolve(result.data.values);
					} else {
						reject('Something happened wrong.');
					}
				});
			});
		});
	}
  addCertificate = (courseid,memberid,certificateurl,coursetitle) => {
    return new Promise((resolve, reject) => {
      var dataSend = {
        trmember_id: memberid,
        course_id: courseid,
        course_title: coursetitle,
        course_type: 'course',
        score: 100,
        file_url: certificateurl,
        form_id: 'form-addcertificate',
        historycourse_id: this.state.historyCourseId
      };
      fetch(global.config.urlLink+"/trainingaddcertificate", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey6
        }, 
        body : JSON.stringify(dataSend)
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong.');
        }
      }).then(resultJson2 => {
        if (resultJson2.status === "200") {
          resolve(resultJson2.values);
        } 
      });
    });
	}
  submitFeedback = (courseid,memberid,answers) => {
    return new Promise((resolve, reject) => {
      var data = {
        "member_id": memberid,
        "course_id": courseid,
        "feedback_answer": answers,
        "form_id": "form-addfeedback"
      };
      fetch(global.config.urlLink+"/coursefeedbackadd/", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey
        },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong.');
        }
      }).then(resultJson => {
        if (resultJson.status === "200") {
          resolve(resultJson.values);
        } 
      });
    });
  }
  async sendFeedback(event) {
    event.preventDefault();
    var formData = new FormData(event.currentTarget);
    var answers = [];
    var a=0;
    this.state.feedbackList
      .filter(a => a.feedbackStatus === 1)
      .sort((a, b) => a.feedbackNumber - b.feedbackNumber)
      .forEach((feedback, i) => {
        for (let [key, value] of formData.entries()) {
          if (key === 'feedback-' + feedback.feedbackNumber) {
            feedback.feedbackInput = key;
            feedback.feedbackAnswer = value;
            if(value !== "") { a++; }
          }
        }
        answers.push(feedback);
      });
    
    if(a>0) {
      var certificate = await this.uploadCertificate();
      if(certificate) {
        var [result1, result2] = await Promise.all([this.submitFeedback(this.state.id,localStorage.getItem('doc_id'),answers), this.addCertificate(this.state.id,localStorage.getItem('doc_id'),certificate,this.state.title)]);
        if(result1 && result2) {
          this.setState({
            open_feedback: false,
            filled_in: true,
            certificate_url: certificate,
          })
        }
      }


      
    } else {

    }
   
  }


  checkIn() {
    if(this.state.courseticketid !== "") {
        var dataText = '{"member_id": "' + localStorage.getItem('doc_id') + '","course_id": "' + this.state.id + '","ticket_id": "' + this.state.ticket + '","courseticket_id":"'+this.state.courseticketid+'","historycourse_id":"'+this.state.historyCourseId+'","form_id": "form-addcheckin"}';
        var dataSend = JSON.parse(dataText);
        fetch(global.config.urlLink+"/courseattendanceadd/", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey
          },
          body: JSON.stringify(dataSend)
        })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log('Something happened wrong.');
          }
        })
        .then(resultJson => {
          if (resultJson.status === "200") {
            if(this.state.type === 'Offline') {
              this.setState({
                checked_in: true
              })
            } else {
              window.open(this.state.meet_link, "_blank");
            }
          } 
        });
    } else {

    }
  }

  checkTicket(e) {
    e.preventDefault();
    if(this.state.ticket !== "" ) {
      var ticketCode = this.state.ticket;
      if(this.textInputTicket.value !== '') { ticketCode = this.textInputTicket.value; } 
      
      var dataText ='{"member_id":"'+ localStorage.getItem('doc_id') +'","course_id":"'+this.state.id+'","ticket_id":"'+ticketCode+'","form_id":"check-ticket"}';
      var dataSend = JSON.parse(dataText);
      fetch(global.config.urlLink+"/cekticket/", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey
        },
        body: JSON.stringify(dataSend)
      })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong.');
        }
      })
      .then(resultJson => {
        if (resultJson.values.length > 0) {
          this.setState({
            button_input: true,
            ticket_error: '',
            courseticketid: resultJson.values[0].docid
          })
        } else {
          this.setState({
            ticket_error: "Ticket doesn't match"
          })
        }
      });

    } else {
      var arrfieldName = ['ticket'];
      var arrvalue = [this.state.ticket];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }

  addToCart() {    
    var dataText ='{"member_id":"'+ localStorage.getItem('doc_id') +'","course_id":"'+ this.state.id +'","quantity":"'+ this.state.qty +'","form_id":"add-cart"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+"/addcart/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey4
      },
      body: JSON.stringify(dataSend)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    })
    .then(resultJson => {
      if(resultJson.status === "200") {
        if(resultJson.values.stat === 0) {
          var carts = document.getElementById('nav-cart').getElementsByTagName('span')[0];
          carts.classList.remove('empty');
          carts.innerHTML = carts.innerHTML > 0 ? (parseInt(carts.innerHTML) + 1) : 1;
        }
        this.setState({
          button_enable: false,
          modal_show_cart: true
        });
       
      } else {
        console.log('Something happened wrong.');
      }
    });
    
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);
    
    fetch(global.config.urlLink+"/courseview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var id = resultJson.values[0].docid;
      var content = resultJson.values[0].content[0].maincontent;
      if(content !== undefined) {
        this.openLanguagePage(content.courseLanguage);
        

        getAllDatas(id,content.tutorId,resultJson.values[0].scontent,content.courseDate,content.categoryName)
        .then((response) => {
          var isCheckin = false, isFilledin = false, isOpenRegister = false, isExpired = false;
          /* ################# */
          if(resultJson.values[0].scontent2.length > 0) {
            var resultCheckin = resultJson.values[0].scontent2.filter((checkin) =>  {
              return checkin.subcontent.checkInBy === localStorage.getItem('doc_id');
            });
            if(resultCheckin.length > 0) { isCheckin = true; }
          }
          /* ################# */
          if(resultJson.values[0].scontent3.length > 0) {
            var resultFilledin = resultJson.values[0].scontent3.filter((feedbacks) =>  {
              return feedbacks.subcontent.memberId === localStorage.getItem('doc_id');
            });
            if(resultFilledin.length > 0) { isFilledin = true; }
          }
          /* ################# */
          var dateNow = new Date();
          var courseStartTime = new Date((content.courseStartTime._seconds*1000));
          var courseEndTime = new Date((content.courseEndTime._seconds*1000));
          var mSecDiffStartTime = courseStartTime.valueOf() - dateNow.valueOf();
          var mSecDiffEndTime = courseEndTime.valueOf() - dateNow.valueOf();
          var diffHoursS = mSecDiffStartTime / (1000 * 60 * 60);
          var diffSecE = mSecDiffEndTime / 1000;

          if(diffSecE < 0) { isExpired = true; }
          if(diffHoursS < 1 && diffSecE > 0) { isOpenRegister = true; }
          /* ################# */ //'YYYYMMDD[T]HHmmss[Z]
          var calendarLink = '';
          
          /* ################# */
          var textSubject = content.courseTitle;
          var textDescription = "Welcome to Novaio Academy!\nYou are invited to attend our class with topic "+content.courseTitle+"\n\n"+ReplaceText(content.courseShortDesc)+"\n\nThank you for your partisipation";
          var periodes = courseStartTime.getUTCFullYear()+NumberLeadingZero((courseStartTime.getUTCMonth()+1),2)+NumberLeadingZero(courseStartTime.getUTCDate(),2)+'T'+NumberLeadingZero(courseStartTime.getUTCHours(),2)+NumberLeadingZero(courseStartTime.getUTCMinutes(),2)+NumberLeadingZero(courseStartTime.getUTCSeconds(),2)+'Z';
          periodes += '/';
          periodes += courseEndTime.getUTCFullYear()+NumberLeadingZero((courseEndTime.getUTCMonth()+1),2)+NumberLeadingZero(courseEndTime.getUTCDate(),2)+'T'+NumberLeadingZero(courseEndTime.getUTCHours(),2)+NumberLeadingZero(courseEndTime.getUTCMinutes(),2)+NumberLeadingZero(courseEndTime.getUTCSeconds(),2)+'Z';
          calendarLink += 'https://www.google.com/calendar/render?action=TEMPLATE';
          calendarLink += '&text='+encodeURIComponent(textSubject);
          calendarLink += '&details='+encodeURIComponent(textDescription);
          calendarLink += '&dates='+encodeURIComponent(periodes);
          // calendarLink += '&ctz='+encodeURIComponent("Asia/Jakarta");
          this.setState({
              id: id,
              title: content.courseTitle,
              category: content.categoryName,
              description: ReplaceText(content.courseLongDesc),
              currency: content.courseCurrency,
              price: NumberFormats(content.coursePrice),
              date: DateTimeFormats(content.courseDate._seconds,"date-long"),
              time: DateTimeFormats(content.courseStartTime._seconds,"time-24"),
              seconds: content.courseDate._seconds,
              image: content.coursePicture,
              video: content.courseVideo,
              recording: content.courseRecording,
              
              type: content.courseType,
              venue: ReplaceText(content.courseVenue),
              venue_address: ReplaceText(content.courseVenueAddress),
              venue_maps: content.courseVenueMaps? content.courseVenueMaps : '',
              meet_link: content.courseMeetLink,
              meet_code: content.courseMeetLink ? content.courseMeetLink.split("/") : '',
              calendar_link: calendarLink,
              excerpt: ReplaceText(content.courseShortDesc),
              link: '/course/' + id,
              
              start_time: courseStartTime,
              end_time: courseEndTime,
              // start_time: DateTimeFormats(content.courseStartTime._seconds,"time-24"), // response.agendadata.startTime,
              // end_time: DateTimeFormats(content.courseEndTime._seconds,"time-24"), // response.agendadata.endTime,
              certificate_bg: content.certificateBg,
              certificate_url: response.userorderdata.link_certificate,
              
              tutor: response.tutordata,
              tutor_name: content.tutorName,
              agenda: response.agendadata.agendacontent,
              courseRelated: response.relateddata,
              purchase: response.userorderdata.status_transaction,

              button_added: response.userorderdata.status_cart,
              button_expired: isExpired,
              checked_in: isCheckin,
              filled_in: isFilledin,
              button_openreg: isOpenRegister,
              feedbackList: response.feedbacklistdata,
              historyCourseId: response.userorderdata.history_course_id,
              isLoad: true
          });
          
        })

      } else {
        window.location.href="/error-204";
      }

    })

  }

  render() {    
    return (
      <div className="detail-course">
        <Modal animation={false} show={this.state.modal_show}>
          <Modal.Header>
            <Modal.Title>{GetText() ? "Konten tidak tersedia!" : "Content not found!"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{GetText() ? "Konten ini hanya tersedia dalam bahasa Inggris. Ubah bahasa atau kembali ke Beranda." : "This content is only available on Indonesian version. Change language or back to Home."}</Modal.Body>
          <Modal.Footer>
            <a className="btn btn-primary" href="#!" onClick={this.changeLanguage}>{GetText() ? "Ubah bahasa" : "Change language"}</a>
            <a className="btn btn-outline-primary" href="/">{GetText() ? "Kembali ke Beranda" : "Back to Home"}</a>
          </Modal.Footer>
        </Modal>
    { this.state.isLoad === true ?
      <>
        <section className="bg-dark">
        <div className="bg-dark" style={{marginTop: "-48px"}}>
        
          <Container>
            <div className="row py-5 mb-5">
              {(this.state.image !== "" && this.state.image !== undefined)?
              <div className="col-md-4 text-center">
                <img className="mxw mb-4 br-8" src={this.state.image} alt={this.state.title}/>
              </div>
              : <></>
              }
              <div className={`${
                (this.state.image !== "" && this.state.image !== undefined) ? "col-md-8 " : "col-md-12 "
                } mt-4 mt-md-0`}>
              
                <h4 className="mb-4 text-white">{this.state.title}</h4>
                <p className="text-white">{this.state.excerpt}</p>
                <div className="separator line"></div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="text-white">
                      <strong>{GetText() ? "Tanggal" : "Date"}</strong>
                      <br/>
                      <span className="me-2">
                        <FaRegCalendarDays className="me-1"/>
                        {this.state.date}
                      </span>
                      <span>
                        <FaRegClock className="me-1"/>
                        {this.state.time}
                      </span>
                    </p>
                    <p className="text-white mb-0">
                        <strong>{GetText() ? "Lokasi" : "Location"}</strong>
                        <br/>
                        {this.state.type === 'Offline' ? 
                          (
                            (this.state.purchase.orderId !== undefined && this.state.purchase.paymentStatus === global.config.paymentStatus[1])?
                              <div>
                                {parse(this.state.venue_address)}<br/>
                                {this.state.venue_maps !== "" && 
                                <a 
                                  className="btn btn-outline-light btn-calendar mt-3"
                                  // href={"https://www.google.com/maps/search/?api=1&query=" + this.state.venue_address}
                                  href={this.state.venue_maps}
                                  target="_blank" rel="noreferrer">
                                  {GetText() ? "Lihat di Maps" : "View in Maps"}
                                </a>
                                }
                              </div>
                            : this.state.venue
                            // : this.state.venue.split(",")[0]
                          )
                          : 'Online via Google Meet'
                        }
                    </p>
                    {/* <p className="text-white">
                      <strong>{GetText() ? "Harga" : "Price"}</strong>
                      <br/>
                      {this.state.currency === "IDR" ? "Rp " : "$"}
                      {this.state.price}
                    </p> */}
                  </div>
                  <div className="col-md-6">
                    <p className="text-white">
                      <strong>{GetText() ? "Kategori" : "Category"}</strong>
                      <br/>
                      {this.state.category}
                    </p>
                    <p className="text-white">
                      <strong>{GetText() ? "Pembicara" : "Trainer"}</strong>
                      <br/>                      
                      {this.state.tutor_name}
                    </p>
                  </div>
                </div>
                <div className="separator line"></div>
                <div className="row">
                  <div className="col-md-12">
                    {
                      (this.state.purchase.orderId !== undefined && (this.state.purchase.orderStatus === global.config.orderStatus[2] || this.state.purchase.orderStatus === global.config.orderStatus[1] || this.state.purchase.orderStatus === global.config.orderStatus[0])) ?
                        (this.state.purchase.orderStatus === global.config.orderStatus[2] && this.state.purchase.paymentStatus === global.config.paymentStatus[1]) ? 
                          this.state.button_expired === true ?
                            this.state.checked_in === true ?  // sudah checkin
                              this.state.filled_in === true ? // form feedback sudah diisi
                                <div>
                                  <a className="btn btn-primary" href={this.state.certificate_url} target="_blank" rel="noreferrer" download>{GetText() ? "Unduh Sertifikat" : "Download Certificate"}</a>
                                </div>
                              : 
                                <div>
                                  <p className="text-white">
                                    {GetText() ? "Terima kasih telah barpartisipasi dalam workshop ini." : "Thank you for participating in this workshop."}
                                    <br/>
                                    {GetText() ? "Silakan isi form feedback terlebih dahulu sebelum mengunduh sertifikat" : "Please fill out the feedback form before downloading the certificate."}
                                  </p>
                                  <Button variant="primary" className="mb-2" onClick={() => this.openFeedback()}>{GetText() ? "Form Feedback" : "Feedback Form"}</Button>
                                </div>
                            
                            :  // tidak checkin
                              <div>
                                <div className="d-inline-flex bg-secondary text-white p-2"><span className="font-weight">{GetText() ? "Workshop telah berakhir" : "The workshop has ended" }</span></div>
                                <div className="text-white mt-2">
                                  {GetText() ? "Anda tidak mendaftarkan kehadiran Anda di event ini" : "You did not register your attendance at this event."}
                                </div>
                              </div>
                          :  // event belum berakhir
                            (this.state.button_openreg === false)?
                            //(Moment.duration(Moment(this.state.start_time).diff(Moment())).asMinutes() > 2000) ? // is NOW() > 1 hour before event start ? 
                              <div>
                                <p className="text-white mb-0"><strong>{GetText() ? "Event akan dimulai dalam" : "The event will start in"}</strong></p>
                                <div className="mt-3"><CountDown value={this.state.start_time}/></div>
                                <div className="mt-5">
                                    <a 
                                      className="btn btn-outline-light btn-calendar"
                                      // href={"http://www.google.com/calendar/event?action=TEMPLATE&dates=" + this.state.start_time + "%2F" + this.state.end_time + "&text=" + encodeURIComponent(this.state.title) + "&details=Welcome%20to%20Novaio%20Academy!%0AYou%20are%20invited%20to%20attend%20our%20class%20" + encodeURIComponent(this.state.title) + "%20on%20" + encodeURIComponent(this.state.date) + ".%0A%0A" + encodeURIComponent(this.state.excerpt) + "%0A%0AAttend%20the%20class%20by%20opening%20this%20link%20" + encodeURIComponent(this.state.meet_link) + ".%0A%0AThank%20you."}
                                      href={this.state.calendar_link}
                                      target="_blank" rel="noreferrer">
                                      {GetText() ? "Tambah ke Calendar" : "Add to Calendar"}
                                    </a>
                                </div>
                              </div>
                            : // 1 jam sebelum event mulai - event belum berakhir
                              (this.state.button_input === false && this.state.button_expired === false  && this.state.checked_in === false) ? 
                                <div className="row">
                                  <div className="col-md-8">
                                    <div>
                                    <form id="form-checkticket" onSubmit={this.checkTicket}>
                                      <input className="form-control mb-2" placeholder="Input your Ticket ID" type="text" name="ticket" value={this.state.ticket} onChange={this.handleUserInput} ref={(input) => this.textInputTicket = input}/>
                                      {
                                        this.state.ticket_error && (
                                          <p className="text-danger mb-2">{GetText() ? 'Tiket tidak sesuai' : 'Ticket doesn\'t match'}</p>
                                        )
                                      }
                                      {
                                        this.state.formErrors.ticket !== "" && (
                                          <p className="text-danger mb-2">{this.state.formErrors.ticket}</p>
                                        )
                                      }
                                      
                                      {/* <Button className="btn btn-primary mb-2" onClick={this.checkTicket}>Check Ticket</Button> */}
                                      <Button type="submit" variant="primary">Check Ticket</Button>
                                    </form>
                                    </div>
                                    
                                  </div>
                                  <div className="col-md-4">
                                      <a 
                                        className="btn btn-outline-light btn-calendar"
                                        // href={"http://www.google.com/calendar/event?action=TEMPLATE&dates=" + this.state.start_time + "%2F" + this.state.end_time + "&text=" + encodeURIComponent(this.state.title) + "&details=Welcome%20to%20Novaio%20Academy!%0AYou%20are%20invited%20to%20attend%20our%20class%20" + encodeURIComponent(this.state.title) + "%20on%20" + encodeURIComponent(this.state.date) + ".%0A%0A" + encodeURIComponent(this.state.excerpt) + "%0A%0AAttend%20the%20class%20by%20opening%20this%20link%20" + encodeURIComponent(this.state.meet_link) + ".%0A%0AThank%20you."}
                                        href={this.state.calendar_link}
                                        target="_blank" rel="noreferrer">
                                        {GetText() ? "Tambah ke Calendar" : "Add to Calendar"}
                                      </a>
                                  </div>
                                  
                                </div>
                                
                                
                              :  // already input tiket code 
                                this.state.type === 'Online'?
                                  <div><Button variant="primary" className="mb-2" onClick={() => this.checkIn()}>Join via Google Meet</Button></div>
                                : 
                                  this.state.checked_in === true ?  // sudah checkin
                                    <div>
                                      <p className="text-white mb-0"><strong>{GetText() ? "Event akan berakhir dalam" : "The event will end in"}</strong></p>
                                      <div className="mt-3"><CountDown value={this.state.end_time}/></div>
                                    </div>
                                  : <div><Button variant="primary" className="mb-2" onClick={() => this.checkIn()}>Check-in to the event</Button></div>
                                

                        :  // belum melakukan pembayaran 
                          this.state.button_expired === true ?
                            <div className="d-inline-flex bg-secondary text-white p-2"><span className="font-weight">{GetText() ? "Workshop telah berakhir" : "The workshop has ended" }</span></div>
                          : 
                          <div className="row">
                            <div className="col-md-6">
                              <p className="text-white">
                                <strong>{GetText() ? "Status Pesanan" : "Order Status"}</strong>
                                <br/>
                                {this.state.purchase_confirmation ? "VERIFYING" : "UNPAID"}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <a className="btn btn-outline-light btn-calendar" href={"/invoice/course/" + this.state.purchase.orderId}>{GetText() ? "Lihat Pesanan" : "View Order"}</a>
                            </div>
                          </div>
                          
                          // “Status Pesanan : Waiting Payment”
                          //   <button> Make Payment </button> —> click go to : “Order Detail” page

                      : // Belum Ada Transaksi
                        this.state.button_expired === true?
                          <div className="d-inline-flex bg-secondary text-white p-2"><span className="font-weight">{GetText() ? "Workshop telah berakhir" : "The workshop has ended" }</span></div>
                        : 
                          this.state.button_added === true ?  // available in cart
                            <div>
                              <Button variant="primary" className="mb-2" disabled={true}>{GetText() ? "Sudah Dipesan" : "Ticket Booked"}</Button>
                              <Button variant="primary" className="ms-2 mb-2" onClick={() => this.goToPage('/shopping-cart')}>{GetText() ? "Lihat Keranjang Belanja" : "Go to Shopping Cart"}</Button>
                            </div>
                          // <button > Lihat Keranjang Balanja </button> —> click go to : “shopping cart” page
                          :   
                          <div> 
                            <div>
                              <div className="text-white me-4 d-inline-flex">
                                <div>{GetText() ? "Hanya dengan " : "Pay for "}
                                <span className="fs-1 ms-2 me-2">
                                {this.state.currency === global.config.currency.name[1] ? global.config.currency.symbol[1] : global.config.currency.symbol[0]} {this.state.price}
                                </span>
                                {GetText() ? " Anda dapat mengikuti workshop ini " : " and join the workshop "}</div>
                              </div>
                              <div className="mt-4">
                              <Button variant="primary" onClick={() => this.addToCart()} className="btn-lg mb-2">{GetText() ? "Pesan Sekarang" : "Book Now"}</Button>
                              </div>
                            </div>
                            <Modal animation={false} show={this.state.modal_show_cart} onHide={this.closeModal.bind(this,'modal_show_cart')} centered>                      
                                <Modal.Header closeButton>
                                  <Modal.Title>{GetText() ? "Keranjang Belanja" : "Shopping Cart"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{GetText() ? "Berhasil ditambahkan ke Keranjang Belanja!" : "Successfully added to Shopping Cart!"}</Modal.Body>

                                <Modal.Footer>
                                  <Button variant="primary" onClick={() => this.goToPage('/shopping-cart')}>{GetText() ? "Lihat Keranjang Belanja" : "Go to Shopping Cart"}</Button>
                                  {/* <a className="btn btn-primary" href="/shopping-cart">{GetText() ? "Lihat Keranjang Belanja" : "Go to Shopping Cart"}</a> */}
                                </Modal.Footer>
                            </Modal>
                          </div>

                    }
                  </div>
                  
                </div>

              </div>
            </div>
          </Container>
        </div>
        </section>
      
        {/* =========== CONTENT DETAIL ================== */}
        <section id="content-detail">
          <Container>
            <div className="row m-0">
              <div className="col-md-6">
                <div className="bg-light p-3 br-8">
                  <h4 className="text-start mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                  {
                    (this.state.video !== undefined && this.state.video !== "") ? (
                      <div className="embed-wrapper mb-4">
                        <div className="embed-container">
                          <video className="embed-content" name="video_intro" autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
                            <source src={this.state.video} type="video/mp4"></source>
                          </video>
                        </div>
                      </div>
                    ) : ''
                  }
                  <p>{parse(this.state.description)}</p>
                </div>
              </div>
              <div className="col-md-6 p-4">
                <Tabs className=" nav-scroll" defaultActiveKey="agenda" id="tab">
                  <Tab className="pt-4" eventKey="agenda" title="Agenda">
                    {
                      this.state.agenda.length > 0 ? (
                        this.state.agenda
                        .sort((a,b) => a.agendaTime._seconds - b.agendaTime._seconds)
                        .map((agenda, i) => {
                          return (
                            <div className="row" key={"agenda-" + i}>
                              <div className="col-md-3">
                                <label className="label-rounded-2">{DateTimeFormats(agenda.agendaTime._seconds,"time-24")}</label>
                              </div>
                              <div className="col-md-9 text-start">
                                <h5>{agenda.agendaTitle}</h5>
                                <p>{ReplaceText(agenda.agendaDesc)}</p>
                              </div>
                            </div>
                          )
                        })
                      ) : <div className="text-center">{GetText() ? 'tidak ada agenda saat ini' : 'no agenda for now' }</div> 
                    }
                  </Tab>
                  <Tab className="pt-4" eventKey="tutor" title="Trainer">
                    { 
                      this.state.tutor.length > 0 ? (
                        this.state.tutor.map((tutor, i) => {
                          return(
                            <div className="row mb-4" key={'tutor-' + i}>
                              <div className="col-md-3 mb-4 mb-md-0">
                                <img className="wh-120" src={tutor.tutorPicture} alt={tutor.tutorName}/>
                              </div>
                              <div className="col-md-9 text-start">
                                <h5>{tutor.tutorName}</h5>
                                <p>{(tutor.tutorBio !== "" && tutor.tutorBio !== undefined)? parse(tutor.tutorBio):""}</p>
                                <div>
                                  {tutor.tutorLinkedin.trim() ? <a href={tutor.tutorLinkedin} className="btn btn-dark btn-icon-circle me-4" target="_blank" rel="noreferrer"><FaLinkedin/></a> : ""}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : <div className="text-center">{GetText() ? 'tidak ada pembicara saat ini' : 'no trainer for now' }</div>  
                    }
                  </Tab>
                  {/* <Tab className="pt-4" eventKey="certificate" title="Certificate">
                    <img className="w-100 mb-4" src={ImgCertificate} alt="Certificate"/>
                    <p>Professional Certificate programs are series of courses designed by industry leaders and top universities to build and enhance critical professional skills needed to succeed in today's most in-demand fields. Find the program that meets your specific needs. Stand out and succeed at work.</p>
                  </Tab> */}
                  <Tab className="pt-4" eventKey="faq" title="FAQ">
                    <div className="accordion" id="accordionTab">
                    { 
                      this.state.faq.map((faqContents, i) => {
                        return(
                          <div className="accordion-item">
                            <h2 className="accordion-header" id={"heading-" + i}>
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + i} aria-expanded="false" aria-controls={"collapse-" + i}>
                                <span className="me-1 lh-base">{parse(faqContents.question)}</span>
                              </button>
                            </h2>
                            <div id={"collapse-" + i} className="accordion-collapse collapse" aria-labelledby={"heading-" + i} data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                              {parse(faqContents.answer)}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                    </div>
                    
                  </Tab>
                </Tabs>  
              </div>
              {
                this.state.courseRelated.length > 0 ? (
                  <div className="col-md-12 mt-5">                      
                    <div className="bg-light p-4 br-8">
                      <h4 className="text-start mb-4">{GetText() ? "Workshops Terkait" : "Related Workshops"}</h4>
                      <div className="row">
                        {
                          this.state.courseRelated
                          .sort((a,b) => b.courseDate._seconds - a.courseDate._seconds)
                          .slice(0, 4)
                          .map((related, i) => {
                            return (
                              <div className="col-md-3" key={"block-" + i}>
                                <CourseBlock value={related} key={"related-" + i}/>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                ) : ''
              }
            </div>
          </Container>
        </section>
        <Modal className="feedback" size="lg" show={this.state.open_feedback} onHide={() => this.closeFeedback()}>
          <Modal.Body className="p-4">
            <h5 className="mb-3">{GetText() ? 'Form Feedback' : 'Feedback Form'}</h5>
            <Form onSubmit={this.sendFeedback}>
              {
                this.state.feedbackList.length !== 0 ? (
                  this.state.feedbackList
                  .filter(a => a.feedbackStatus === 1)
                  .sort((a, b) => a.feedbackNumber - b.feedbackNumber)
                  .map((feedback, i) => {
                    return (
                      <div className="mb-3" key={"feedback-" + i}>
                        <p className="mb-2">{feedback.feedbackText}</p>
                        {
                          feedback.feedbackType === "Multiple Choice" ? (
                            <div>
                              { 
                                feedback.option1 ? (
                                  <div className="form-check">
                                    <label>
                                      <input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option1} required />
                                      {feedback.option1}
                                    </label>
                                  </div>
                                ) : ''
                              }
                              { 
                                feedback.option2 ? (
                                  <div className="form-check">
                                    <label>
                                      <input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option2} required />
                                      {feedback.option2}
                                    </label>
                                  </div>
                                ) : ''
                              }
                              { 
                                feedback.option3 ? (
                                  <div className="form-check">
                                    <label>
                                      <input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option3} required />
                                      {feedback.option3}
                                    </label>
                                  </div>
                                ) : ''
                              }
                              { 
                                feedback.option4 ? (
                                  <div className="form-check">
                                    <label>
                                      <input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option4} required />
                                      {feedback.option4}
                                    </label>
                                  </div>
                                ) : ''
                              }
                              { 
                                feedback.option5 ? (
                                  <div className="form-check">
                                    <label>
                                      <input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option5} required />
                                      {feedback.option5}
                                    </label>
                                  </div>
                                ) : ''
                              }
                            </div>
                          ) : (
                            <div>
                              <textarea className="form-control" name={"feedback-" + feedback.feedbackNumber} rows="3" required></textarea>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                ) : ''
              }
              <div className="mt-4">
                <Button variant="primary" type="submit" value="Save" id="submit">{GetText() ? "Kirim" : "Submit"}</Button>
                <Button variant="outline-primary" className="ms-2" onClick={() => this.closeFeedback()}>
                  {GetText() ? 'Batal' : 'Cancel'}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <div className="text-center mx-auto">
          <Certificate title={this.state.title} name={this.state.member_name} type="course" background={this.state.certificate_bg}/>
        </div>
      </>
      : <Loading value={{id:'course-area', type: 'screen'}}/> 
      }
      </div>
    );
  }
}