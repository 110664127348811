import React from "react";
import { FaXmark } from "react-icons/fa6";
// import CloseIcon from '@mui/icons-material/Close';

const Popup = props => {
  return (
    <div className="popup-box">
      <div className="box" style={{width:props.styles}}>
        <div className="d-flex flex-row border-bottom" >
        {/* <div className="header-pop"> */}
          <div className="col-md-10"><h4>{props.title}</h4></div>
          <div className="col-md-2 d-flex justify-content-end"><FaXmark className="close-icon cursor-on" style={{fontSize:"22px"}} onClick={props.handleClose} /></div>
        </div>
        <div>{props.content} </div>
        <div className="d-none" id="contentid">{props.id}</div>
        <div className="d-none" id="contentoth">{props.others}</div>
      </div>
    </div>
  );
};
 
export default Popup;