import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import MenuLeftCMS from './ct-fragmentsidemenu';
import CTUC from './ct-fragmentuc';

export default class CTDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none"
    }
  }
  componentDidMount() {
    var url = window.location.href;
    var tabKey = url.substring(url.lastIndexOf('/') + 1);
    this.setState({
      activelink: tabKey
    });
  }

  render() {
    return (
      <div className="setting">
       <Container>
          <div className="row py-5 mb-5">
            {/* === start - body */}
              <div className="col-md-3 bg-light">
                { 
                  this.state.activelink !== "none" && 
                    <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                
                }
              </div>
              <div className="col-md-9 p-4">
                <CTUC/>
              </div>
            {/* === end - body */}
          </div>
        </Container>
      </div>
    );
  }
}
