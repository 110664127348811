import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Moment from 'moment';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa6';
import { GetText } from '../config/helper';
import '../config/global';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer p-5 mt-5 bg-dark text-white">
        <Container>
          <div className="row text-start">
            <div className="col-md-8">
              <img className="mt-3 mb-4" src={process.env.PUBLIC_URL + "/img/logo-novaio-white.png"} width="120" alt="Novaio Academy"/>
              <p className="mb-md-4">
                {GetText() ? "Belajar itu seperti lari marathon, siapa yang memulai lebih cepat dan konsisten setiap harinya akan mencapai impiannya terlebih dahulu. Mari belajar bersama hanya di " : "Learning is like running a marathon, someone who start first and are consistent every day will achieve their dreams first. Let's learn together only on "}
                <a href="/" className="text-white"><strong>NOVAIO ACADEMY</strong></a>.
              </p>
            </div>
            <div className="col-md-4">
            { (localStorage.getItem("logged_in") === global.config.randomLoginKey)? 
              ( 
                <div className="row">
                  <div className="col-6">
                    <h5 className="my-4">{GetText() ? "Navigasi" : "Navigation"}</h5>
                    <ul className="list-unstyled">
                      <li key="menu-11"><a className="text-white" href="/account">{GetText() ? "Akun" : "Account"}</a></li>
                      <li key="menu-12"><a className="text-white" href="/account/subscription">{GetText() ? "Berlangganan" : "Subscription"}</a></li>
                      <li key="menu-13"><a className="text-white" href="/account/mycertificates">{GetText() ? "Sertifikat" : "Certificate"}</a></li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <h5 className="my-4">{GetText() ? "Tentang" : "About"}</h5>
                    <ul className="list-unstyled">
                      <li key="menu-21"><a className="text-white" href="/about">{GetText() ? "Tentang Kami" : "About Us"}</a></li>
                      <li key="menu-34"><a className="text-white" href="/help">{GetText() ? "Bantuan" : "Help"}</a></li>
                      <li key="menu-24"><a className="text-white" href="/privacy-policy">{GetText() ? "Kebijakan Privasi" : "Privacy Policy"}</a></li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6">
                    <h5 className="my-4">{GetText() ? "Navigasi" : "Navigation"}</h5>
                    <ul className="list-unstyled">
                      <li key="menu-11"><a className="text-white" href="/login">{GetText() ? "Daftar" : "Register"}</a></li>
                      <li key="menu-12"><a className="text-white" href="/login">{GetText() ? "Masuk" : "Login"}</a></li>
                      <li key="menu-13"><a className="text-white" href="/login">{GetText() ? "Keanggotaan" : "Membership"}</a></li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <h5 className="my-4">{GetText() ? "Tentang" : "About"}</h5>
                    <ul className="list-unstyled">
                      <li key="menu-21"><a className="text-white" href="/about">{GetText() ? "Tentang Kami" : "About Us"}</a></li>
                      <li key="menu-34"><a className="text-white" href="/help">{GetText() ? "Bantuan" : "Help"}</a></li>
                      <li key="menu-24"><a className="text-white" href="/privacy-policy">{GetText() ? "Kebijakan Pribadi" : "Privacy Policy"}</a></li>
                    </ul>
                  </div>
                </div>
              )
            }
              
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md d-flex align-items-center mb-2 mb-md-0">
              &copy; {Moment().year()} &nbsp; <strong>Novaio Academy</strong>
            </div>
            <div className="col-auto">
              <a href="#/" className="btn btn-social btn-icon-circle bg-white me-4" target="_blank" rel="noreferrer"><FaFacebook/></a>
              <a href="#/" className="btn btn-social btn-icon-circle bg-white me-4" target="_blank" rel="noreferrer"><FaTwitter/></a>
              <a href="#/" className="btn btn-social btn-icon-circle bg-white me-4" target="_blank" rel="noreferrer"><FaInstagram/></a>
            </div>
          </div>
          </Container>
      </div>
    );
  }
}