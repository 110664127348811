import React, { Component } from 'react';
// import Moment from 'moment';
import Axios from 'axios';
import parse from 'html-react-parser';
import { Container, Button, Modal } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa6';
import { GetText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import '../config/global';
import InstructionPayments from '../inc/InstructionPay';
import InvoiceContent from '../inc/InvoicePrint';
import Loading from '../inc/Loading';
import Error204 from '../Error204';


const authKey = global.config.kindcode.training[15]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[11]+global.config.authBearerKey;

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      name: '',
      email: '',
      sumorder_id: '',
      order_id: '',
      order_docid: '',
      order_date: '',
      amount: '',
      order_status: '',
      payment_status: '',
      payment_status_msg: ["",""],
      payment_method: '',
      payment_currency: '',
      payment_currency_symbol: '',
      payment_account: '',
      payment_date: '',
      payment_info: '',
      payment_bank: '',
      confirmation_date: '',
      confirmation_remarks: '',
      confirmation_photo: null,
      input_date: '',
      input_remarks: '',
      input_photo: null,
      payment_voucher_code: '',
      payment_voucher_amount: 0,
      grand_total: 0,
      sub_total: 0,
      
      item_list: [],

      modal_show: false,
      form_error: [],
      invoice_content: '',
      instruction_content: {},
      isLoad: false
    };
    this.inputDate = this.inputDate.bind(this);
    this.inputRemarks = this.inputRemarks.bind(this);
    this.inputPhoto = this.inputPhoto.bind(this);
    this.submitConfirm = this.submitConfirm.bind(this);
    this.confirm = this.confirm.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    
  }

  openModal(e) {
    e.preventDefault();
    this.setState({
      modal_show: true
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({
      modal_show: false
    });
  }

  inputDate(event) {
    this.setState({ 
      input_date: event.target.value,
      form_error: [] 
    });
  }

  inputRemarks(event) {
    this.setState({ 
      input_remarks: event.target.value,
      form_error: [] 
    });
  }

  inputPhoto(event) {
    if (event.target.files[0] !== null) {
      var value = event.target.files[0];
      if (value.type.indexOf('image') > -1) {
        this.setState({
          input_photo: value,
          form_error: [] 
        }, () => {
          document.getElementById('input-photo').innerText = this.state.input_photo.name;
        });
      }
    }
  }
  
  cancelOrder(e) {
    e.preventDefault();
    document.getElementById('cancel').innerText = 'Loading...';
    document.getElementById('cancel').setAttribute('disabled', 'true');
    
    var dataText = '{"memberdoc_id":"' + this.state.memberId + '","order_docid":"' + this.state.order_docid + '","sumorder_id":"' + this.state.sumorder_id + '","form_id": "form-cancel-order"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+ "/trainingupdateorder", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(result => {
      if(result.status === 200) return result.json() 
    }).then(resJson => {
      window.location.reload();
    });
  }

  submitConfirm(photo) {
    
    var dataText = '{"memberdoc_id":"' + this.state.memberId + '","order_docid":"' + this.state.order_docid + '","sumorder_id":"' + this.state.sumorder_id + '","confirmation_date":"' + this.state.input_date + '","confirmation_remarks":"' + this.state.input_remarks + '","confirmation_photo":"' + photo + '","form_id": "form-confirm-payment"}';
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+ "/trainingupdateorder", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    }).then(resultJson => {
      window.scrollTo(0, 0);
      window.location.reload();
    });
    
  }
  confirm(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.input_date)) {
      error.push('input_date');
    }
    if (!(this.state.input_remarks)) {
      error.push('input_remarks');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Send';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
      var dataPhoto = "";
      if(this.state.input_photo !== null && this.state.input_photo !== "") {
        const data = new FormData();
        data.append('file', this.state.input_photo);
        data.append('source', 'confirmation');
        Axios.post(global.config.urlLink+ "/uploadupdateorder", 
          data, { 
            headers: { 
              'content-type': 'multipart/form-data',
              'Authorization': authKey2
            }
          }
        )
        .then((result) => {
          var dataPhoto = result.data.values;
          this.submitConfirm(dataPhoto);
        });

      } else {
        this.submitConfirm(dataPhoto);
      }

    }
    
    // const data = new FormData();
    // data.append('file', this.state.input_photo);
    // data.append('source', 'confirmation');
    // Axios.post(global.config.urlLink+ "/uploadconfirmpurchase", 
    //   data, { 
    //     headers: { 
    //       'content-type': 'multipart/form-data',
    //       'Authorization': authKey2
    //     }
    //   }
    // )
    // .then((result) => {
    //   var photo = result.data.values;
      
    // });
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);
    
    var dataSend = { docid: id, types: 'orders', form_id: 'detail-order-col' }
    fetch(global.config.urlLink+"/trainingorderview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log("Something happened wrong.");
      }
    })
    .then(resultJson => {
      if(resultJson.status === "200" && resultJson.values.length > 0) {
        var sumorderid = resultJson.values[0].sumorderid;
        var member = resultJson.values[0].content[0].maincontent;
        var order = resultJson.values[0].scontent6[0];

        var paystatus_msg = ["",""];
        if(order.subcontent.orderStatus === global.config.orderStatus[3]) { paystatus_msg = ["DIBATALKAN","CANCELED"]; }
        else if(order.subcontent.orderStatus === global.config.orderStatus[4]) { paystatus_msg = ["EXPIRED","EXPIRED"]; }
        else if(order.subcontent.orderStatus === global.config.orderStatus[2]) { paystatus_msg = ["LUNAS","PAID"]; }
        else if(order.subcontent.orderStatus === global.config.orderStatus[1]) { paystatus_msg = ["MEMVERIFIKASI","VERIFYING"]; }
        else {
          if(order.subcontent.paymentConfirmationDate) { paystatus_msg = ["MEMVERIFIKASI","VERIFYING"]; } 
          else { paystatus_msg = ["MENUNGGU PEMBAYARAN", "PENDING PAYMENT"]; }
        }
        var invoiceContent = {
          order_fullname: member.memberName,
          order_email: member.memberEmail,
          order_id: order.subcontent.orderId,
			    order_date: DateTimeFormats(order.subcontent.orderDate._seconds,"date-long"),
          order_status: order.subcontent.orderStatus,
          order_items: order.sub2content,
          order_subtotal: order.subcontent.orderSubtotal,
          order_grandtotal: order.subcontent.orderGrandTotal,
          order_voucheramount: order.subcontent.voucherAmount,
          payment_currency: order.subcontent.paymentCurrency,
          payment_method: order.subcontent.paymentMethod,
          payment_bank: order.subcontent.paymentBank,
          payment_account: order.subcontent.paymentAccount,
          payment_date: order.subcontent.paymentVerificationDate ? DateTimeFormats(order.subcontent.paymentVerificationDate._seconds,"date-long") : '',
          confirmation_date: order.subcontent.paymentConfirmationDate ? DateTimeFormats(order.subcontent.paymentConfirmationDate._seconds,"date-long") : '',
        };
        var instructionContent = {
          payment_method: order.subcontent.paymentMethod,
          payment_currency: order.subcontent.paymentCurrency,
          payment_amount: order.subcontent.orderGrandTotal,
          payment_account: order.subcontent.paymentAccount,
          payment_bank: order.subcontent.paymentBank
        };
        
        this.setState({
          memberId: resultJson.values[0].docid,
          name: member.memberName,
          email: member.memberEmail,
          sumorder_id: sumorderid,
          order_id: order.subcontent.orderId,
          order_docid: order.subdocid,
          order_date: DateTimeFormats(order.subcontent.orderDate._seconds,"date-long"), //Moment.unix(order.subcontent.orderDate._seconds).format("D MMMM Y"),
          amount: order.subcontent.paymentAmount,
          order_status: order.subcontent.orderStatus,
          payment_status: order.subcontent.paymentStatus,
          payment_status_msg: paystatus_msg,
          payment_method: order.subcontent.paymentMethod,
          payment_currency: order.subcontent.paymentCurrency,
          payment_currency_symbol: order.subcontent.paymentCurrency === global.config.currency.name[1]? global.config.currency.symbol[1] : global.config.currency.symbol[0],
          payment_account: order.subcontent.paymentAccount,
          payment_date: order.subcontent.paymentVerificationDate ? DateTimeFormats(order.subcontent.paymentVerificationDate._seconds,"date-long") : '',
          payment_info: order.subcontent.paymentInfo,
          payment_bank: order.subcontent.paymentBank,
          confirmation_date: order.subcontent.paymentConfirmationDate ? DateTimeFormats(order.subcontent.paymentConfirmationDate._seconds,"date-long") : '',
          confirmation_remarks: order.subcontent.paymentConfirmationRemarks ? order.subcontent.paymentConfirmationRemarks : '',
          confirmation_photo: order.subcontent.paymentConfirmationPhoto ? order.subcontent.paymentConfirmationPhoto : '',
          payment_voucher_code: order.subcontent.voucherCode,
          payment_voucher_amount: order.subcontent.voucherAmount,
          grand_total: order.subcontent.orderGrandTotal,
          sub_total: order.subcontent.orderSubtotal,
          
          item_list: order.sub2content,
          modal_show: false,
          form_error: [],
          invoice_content : invoiceContent,
          instruction_content: instructionContent,
          isLoad: true
        });
      } else {
        document.getElementById("order-content").style.display = "none";
        document.getElementById("no-content").style.display = "block";
        // window.location.href="/orders";
      }

    });
  
  }
  render() {
    return (
    <div >
      <div id="no-content" style={{display:"none"}}><Error204 /></div>
    {this.state.isLoad === true? 
    <>
      <div className="visible-print">
        {this.state.invoice_content !== "" && 
          <InvoiceContent value={this.state.invoice_content}/>
        }
      </div>
      <div className="invoice-course hidden-print" id="order-content">
        <Container>
          
          <div className="row py-5">
            <div className="col-md-7">
              
              <div className="card card-pop">
                <div className="card-body p-4">
                  <div className="header">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-1 mb-0">Invoice</h4>
                      </div>
                      <div className="col-6 text-end">
                        <button onClick={() => window.print()} className="btn btn-sm btn-secondary"><FaPrint/> Print</button>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Tanggal Pesanan" : "Order Date"}</strong></div>
                    <div className="col-6 col-md-8 text-end text-md-start">{this.state.order_date}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Nomor Invoice" : "Invoice No"}</strong></div>
                    <div className="col-6 col-md-8 text-end text-md-start">{this.state.order_id}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Status Pemesanan" : "Order Status"}</strong></div>
                    <div className="col-6 col-md-8 text-end text-md-start">{GetText() ? this.state.payment_status_msg[0] : this.state.payment_status_msg[1]}</div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-md-12">
                      <strong>{GetText() ? "Tagihan Kepada" : "Invoiced To"}</strong>
                      <address>{this.state.name}<br/>{this.state.email}</address>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="card-header">
                        <tr>
                          <td className="col-3 border-0"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></td>
                          <td className="col-1 text-center border-0"><strong>{GetText() ? "Jumlah" : "Qty"}</strong></td>
                          <td className="col-2 text-end border-0"><strong>{GetText() ? "Harga" : "Amount"}</strong></td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.item_list.map((item, c) =>{
                            var title = ["",""];
                            (item.subsubcontent.itemType === global.config.orderItemType[0]) ?
                              title = [
                                'Bergabung di Workshop dengan topik "'+item.subsubcontent.itemDescription.courseTitle+'"',
                                'Join workshop with topic "'+item.subsubcontent.itemDescription.courseTitle+'"'
                              ]
                            : (item.subsubcontent.itemDescription.subscriptionType === global.config.subscribePlan[0].type)?
                              title = [
                                'Satu Bulan Langganan Keanggotaan',
                                'One Month Membership Subscription',
                              ]
                              : title = [
                                'Satu Tahun Langganan Keanggotaan',
                                'One Year Membership Subscription',
                              ]

                            return (
                              <tr key={c}>
                                <td className="col-3 border-0">
                                  {
                                    GetText() ? title[0] : title[1]
                                  }
                                </td>
                                <td className="col-1 text-center border-0">{item.subsubcontent.quantity}</td>
                                <td className="col-2 text-end border-0">{(item.subsubcontent.itemCurrency === global.config.currency.name[1])? global.config.currency.symbol[1] : global.config.currency.symbol[0] + NumberFormats(item.subsubcontent.itemPrice)}</td>
                              </tr>
                            )
                          })
                        }
                        {
                          // this.state.item_list.map((course, c) =>{
                          //   return (
                          //     <tr key={c}>
                          //       <td className="col-3 border-0">
                          //         {(course.subsubcontent.courseTitle)? GetText() ? 'Bergabung di Workshop dengan topik ' : 'Join workshop with topic' : ''}
                          //          "{course.subsubcontent.courseTitle}"
                          //       </td>
                          //       <td className="col-1 text-center border-0">{course.subsubcontent.quantity}</td>
                          //       <td className="col-2 text-end border-0">{(course.subsubcontent.courseCurrency === global.config.currency.name[1])? global.config.currency.symbol[1] : global.config.currency.symbol[0] + NumberFormats(course.subsubcontent.coursePrice)}</td>
                          //     </tr>
                          //   )
                          // })
                        }
                        {(this.state.sub_total !== this.state.grand_total)? 
                        <>
                        <tr key="-1">
                          <td className="text-end border-bottom-0 border-end-0 border-start-0 border-1"><strong>Subtotal</strong></td>
                          <td className="text-end border-bottom-0 border-end-0 border-start-0 border-1"><strong>{NumberFormats(this.state.sub_total)}</strong></td>
                        </tr>
                        <tr key="-2">
                          <td className="text-end border-0"><strong>Voucher</strong></td>
                          <td className="text-end border-0"><strong>{NumberFormats(this.state.voucher_amount)}</strong></td>
                        </tr>
                        </>
                        : <></>
                        }
                        
                      </tbody>
                      <tfoot className="card-footer">
                        <tr>
                          <td colSpan="2" className="text-end"><strong>Total</strong></td>
                          <td className="text-end"><strong>{this.state.payment_currency_symbol + NumberFormats(this.state.grand_total)}</strong></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {
                    // this.state.confirmation_date ? <></> : (
                    (this.state.order_status === global.config.orderStatus[0] && 
                      <div className="mt-5 mb-4">
                        {Object.keys(this.state.instruction_content).length > 0 && <InstructionPayments value={{data:this.state.instruction_content}}/> }

                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-4 mt-md-0">
              <div className="bg-dark p-4">
                  <div>
                    {
                      (this.state.order_status === global.config.orderStatus[4])?  // Expired
                        <h4 className="text-white"><strong>{GetText() ? "Status Pemesanan" : "Order Status"}</strong></h4>
                      : (this.state.order_status === global.config.orderStatus[3])? // Canceled
                        <h4 className="text-white"><strong>{GetText() ? "Status Pemesanan" : "Order Status"}</strong></h4>
                      : (this.state.order_status === global.config.orderStatus[2])?  // Verified
                        <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                      : ((this.state.order_status === global.config.orderStatus[0] || this.state.order_status === global.config.orderStatus[1]) && this.state.confirmation_date !== "" && this.state.confirmation_date !== undefined)? // Pending Payment
                        <h4 className="text-white"><strong>{GetText() ? "Konfirmasi Pembayaran" : "Payment Confirmation"}</strong></h4>
                      : <>
                        <h4 className="text-white"><strong>{GetText() ? "Konfirmasi Pembayaran" : "Payment Confirmation"}</strong></h4>
                        <p className="my-4 text-white">{GetText() ? "Harap melakukan pembayaran ke nomor rekening di bawah ini." : "Please make your payment to this account below."}</p>
                      </>
                    }
                      <div className="row mt-4">
                        <div className="col-6"><p className="text-white">Status</p></div>
                        <div className="col-6"><p className="text-white"><strong>{GetText() ? this.state.payment_status_msg[0] : this.state.payment_status_msg[1]}</strong></p></div>
                      </div>
                    {
                      (this.state.payment_date !== "" && this.state.payment_date !== undefined)? 
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Tanggal Bayar" : "Payment Date"}</p></div>
                        <div className="col-6"><p className="text-white"><strong>{this.state.payment_date}</strong></p></div>
                      </div>
                      : ''
                    }
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</p></div>
                        <div className="col-6"><p className="text-white"><strong>{this.state.payment_method} {this.state.payment_method === global.config.paymentMethod.IDN[2].value ? this.state.payment_bank : '' }</strong></p></div>
                      </div>
                      {
                        (this.state.payment_method === global.config.paymentMethod.IDN[2].value || this.state.payment_method === global.config.paymentMethod.IDN[1].value) ? (
                          <div className="row">
                            <div className="col-6 text-start"></div>
                            <div className="col-6 text-start"><p className="text-white">{parse(this.state.payment_info)}</p></div>
                            {/* <div className="col-6"><p className="text-white">{GetText() ? "Bayar Ke" : "Pay To"}</p></div> */}
                            {/* <div className="col-6 text-end"><p className="text-white"><strong>{this.state.payment_account}</strong></p></div> */}
                          </div>
                        ) : ''
                      }
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Total Harga" : "Total Amount"}</p></div>
                        <div className="col-6"><p className="text-white"><strong>{this.state.payment_currency_symbol + NumberFormats(this.state.grand_total)}</strong></p></div>
                      </div>
                    {
                      (this.state.order_status === global.config.orderStatus[2])? 
                      <div>
                        <p className="my-4 text-white">{GetText() ? "Terima kasih, pembayaran Anda telah kami terima. Anda juga dapat melihat transaksi lainnya di profil Anda." : "Thank you, your payment has been received. You can also check your profile page for past orders."}</p>
                        <a className="btn btn-lg btn-primary w-100" href="/account/mycourses">{GetText() ? "Lihat Profil" : "Go to Profile"}</a>
                      </div>
                      : ''
                    }
                  </div>
                      {
                        // ((this.state.order_status === global.config.orderStatus[0] || this.state.order_status === global.config.orderStatus[1])  &&  this.state.confirmation_date === "") ? 
                        ((this.state.order_status === global.config.orderStatus[0])  &&  this.state.confirmation_date === "") ? 
                          <div>
                          <hr className="my-4" size={1} style={{color:"white"}}/>
                          <p className="my-4 text-white">{GetText() ? "Jika Anda telah melakukan pembayaran, harap mengisi formulir di bawah ini agar memudahkan kami melacak pembayaran Anda." : "If you have made payment, please fill the form below so we can track your payment."}</p>
                          <form className="form-payment" onSubmit={this.confirm}>
                            <div className="mt-3">
                              <label className="text-white form-label">{GetText() ? "Tanggal Bayar" : "Payment Date"}</label>
                              {this.state.form_error.some(item => item === 'input_date') ? <span className="error-message">{GetText() ? "Wajib Diisi!" : "Required!"}</span> : ''}
                              <input className="form-control" type="date" name="confirmation_date" onChange={this.inputDate}/>
                            </div>
                            <div className="mt-3">
                              <label className="text-white form-label">{GetText() ? "Keterangan" : "Remarks"}</label>
                              {this.state.form_error.some(item => item === 'input_remarks') ? <span className="error-message">{GetText() ? "Wajib Diisi!" : "Required!"}</span> : ''}
                              <textarea className="form-control" name="confirmation_remarks" placeholder={"I have paid " + this.state.payment_currency_symbol + NumberFormats(this.state.grand_total) + " through " + this.state.payment_account} onChange={this.inputRemarks}></textarea>
                            </div>
                            <div className="mt-3">
                              <label className="text-white form-label">{GetText() ? "Resi Transfer" : "Transfer Receipt"} <small>(Optional)</small></label>
                              <div className="mb-3">
                                  <input type="file" className="form-control" name="confirmation_photo" onChange={this.inputPhoto}/>
                                  {/* <label for="confirmation_photo" id="input-photo" htmlFor="photo">{GetText() ? "Pilih Gambar" : "Choose file"}</label> */}
                              </div>
                            </div>
                            <Button className="w-100 mt-4" variant="primary" size="lg" type="submit" id="submit">{GetText() ? "Kirim" : "Send"}</Button>
                            <hr className="bg-white mt-5"/>
                            <Button className="btn-gray w-100 mt-2 text-primary btn-outline-secondary" variant="outlined" size="sm" type="submit" onClick={this.openModal}>{GetText() ? "Batalkan Pesanan" : "Cancel Order"}</Button>
                          </form>
                          <Modal animation={false} show={this.state.modal_show}>
                            <Modal.Header>
                              <Modal.Title>{GetText() ? 'Batalkan Pesanan?' : 'Cancel Order?'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {GetText() ? 'Anda yakin ingin membatalkan pesanan dengan Nomor Invoice ' : 'Are you sure you want to cancel an order with an Invoice No '}
                              <strong>{this.state.order_id}</strong>?
                            </Modal.Body>
                            <Modal.Footer>
                              {/* <a className="btn btn-outline-primary" href="#" onClick={this.closeModal}>{GetText() ? "Kembali" : "Back"}</a>
                              <a className="btn btn-primary" href="#" onClick={this.cancelOrder} id="cancel">{GetText() ? "Batalkan pesanan" : "Cancel order"}</a> */}
                              <Button variant="outline-primary" onClick={this.closeModal}>{GetText() ? "Kembali" : "Back"}</Button>
                              <Button variant="primary" onClick={this.cancelOrder} id="cancel">{GetText() ? "Batalkan pesanan" : "Cancel order"}</Button>
                            </Modal.Footer>
                          </Modal>
                        </div> 
                        : ((this.state.order_status === global.config.orderStatus[0] || this.state.order_status === global.config.orderStatus[1]) &&  this.state.confirmation_date !== "") ? 
                        <div>
                            <hr className="bg-white mt-5"/>
                            <Button className="btn-gray w-100 mt-2 text-primary btn-outline-secondary" variant="outlined" size="sm" type="submit" onClick={this.openModal}>{GetText() ? "Batalkan Pesanan" : "Cancel Order"}</Button>
                            <Modal animation={false} show={this.state.modal_show}>
                              <Modal.Header>
                                <Modal.Title>{GetText() ? 'Batalkan Pesanan?' : 'Cancel Order?'}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {GetText() ? 'Anda yakin ingin membatalkan pesanan dengan Nomor Invoice ' : 'Are you sure you want to cancel an order with an Invoice No '}
                                <strong>{this.state.order_id}</strong>?
                              </Modal.Body>
                              <Modal.Footer>
                                {/* <a className="btn btn-outline-primary" href="#" onClick={this.closeModal}>{GetText() ? "Kembali" : "Back"}</a>
                                <a className="btn btn-primary" href="#" onClick={this.cancelOrder} id="cancel">{GetText() ? "Batalkan pesanan" : "Cancel order"}</a> */}
                                <Button variant="outline-primary" onClick={this.closeModal}>{GetText() ? "Kembali" : "Back"}</Button>
                                <Button variant="primary" onClick={this.cancelOrder} id="cancel">{GetText() ? "Batalkan pesanan" : "Cancel order"}</Button>
                              </Modal.Footer>
                          </Modal>
                        </div>
                        : ''
                      }
              </div>
            </div>

          </div>
        </Container>
      </div>
      </>
    : <Loading value={{id:'order-area', type: 'screen'}}/> 
    }
    </div>
    );
  }
}