import React, { Component } from 'react';
import { FaRegCalendarDays, FaRegClock, FaRegFolder } from 'react-icons/fa6';
import NumberFormats from '../../config/number-format';
import DateTimeFormats from '../../config/date-time-format';
import NAimage from '../../img/pic-na.png';

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      date: '',
      time: '',
      image: '',
      language: '',
      currency: '',
      price: '',
      link: ''
    }
  }

  componentDidMount() {
    var content = this.props.value;
    this.setState({
      id: content.id,
      title: content.courseTitle,
      category: content.categoryName,
      date: DateTimeFormats(content.courseDate._seconds,"date-long"),
      time: DateTimeFormats(content.courseStartTime._seconds,"time-24"),
      image: (content.coursePicture !== "" && content.coursePicture !== undefined)? content.coursePicture : NAimage,
      language: content.courseLanguage,
      currency: content.courseCurrency,
      price: NumberFormats(content.coursePrice),
      link: '/course/' + content.id
    });
  }

  render() {
    return (
      <a href={this.state.link}>
        <div className="course-list row">
          <div className="col-md-3">
            <img className="w-100 br-8" src={this.state.image} alt={"Course " + this.state.title}/>
          </div>
          <div className="col-md-9">
            <h5 className="mb-3">{this.state.title}</h5>
            <p>
              <FaRegCalendarDays className="me-1"/>
              {this.state.date}
              <FaRegClock className="ms-3 me-1"/>
              {this.state.time}
              <FaRegFolder className="ms-3 me-1"/>
              {this.state.category}
            </p>
            <p>
              {this.state.currency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.name[1] } 
              {this.state.price}
            </p>
          </div>
        </div>
      </a>
    )
  }
}