import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FormErrors } from '../inc/error-form';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

export default class CTLibraryAttachForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library_id: '',
      library_title: '',
      library_attach_id: '',
      libfile_title: '',
      libfile_name: '',
      libfile_id: '',
      libfile_url: '',
      file_url: '',
      selectedFileDocument: null,
      selectedFileDocument2: null,
      selectedFileName: null,
      selectedFileName2: null,
      selectedFileSignedUrl: '',
      selectedFileSignedUrl2: '',            
      uploadedFileUrl: global.config.storageUrl + 'training/library/atch/',
      formErrors: { libfile_title: '', selectedFileDocument: '', file_url: '', selectedFileDocument2: '' },
      selectedFileDocumentValid: false,
      selectedFileDocument2Valid: false,
      file_titleValid: false,
      file_urlValid: false,
      formValid: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => { 
        this.validateField(name, value)
    });
  }
  onChangeFileHandler = (e) => {
    console.log("target files")
    console.log(e.target.files);
    if(e.target.files[0] !== undefined) {
        document.getElementById('loading-area').style.display = 'block'; 
        var fieldname = e.target.name;
        var inputValue = e.target.files[0];
        var inputType = e.target.files[0].type;
        var inputFormat = e.target.files[0].name.split('.').pop();
        var inputName = '';
        const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < 12; i++) {
            const index = Math.floor(Math.random() * chars.length);
            inputName += chars.charAt(index);
        }
        var name = '';
        var validname = '';
        var selectedFile = '';
        var selectedName = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;            
        if(e.target.id === "fileDocument") {
            if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1) {
                selectedFileValid = true;
            }
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            selectedFile = 'selectedFileSignedUrl';
            selectedName = 'selectedFileName';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'Please upload video or pdf only';
        } else {
            if(inputType.indexOf('image') > -1) { 
                selectedFileValid = true; 
            }
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            selectedFile = 'selectedFileSignedUrl2';
            selectedName = 'selectedFileName2';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'Please upload image only';
        }
        if(selectedFileValid === true) {
            var datatext ='{"file_name":"' + inputName + '.' + inputFormat + '","file_type":"' + inputType + '","form_id":"attach-library","form_type":"buckets"}';
            // var datatext ='{"file_name":"' + e.target.files[0].name + '","file_type":"' + inputType + '","form_id":"attach-library","form_type":"buckets"}';
            var data = JSON.parse(datatext);
            console.log(global.config.urlLink);
            console.log(datatext);
            fetch(global.config.urlLink+"/getsignedurl", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then(async (resJson) => {
                console.log(resJson.values);
                if (resJson.status === "200") {
                    var urlUploadFile = resJson.values;
                    await fetch(urlUploadFile, {
                      method: "PUT",
                      headers: {
                        "content-type": "application/octet-stream",
                      },
                      body: inputValue,
                    }).then(async (res) => {
                      let urlFile = res.url.split("?")[0];
                    //   setForm((prevState) => ({
                    //     ...prevState,
                    //     [fieldname]: urlPDFFile,
                    //   }));
                    console.log("urlFile");
                    console.log(urlFile);
                    console.log(datatext);
                      await fetch(global.config.urlLink + "/makepublic", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': authKey,
                        }, body : JSON.stringify(data),
                      }).then((res) => {
                        console.log(fieldname + " : " + urlFile);
                            console.log(name + " : " + inputValue);
                                console.log(selectedFile+ " : " + resJson.values);
                                    console.log(selectedName+ " : " + inputName + '.' + inputFormat);
                                    console.log(validname+ " : " + selectedFileValid);
                        if (res.status === 200) {
                            this.setState({
                                [fieldname]: urlFile,
                                [name]: inputValue,
                                [selectedFile]: resJson.values,
                                [selectedName]: inputName + '.' + inputFormat,
                                formErrors: fieldValidationErrors,
                                [validname] : selectedFileValid,
        
                            }, this.validateForm);
                        }
                      });
                    });
                } else {
                    selectedFileValid = false;
                    fieldValidationErrors[name] = selectedFileValid ? '' : 'Failed upload file';
                    this.setState({
                        [name]: null,
                        [selectedFile]: '',
                        formErrors: fieldValidationErrors,
                        [validname] : selectedFileValid,
                    }, this.validateForm);
                }



                // this.setState({
                //     [name]: inputValue,
                //     [selectedFile]: resJson.values,
                //     [selectedName]: inputName + '.' + inputFormat,
                //     formErrors: fieldValidationErrors,
                //     [validname] : selectedFileValid,

                // }, this.validateForm);
                document.getElementById('loading-area').style.display = 'none'; 
            });
        } else {
            selectedFileValid = false;
            fieldValidationErrors[name] = selectedFileValid ? '' : 'Failed upload file';
            this.setState({
                [name]: null,
                [selectedFile]: '',
                formErrors: fieldValidationErrors,
                [validname] : selectedFileValid,
            }, this.validateForm);
            document.getElementById('loading-area').style.display = 'none'; 
        }
    } else {
        if(e.target.id === "fileDocument") { 
            this.setState({
                selectedFileDocument: null,
                selectedFileSignedUrl: '',
                formErrors: { selectedFileDocument: '', file_url: '' },
                selectedFileDocumentValid: false,
                formValid: false
            }); 
        } else { 
            this.setState({
                selectedFileDocument2: null,
                selectedFileSignedUrl2: '',
                formErrors: { selectedFileDocument2: '' },
                selectedFileDocument2Valid: false,
                formValid: false
            }); 
        }
        document.getElementById('loading-area').style.display = 'none'; 
    }
  }
  validateField(fieldName, value) {        
    let fieldValidationErrors = this.state.formErrors;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let file_titleValid = this.state.file_titleValid;
    let file_urlValid = this.state.file_urlValid;
    let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
    switch(fieldName) {
        case 'libfile_title': 
            // if(this.state.libfile_title === "") {
                file_titleValid = value.trim().length > 0;
                fieldValidationErrors.libfile_title = file_titleValid ? '' : 'title need to be filled';
            // }
            break;
        case 'selectedFileDocument': 
            // if(this.state.file_url === "") {
                selectedFileDocumentValid = value !== null;
                fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'file need to be selected';
            // }
            break;
        case 'file_url': 
            if(this.state.selectedFileDocument === null || this.state.selectedFileDocument === "") {
                file_urlValid = value !== null;
                fieldValidationErrors.file_url = file_urlValid ? '' : 'file need to be filled';
            }
            break;
        default:
            break;
    }
    this.setState({
        formErrors: fieldValidationErrors,
        selectedFileDocumentValid: selectedFileDocumentValid,
        file_titleValid: file_titleValid,
        file_urlValid: file_urlValid,
        selectedFileDocument2Valid: selectedFileDocument2Valid,
    }, this.validateForm);
  }
  validateForm() {
    this.setState({
      formValid: this.state.selectedFileDocumentValid && this.state.file_urlValid && this.state.selectedFileDocument2Valid 
    });
  }
  errorClass(error) {
      return(error.length === 0 ? '' : 'has-error');
  }
  async handleSubmit(e) {
    e.preventDefault();
    document.getElementById('loading-area').style.display = 'block';   
    var fileTitle="";
    if(this.textInputLibFileTitle.value === '') { fileTitle = this.state.libfile_title; } else { fileTitle = this.textInputLibFileTitle.value; }     
    if(fileTitle !== '' && this.state.selectedFileDocument !== null) {
        var uploadedUrl = '', uploadedUrl2 = '', uploadedType = '', fileName="", fileName2="";
        if(this.state.selectedFileName !== "" && this.state.selectedFileName !== null) { uploadedUrl = this.state.uploadedFileUrl+this.state.selectedFileName; fileName = this.state.selectedFileName; }
        if(this.state.selectedFileName2 !== "" && this.state.selectedFileName2 !== null) { uploadedUrl2 = this.state.uploadedFileUrl+this.state.selectedFileName2; fileName2 = this.state.selectedFileName2; }
        uploadedType = this.state.selectedFileDocument.type;
        
        /*
        if(this.state.selectedFileSignedUrl !== '') {
            await fetch(this.state.selectedFileSignedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': this.state.selectedFileDocument.type
                },
                body: this.state.selectedFileDocument
            });
            uploadedUrl = this.state.uploadedFileUrl + this.state.selectedFileName;
            uploadedType = this.state.selectedFileDocument.type;
        }
        if((this.state.selectedFileDocument2 !== null) && (this.state.selectedFileSignedUrl2 !== '')) {
            await fetch(this.state.selectedFileSignedUrl2, {
                method: 'PUT',
                headers: {
                    'Content-Type': this.state.selectedFileDocument2.type
                },
                body: this.state.selectedFileDocument2
            });
            uploadedUrl2 = this.state.uploadedFileUrl + this.state.selectedFileName2;
            var datatext2 ='{"file_name":"'+this.state.selectedFileName2+'"}';
            var data2 = JSON.parse(datatext2);
            await fetch(global.config.urlLink+"/makepublic", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data2)
            });
        }
        */
        var datatext ='{"docid":"'+this.state.library_id+'","subdocid":"'+this.state.library_lesson_id+'","filetitle":"'+fileTitle+'","fileurl":"'+uploadedUrl+'","filetype":"'+uploadedType+'","filename":"'+fileName+'","filethumb":"'+uploadedUrl2+'","filethumbname":"'+fileName2+'","form_id":"form-addattachment","form_type":"buckets"}';
        console.log(datatext);
        var data = JSON.parse(datatext);
        console.log(data)
        
        fetch(global.config.urlLink+"/libraryattachmentadd", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
              window.location.href=global.config.urlCms+"/ctlibrary/detailedit/"+this.state.library_id;
            } else {
                console.log('Something happened wrong');
                this.setState({
                    formErrors: { selectedFileDocument: '', file_url: resJson.values[1] },
                    selectedFileDocumentValid : false,
                    file_urlValid : false
                }, this.validateForm);
            }
        }).catch(err => { 
            console.log(err);
            document.getElementById('loading-area').style.display = 'none'; 
        });
        
    } else {
        document.getElementById('loading-area').style.display = 'none';
        var arrfieldName = ['libfile_title','selectedFileDocument'];
        var arrvalue = [this.textInputLibFileTitle.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }

  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    this.setState({
        library_id: othersContent.libraryId,
        library_lesson_id: othersContent.libraryLessonId,
        library_title: othersContent.libraryTitle,
        library_attach_id: othersContent.libattachid
    }); 
  }

  render() {
    console.log(this.state);
                        
    return (
        <div className="container mb-4">
          <div id="attach-detail-form">
            <form onSubmit={this.handleSubmit}>
              <div>
                  <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                  <input type="hidden" id="library_attach_id" name="library_attach_id" value={this.state.library_attach_id} />
              </div>
              <div className="mt-3"><h5>{this.state.library_title}</h5></div>
              
              <div className="mt-4">
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="library_document">Title</label>                            
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libfile_title"
                              placeholder={this.state.libfile_title}
                              value={this.state.libfile_title}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibFileTitle = input}
                              disabled={this.state.input_disabled}/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libfile_title}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="library_document">Upload File</label>
                      <div className="col-md-9">
                              <input id="fileDocument" type="file" onChange={this.onChangeFileHandler}/>
                              <div className="mt-1">{<FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>}</div>
                              <div className="mt-2 fieldComments">Please upload pdf document or video</div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <div className="col-md-3">
                          <div>Upload Thumbnail</div>
                          <div className="notesText">(for video only)</div>
                      </div>
                      <div className="col-md-9">
                              <input id="fileDocument2" type="file" onChange={this.onChangeFileHandler}/>
                              <div className="mt-1">{<FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>}</div>
                              <div className="mt-2 fieldComments">Please upload image (jpg/png) with 1280 x 720 pixels</div>
                      </div>                        
                  </div>
                  
                  <div className="row mt-4 mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                  </div>
                   
              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
