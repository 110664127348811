import React, { Component } from "react";
// import '../css/ct-style-2_OLD.css';

export default class CTUC extends Component {
	
	render() {
		return (
			<div className="mt-4">
				<div className="d-flex justify-content-center fs-1 " style={{color:"#89879F"}}>Under Construction</div>
				<div className="d-flex justify-content-center mt-3"  style={{color:"#89879F"}}>Hey! Thank you for checking out our app.</div>
				<div className="d-flex justify-content-center mt-2"  style={{color:"#89879F"}}>It’s not quite ready yet, but we are working hard and it will be ready in approximately.</div>
			</div>
			
		);
	}
}
