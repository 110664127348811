import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormErrors } from '../inc/error-form';
import ReplaceAllContent from '../config/replace-all';
import MyCustomUploadAdapterPlugin from '../inc/ckImageUploader';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

export default class CTLibraryLessonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library_id: '',
      library_title: '',
      liblesson_id: '',
      liblesson_number: '',
      liblesson_subject: '',
      liblesson_description: '',
      source: 'library',
      formErrors: { liblesson_number: '', liblesson_subject: '', liblesson_description: '' },
      liblessonnumberValid: false,
      liblessonsubjectValid: false,
      liblessondescriptionValid: false,
      formValid: false,
      input_disabled: true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleUserCKEditorInput = (e) => {
    const name = "liblesson_description";
    const value = e.getData();
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let liblessonnumberValid = this.state.liblessonnumberValid;
    let liblessonsubjectValid = this.state.liblessonsubjectValid;
    let liblessondescriptionValid = this.state.liblessondescriptionValid;
    
    switch(fieldName) {
        case 'liblesson_number': 
            liblessonnumberValid = value.trim().length > 0;
            fieldValidationErrors.liblesson_number = liblessonnumberValid ? '' : 'lesson number need to be filled';
            break;
        case 'liblesson_subject': 
            liblessonsubjectValid = value.trim().length > 0;
            fieldValidationErrors.liblesson_subject = liblessonsubjectValid ? '' : 'subject lesson need to be filled';
            break;
        case 'liblesson_description': 
            liblessondescriptionValid = value.trim().length > 0;
            fieldValidationErrors.liblesson_description = liblessondescriptionValid ? '' : 'description need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
                    liblessonnumberValid: liblessonnumberValid,
                    liblessonsubjectValid: liblessonsubjectValid,
                    liblessondescriptionValid: liblessondescriptionValid,
                }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.liblessonnumberValid && this.state.liblessonsubjectValid && this.state.liblessondescriptionValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  handleSubmit(e) {
    e.preventDefault();
    if(this.state.liblesson_number !== '' && this.state.liblesson_subject !== '' && this.state.liblesson_description !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var liblessonNumber, liblessonSubject, liblessonDescription;
        if(this.textInputLibLessonNumber.value === '') { liblessonNumber = this.state.liblesson_number; } else { liblessonNumber = this.textInputLibLessonNumber.value; }
        if(this.textInputLibLessonSubject.value === '') { liblessonSubject = this.state.liblesson_subject; } else { liblessonSubject = this.textInputLibLessonSubject.value; }
        // if(this.textInputLibLessonDescription.value === '') { liblessonDescription = this.state.liblesson_description; } else { liblessonDescription = this.textInputLibLessonDescription.value; }
        // liblessonDescription = ReplaceAllContent('"','[/"]',ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',liblessonDescription)));
        liblessonDescription = ReplaceAllContent('"',"'",this.state.liblesson_description);
    
        var datatext;
        var urlFetch = global.config.urlLink;
        if(this.state.liblesson_id !== '') { 
            var datatext ='{"library_id":"'+this.state.library_id+'","liblesson_id":"'+this.state.liblesson_id+'","liblesson_number":"'+liblessonNumber+'","liblesson_subject":"'+liblessonSubject+'","liblesson_description":"'+liblessonDescription+'","form_id":"form-editlesson"}';
            urlFetch = urlFetch+"/librarylessonedit"; 
        } else { 
            var datatext ='{"library_id":"'+this.state.library_id+'","liblesson_id":"'+this.state.liblesson_id+'","liblesson_number":"'+liblessonNumber+'","liblesson_subject":"'+liblessonSubject+'","liblesson_description":"'+liblessonDescription+'","form_id":"form-addlesson"}';
            urlFetch = urlFetch+"/librarylessonadd"; 
        }
        console.log(datatext);
        console.log(urlFetch);
        var data = JSON.parse(datatext); 
        
        
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            console.log(resJson);
            console.log(resJson.status);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href=global.config.urlCms+"/ctlibrary/detailedit/"+this.state.library_id;
                //window.location.href='/ctviewlibrary/'+this.state.library_id;
                //window.location.href='/ctmyprofile';
            } else {
                console.log('Something happened wrong');
                console.log(resJson);
            }
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
        });
        
    } else {
        var arrfieldName = ['liblesson_number', 'liblesson_subject','liblesson_description'];
        var arrvalue = [this.textInputLibLessonNumber.value, this.textInputLibLessonSubject.value, this.state.liblesson_description];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }

  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    if(othersContent.liblessonid !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        fetch(global.config.urlLink+"/librarylessonview/"+othersContent.libraryId+"/"+othersContent.liblessonid, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            console.log(resJson);
            console.log(resJson.status);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') { 
                this.setState({
                    library_id: resJson.values[0].docid,
                    library_title: resJson.values[0].content[0].maincontent.libraryTitle,
                    liblesson_id: resJson.values[0].scontent[0].subdocid,
                    liblesson_number: resJson.values[0].scontent[0].subcontent.lessonNumber,
                    liblesson_subject: resJson.values[0].scontent[0].subcontent.lessonTitle,
                    liblesson_description: resJson.values[0].scontent[0].subcontent.lessonDesc,
                    source: 'library',
                    formErrors: { liblesson_number: '', liblesson_subject: '', liblesson_description: '' },
                    liblessonnumberValid: false,
                    liblessonsubjectValid: false,
                    liblessondescriptionValid: false,
                    formValid: false,
                    input_disabled: false
                }); 
            } else {
                console.log('Something happened wrong');
                console.log(resJson);
            }
          }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
          });
    } else {
        this.setState({
            library_id: othersContent.libraryId,
            library_title: othersContent.libraryTitle,
            liblesson_id: othersContent.liblessonid,
            liblesson_number: '',
            liblesson_subject: '',
            liblesson_description: '',
            source: 'library',
            formErrors: { liblesson_number: '', liblesson_subject: '', liblesson_description: '' },
            liblessonnumberValid: false,
            liblessonsubjectValid: false,
            liblessondescriptionValid: false,
            formValid: false,
            input_disabled: false
        }); 
    }
  }

  render() {
    return (
        <div className="container mb-4">
          <div id="lesson-detail-form">
            <form onSubmit={this.handleSubmit}>
              <div>
                  <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                  <input type="hidden" id="liblesson_id" name="liblesson_id" value={this.state.liblesson_id} />
              </div>
              <div className="mt-3"><h5>{this.state.library_title}</h5></div>
              
              <div className="mt-4">
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="liblesson_number">Module Number</label>
                      <div className="col-md-9">
                          <input type="number" className="form-control" name="liblesson_number"
                              placeholder={this.state.liblesson_number}
                              value={this.state.liblesson_number}
                              onChange={this.handleUserInput}  
                              ref={(input) => this.textInputLibLessonNumber = input}
                              disabled={this.state.input_disabled}/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.liblesson_number}/>  }
                          </div>
                      </div>
                  </div>
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="liblesson_subject">Subject </label>
                      <div className="col-md-9">
                          <textarea name="liblesson_subject" className="form-control" 
                              rows={2} cols={30} 
                              /*placeholder={this.state.event_name}*/
                              value={this.state.liblesson_subject} 
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibLessonSubject = input}
                              disabled={this.state.input_disabled}/>
                  
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.liblesson_subject}/>  }
                          </div>
                      </div>
                  </div>
                  {/*<div className="row mt-3">
                      <label className="col-md-3" htmlFor="liblesson_description">Description</label>
                      <div className="col-md-9">
                          <textarea name="liblesson_description" className="form-control" 
                              rows={5} cols={30} 
                              value={this.state.liblesson_description} 
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibLessonDescription = input}
                              disabled={this.state.input_disabled}/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.liblesson_description}/>  }
                          </div>
                      </div>
                  </div>*/}
                  <div className="mt-3">
                      <label htmlFor="liblesson_description">Description</label>
                      <div>
                          <CKEditor
                                  editor  = { ClassicEditor }
                                  name    = "liblesson_description"
                                  data    = {this.state.liblesson_description}
                                  config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                  onReady = { editor => {
                                      // You can store the "editor" and use when it is needed.
                                      console.log( 'Editor is ready to use!', editor );
                                      editor.ui.view.editable.element.style.minHeight = "500px";
                                  } }
                                  onChange={ ( event, editor ) => {
                                      this.handleUserCKEditorInput(editor);
                                      const data = editor.getData();
                                      
                                      //this.textInputContentLongDesc = data;
                                      console.log( { event, editor, data } );
                                      editor.ui.view.editable.element.style.minHeight = "500px";
                                  } }
                                  onBlur={ ( event, editor ) => {
                                      console.log( 'Blur.', editor );
                                      editor.ui.view.editable.element.style.minHeight = "500px";
                                  } }
                                  onFocus={ ( event, editor ) => {
                                      console.log( 'Focus.', editor );
                                      editor.ui.view.editable.element.style.minHeight = "500px";
                                  } }
                              />
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.liblesson_description}/>  }
                          </div>
                      </div>
                  </div>
                  
                  <div className="row mt-4 mb-3">
                    <div className="col-md-12"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                  </div>
                   
              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
