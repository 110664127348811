import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "./App";
import './config/global';

// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Guest from './guest/Index';
// import Member from './member/Index';
// import CMS from './cms/Index';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={global.config.clientLoginId} >
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
  
);

/*
const routing = localStorage.getItem('logged_in') !== '1' ? (

  <Router>
    <Routes>
      <Route exact path="/"><Guest/></Route>
      <Route path="/c"><CMS/></Route>
      <Route><Guest/></Route>
    </Routes>
  </Router>

) : (

  <Router>
    <Routes>
      <Route exact path="/"><Member/></Route>
      <Route path="/c"><CMS/></Route>
      <Route><Member/></Route>
    </Routes>
  </Router>
  
);
ReactDOM.render(routing, document.getElementById('root'));
// ReactDOM.render(<React.StrictMode>{routing}</React.StrictMode>, document.getElementById('root'));
*/

// const MainApp = () => { 
  // var homePage=<Guest />;
  // if(localStorage.getItem('logged_in') === '1') {
  //   homePage=<Member />;
  // }
  // return (
  //   <>
  //    <App />
  
    {/* 
      <div>
        <Routes>
          <Route path="/" element={homePage}></Route>
          <Route path="/c" element={<CMS />}></Route>
        </Routes>
      </div> 
    */}

    {/* {(localStorage.getItem('logged_in') !== '1') ? (

        <Routes>
          
          <Route path="/" element={<Guest/>} /> 
          <Route path="/c"><CMS/></Route> 
          <Route><Guest/></Route>
        </Routes>
    
    ) : (
    
        <Routes>
          <Route exact path="/"><Member/></Route>
          <Route path="/c"><CMS/></Route>
          <Route><Member/></Route> 
        </Routes>
    )
    } */}
    
//     </>
//   )
// };
// const root = ReactDOM.createRoot(document.getElementById("root"));

