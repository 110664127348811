import React, { Component } from 'react';
import Moment from 'moment';
import { Container, Form, Button, Popover, OverlayTrigger, Modal } from 'react-bootstrap';
import { FaCopy, FaDownload, FaCircleCheck } from 'react-icons/fa6';
import '../config/global';
import { GetText } from '../config/helper';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import RandomNumber from '../config/random-number';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import InstructionPayments from '../inc/InstructionPay';
import NAimage from '../img/pic-na.png';
import Loading from '../inc/Loading';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey; 
const authKey2 = global.config.kindcode.training[14]+global.config.authBearerKey; 
const authKey3 = global.config.kindcode.training[7]+global.config.authBearerKey; 
const authKey4 = global.config.kindcode.training[11]+global.config.authBearerKey; 

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_id: localStorage.getItem('doc_id'),
      member_email: '',
      cart_items: [],
      sub_total: 0,
      grand_total: 0,
      symbol_currency: GetText()? global.config.currency.symbol[0] : global.config.currency.symbol[1],
      available_banks: [],
      available_bt_banks: global.config.paymentAccount[global.config.paymentMethod.IDN[1].value],
      payment_method: '',
      payment_bank: '',
      payment_account: '',
      payment_currency: GetText() ? global.config.currency.name[0] : global.config.currency.name[1],
      payment_amount: 0,
      payment_expired_date: Moment().add('1', 'day'),
      payment_result_status: '',
      payment_card_number: '',
      payment_card_expiry_month: '',
      payment_card_expiry_year: '',
      payment_card_cvc: '',
      xendit_token: '',
      qr_string: '',
      card_url: '',
      card_status: '',
      voucher_code: '',
      voucher_amount: 0,
      modal_show: false,
      modal_invoice_id: '',
      order_id: "NA"+new Date().getTime()+RandomNumber(101,999),
      order_date: DateTimeFormats("","date-long"),
      form_error: [],
      invoice_id: '',
      error_page: [false,''],
      instruction_content: {},
      isLoad: false

      // amount: 0,
      // payment_voucher_code: '',
      // payment_voucher_amount: 0,
      // payment_phone: '',

    };
    this.loadData = this.loadData.bind(this);
    this.getCarts = this.getCarts.bind(this);
    this.getAvailableBank = this.getAvailableBank.bind(this);
    this.getVoucher = this.getVoucher.bind(this);
    this.submitCheckout = this.submitCheckout.bind(this);
    this.getPaymentAccount = this.getPaymentAccount.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.createInvoice = this.createInvoice.bind(this);
    // this.createCharge = this.createCharge.bind(this);
    // this.setMethod = this.setMethod.bind(this);
    // this.setBank = this.setBank.bind(this);
    // this.setPhone = this.setPhone.bind(this);
    // this.setCardNumber = this.setCardNumber.bind(this);
    // this.setCardExpiry = this.setCardExpiry.bind(this);
    // this.setCardCvc = this.setCardCvc.bind(this);
    
  }

  closeModal(minvid) {
    this.setState({
      modal_show: false,
      invoice_id: minvid
    });

    document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
    document.getElementById('submit').removeAttribute('disabled');
    return;
  }
  downloadQr = () => {
    const qrContainer = document.getElementById("qris");
    html2canvas(qrContainer).then(function (canvas) {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "qr-code.png");
      downloadLink.setAttribute("href", image);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
  setMethod(event) {
    this.setState({
      payment_method: event.target.value,
      form_error: []
    });
  }

  setBank(event) {
    this.setState({
      payment_bank: event.target.value,
      form_error: []
    });
  }

  setPhone(event) {
    var phone = event.target.value.replace(/\D/g,'');
    var first = Array.from(phone)[0];
    var second = Array.from(phone)[1];
    if (first === '0') {
      phone = '+62' + phone.substring(1);
    } else if ((first === '6') && (second === '2')) {
      phone = '+' + phone;
    } else {
      phone = '+62' + phone;
    }
    this.setState({
      payment_phone: phone,
      form_error: []
    });
  }

  setCardNumber(event) {
    this.setState({
      payment_card_number: event.target.value,
      form_error: []
    });
  }

  setCardExpiry(event) {
    var expiry = event.target.value.split('/');
    this.setState({
      payment_card_expiry_month: expiry[0],
      payment_card_expiry_year: '20' + expiry[1],
      form_error: []
    });
  }

  setCardCvc(event) {
    this.setState({
      payment_card_cvc: event.target.value,
      form_error: []
    });
  }

  getVoucher(event) {
    if (event.target.value.length > 5) {
      var error = this.state.form_error;
      var dataSend2 = {
        voucher_code: event.target.value,
        users_email: this.state.member_email
      };
      fetch(global.config.urlLink+ "/cekvoucher/", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey3
        }, body: JSON.stringify(dataSend2)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong');
        }
      })
      .then(resultJson => {
        if(resultJson.status === '200') {
          var voucherAmount = GetText() ? resultJson.values[0].voucherValue[0] : resultJson.values[0].voucherValue[0];
          var gtotal = this.state.sub_total - voucherAmount;
          this.setState({
            voucher_code: event.target.value,
            voucher_amount: voucherAmount,
            grand_total: gtotal,
            form_error: []
          });
        } else {
          error.push('voucher_code');
          this.setState({
            voucher_code: event.target.value,
            voucher_amount: 0,
            grand_total: this.state.grand_total,
            form_error: error
          });
        }
      }).catch(err => { 
        error.push('voucher_code');
        this.setState({
          voucher_code: event.target.value,
          voucher_amount: 0,
          grand_total: this.state.grand_total,
          form_error: error
        });
      });
    } else {
      this.setState({
        voucher_code: event.target.value,
        voucher_amount: 0,
        grand_total: this.state.grand_total,
        form_error: []
      });
    }
  }

  getPaymentAccount = (dataSend) => {
    return new Promise((resolve) => {
      dataSend.request = dataSend.request_action;
      
      fetch(global.config.urlLinkPG + "/paymentid", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2
        }, 
        body: JSON.stringify(dataSend)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong');
        }
      })
      .then(resultJson => {
        resolve(resultJson.values);
      });
      
    })
  }
  async createInvoice(dataSend) {
    return new Promise((resolve) => {
      dataSend.form_id = "form-checkout";
      fetch(global.config.urlLink + "/trainingaddorder", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey4
        }, 
        body: JSON.stringify(dataSend)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong');
        }
      })
      .then(resultJson => {
        resolve(resultJson);
      });
    });
  }

  async autoVerified(dataSend) {
    return new Promise((resolve) => {
        var data2 = {
            "memberdoc_id": this.state.member_id,
            "order_docid": dataSend.order_docid,
            "sumorder_id": dataSend.sumorder_docid,
            "received_date": Moment().format('YYYY-MM-DD'),
            "received_price": dataSend.payment_gtotal,
            "received_currency": dataSend.payment_currency,
            "verified_remarks":"",
            "form_id": "form-verified-payment"
        };
        
        fetch(global.config.urlLink+"/trainingupdateorder", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey4,
            }, body : JSON.stringify(data2)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
          resolve(resJson);
        });
    });
          
  }

  async submitCheckout(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');
    
    var error = [];
    if (!(this.state.payment_method)) {
      error.push('payment_method');
    }

    if (this.state.payment_method === global.config.paymentMethod.IDN[1].value) { 
      if (!(this.state.payment_bank)) {
        error.push('payment_bank');
      }
    
    } else if (this.state.payment_method === global.config.paymentMethod.IDN[2].value) { 
      if (!(this.state.payment_bank)) {
        error.push('payment_bank');
      }
    
    } else if (this.state.payment_method === global.config.paymentMethod.IDN[4].value) { 
      if (!(this.state.payment_bank)) {
        error.push('payment_bank');
      }
      if (!(this.state.payment_phone)) {
        error.push('payment_phone');
      }
    } else if (this.state.payment_method === global.config.paymentMethod.IDN[0].value) { 
      if (!(this.state.payment_card_number)) {
        error.push('payment_card_number');
      }
      if (!(this.state.payment_card_expiry_month)) {
        error.push('payment_card_expiry');
      }
      if (!(this.state.payment_card_expiry_year)) {
        error.push('payment_card_expiry');
      }
      if (!(this.state.payment_card_cvc)) {
        error.push('payment_card_cvc');
      }
    }
    
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
        var carts_id = [];
        for (var i = 0; i < this.state.cart_items.length; i++) {
          carts_id.push(this.state.cart_items[i].subdocid);
        }
        
        var dataSend = {
          order_id: this.state.order_id,
          trmember_id: this.state.member_id,
          users_email: this.state.member_email,
          cart_items: this.state.cart_items,
          carts_id: carts_id,
          order_subtotal: this.state.sub_total,   // or carts_subtotal in VA
          order_gtotal: this.state.grand_total,
          payment_gtotal: this.state.grand_total,
          payment_amount: this.state.grand_total,
          payment_method: this.state.payment_method,
          payment_currency: this.state.payment_currency,
          payment_vouchercode: this.state.voucher_code, // or voucher_code
          payment_voucheramount: this.state.voucher_amount, // or voucher_amount
          payment_account: '',
          portal_name: 'Novaio',
          // usage_for: 'cart',
          callback_portal_url: global.config.urlLink+"/trainingupdateorder",
          callback_portal_auth: authKey4,
          country_currency: (GetText() ? global.config.currency.countrycode[0] : global.config.currency.countrycode[1])
        };
        var instructionContent = {
          payment_method: this.state.payment_method,
          payment_currency: this.state.payment_currency,
          payment_amount: this.state.grand_total,
          payment_account: ''
        };

        if (this.state.payment_method === global.config.paymentMethod.IDN[1].value) { // Bank Transfer
          var dataSend2a = dataSend;
          if(this.state.payment_bank !== "") {
            var bankInformation = global.config.paymentAccount[global.config.paymentMethod.IDN[1].value][this.state.payment_bank];
            var paymentInfoMsg = "Transfer to " + bankInformation.BankName + "<br/>" + bankInformation.BankAccount + "<br/>a/n " + bankInformation.BankHolder; //global.config.paymentMethod.IDN[1].label;
            dataSend2a.payment_bank = bankInformation.BankCode;
            dataSend2a.payment_account = bankInformation.BankAccount;
            dataSend2a.payment_info = paymentInfoMsg
            dataSend2a.payment_request = JSON.stringify(bankInformation);
            dataSend2a.payment_expired_date = this.state.payment_expired_date;
          }
          
          dataSend2a.payment_response = "";
          var resultCheckoutA = await this.createInvoice(dataSend2a);
          if(resultCheckoutA) {
            if(resultCheckoutA.status === "200") {
              instructionContent.payment_account = bankInformation.BankAccount;
			        instructionContent.payment_bank = bankInformation.BankCode;
              instructionContent.payment_info = paymentInfoMsg;
              // instructionContent.payment_expired_date = "",
			        
                this.setState({
                  invoice_id: resultCheckoutA.values.docid,
                  payment_result_status: global.config.paymentStatus[0],
                  instruction_content: instructionContent,
                  payment_bank: bankInformation.BankCode
                });  
            } else {
              this.setState({
                error_page: [true,''],
                payment_result_status: global.config.paymentStatus[0]
              });
              document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
              document.getElementById('submit').removeAttribute('disabled');
              return;
            }
      
          }
        } else if (this.state.payment_method === global.config.paymentMethod.IDN[2].value) { // Virtual Account
          var dataSend2b = dataSend;
          dataSend2b.payment_bank = this.state.payment_bank;
          // dataSend.payment_account = this.state.payment_account,
          // dataSend2b.payment_info = "VA " + this.state.payment_bank + " " + this.state.payment_account,
          dataSend2b.payment_description = "Novaio Workshop @ " + this.state.grand_total + " " + this.state.payment_currency;
          dataSend2b.request_action = "create va";
          var accountVa = await this.getPaymentAccount(dataSend2b);
          if(accountVa) {
            dataSend2b.payment_account = accountVa.data.account_number;
            dataSend2b.payment_info = "VA " + this.state.payment_bank + " " + accountVa.data.account_number;
            dataSend2b.payment_request = accountVa.data;
            dataSend2b.payment_transaction_docid = accountVa.docid;
            
            var resultCheckoutB = await this.createInvoice(dataSend2b);
            if(resultCheckoutB) {
              if(resultCheckoutB.status === "200") {
                  instructionContent.payment_account = accountVa.data.account_number;
                  instructionContent.payment_bank = this.state.payment_bank;
                
                  this.setState({
                    invoice_id: resultCheckoutB.values.docid,
                    payment_account : accountVa.data.account_number,
                    payment_result_status: global.config.paymentStatus[0],
                    instruction_content: instructionContent,
                  });
              } else {
                this.setState({
                  error_page: [true,''],
                  payment_result_status: global.config.paymentStatus[0]
                });
                document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
                document.getElementById('submit').removeAttribute('disabled');
                return;
              }
        
            }
            
          }
          

        } else if (this.state.payment_method === global.config.paymentMethod.IDN[3].value) { // QRIS
          var dataSend2c = dataSend;
          dataSend2c.callback_url = global.config.urlLinkPG + "/paymentid";
          dataSend2c.payment_bank = this.state.payment_bank;
          dataSend2c.payment_description = "Novaio Workshop @ " + this.state.grand_total + " " + this.state.payment_currency;
          dataSend2c.request_action = "create qr";
          var accountQR = await this.getPaymentAccount(dataSend2c);
          if(accountQR) {
            if(accountQR.data.qr_string !== undefined) { dataSend2c.payment_account = accountQR.data.qr_string; }
            if(accountQR.data.status === 'ACTIVE') {
              dataSend2c.payment_info = "QR ID : " + accountQR.data.id;
            } else {
              dataSend2c.payment_info = "QR Status : " + accountQR.data.status;
            }
            dataSend2c.payment_request = accountQR.data;
            dataSend2c.payment_transaction_docid = accountQR.docid;

            var resultCheckoutC = await this.createInvoice(dataSend2c);
            if(resultCheckoutC) {
              if(resultCheckoutC.status === "200" && accountQR.data.status === 'ACTIVE') {
                  instructionContent.payment_account = accountQR.data.qr_string;
                  
                  this.setState({
                    // invoice_id: resultCheckoutC.values.docid,
                    modal_invoice_id: resultCheckoutC.values.docid,
                    payment_account : accountQR.data.id,
                    qr_string: accountQR.data.qr_string,
                    card_status: 'qrscan',
                    modal_show: true,
                    payment_result_status: global.config.paymentStatus[0],
                    instruction_content: instructionContent,
                  });
              } else {
                this.setState({
                  error_page: [true,''],
                  payment_result_status: global.config.paymentStatus[0]
                });
                document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
                document.getElementById('submit').removeAttribute('disabled');
                return;
              }
            }
            
          }
        } else if (this.state.payment_method === global.config.paymentMethod.IDN[4].value) { // EWallet
          var dataSend2d = dataSend;
          dataSend2d.payment_phone = this.state.payment_phone;
          dataSend2d.payment_bank = this.state.payment_bank;
          dataSend2d.payment_description = "Novaio Workshop @ " + this.state.grand_total + " " + this.state.payment_currency;
          dataSend2d.request_action = "ewallet charge";
          
          var accountWallet = await this.getPaymentAccount(dataSend2d);
          if(accountWallet) {
            if(accountWallet.id !== undefined) { dataSend2d.payment_account = accountWallet.id; }
            var paymentInfo = "Status : "+accountWallet.status+"<br/> ";
            paymentInfo += "Paid using " + this.state.payment_bank;
            if(accountWallet.channel_properties.mobile_number !== undefined) { paymentInfo += " (" + accountWallet.channel_properties.mobile_number + ")"; }
            dataSend2d.payment_info = paymentInfo;
            dataSend2d.payment_request = accountWallet;
            var resultCheckoutD = await this.createInvoice(dataSend2d);
            if(resultCheckoutD) {
              if(resultCheckoutD.status === "200") {
                if(accountWallet.status === 'PENDING') {
                  this.setState({
                    invoice_id: resultCheckoutD.values.docid,
                    payment_account : accountWallet.id,
                    card_url: (accountWallet.actions !== null && accountWallet.actions.desktop_web_checkout_url !== undefined)?  accountWallet.actions.desktop_web_checkout_url : '',
                    card_status: '3dsecure',
                    // modal_show: true,
                    payment_result_status: global.config.paymentStatus[0]
                  });
                } else if(accountWallet.status === 'SUCCESS') {
                  this.setState({
                    invoice_id: resultCheckoutD.values.docid,
                    payment_account : accountWallet.id,
                    card_status: 'success',
                    modal_show: false,
                    payment_result_status: global.config.paymentStatus[1]
                  });
                } else {
                  this.setState({
                    card_status: 'declined',
                    modal_show: true,
                    payment_result_status: global.config.paymentStatus[0]
                  });
                }                
              } else {
                this.setState({
                  error_page: [true,''],
                  payment_result_status: global.config.paymentStatus[0]
                });
                document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
                document.getElementById('submit').removeAttribute('disabled');
                return;
              }
            }
          }
        } else if (this.state.payment_method === global.config.paymentMethod.IDN[0].value || this.state.payment_method === global.config.paymentMethod.ENG[0].value) { // Credit Card
          if (this.state.payment_currency === global.config.currency.name[0]) { // IDR - Xendit
            // var dataSend2e = dataSend;
            const cardData = {
              card_number: this.state.payment_card_number,
              card_exp_month: this.state.payment_card_expiry_month,
              card_exp_year: this.state.payment_card_expiry_year,
              card_cvn: this.state.payment_card_cvc,
              currency: this.state.payment_currency, //'IDR',
              amount: this.state.grand_total,
              is_multiple_use: false,
              should_authenticate: true,
            };            
            this.xendit.card.createToken(cardData, async (err, token) => {
              if (err) {
                  this.setState({
                    card_status: 'declined',
                    modal_show: true
                  });
                  return;
              } else {
                if (token.status === 'VERIFIED') {
                  var dataSend2e = dataSend;
                  // dataSend2e.payment_account = token.id;
                  dataSend2e.payment_bank = token.card_info?.bank;
                  dataSend2e.payment_description = "Novaio Workshop @ " + this.state.grand_total + " " + this.state.payment_currency;
                  dataSend2e.request_action = "create charge";
                  dataSend2e.token_id = token.id;
                  dataSend2e.payment_request = token;

                  var accountCC = await this.getPaymentAccount(dataSend2e);
                  if(accountCC) {
                    var jsonCharge = accountCC.data;
                    if (jsonCharge.status === 'CAPTURED' || jsonCharge.status === 'AUTHORIZED') {

                      var payment_infoz = global.config.paymentMethod.IDN[0].label;
                      if(jsonCharge.card_brand !== undefined) { payment_infoz += jsonCharge.card_brand; }
                      if(jsonCharge.card_type !== undefined) { payment_infoz += " " + jsonCharge.card_type; }
                      if(jsonCharge.issuing_bank_name !== undefined) { payment_infoz += "<br/>"+jsonCharge.issuing_bank_name; }
                      if(jsonCharge.approval_code !== undefined) { payment_infoz += "<br/>Approval Code : "+jsonCharge.approval_code; }
                      
                      dataSend2e.payment_info = payment_infoz;
                      dataSend2e.payment_account = jsonCharge.id;
                      dataSend2e.payment_bank = jsonCharge.card_brand + " " + jsonCharge.card_type;
                      dataSend2e.payment_response = JSON.stringify(jsonCharge);
                      dataSend2e.payment_request.charge_response = jsonCharge;
                      dataSend2e.payment_transaction_docid = accountCC.docid;

                      var resultCheckoutE = await this.createInvoice(dataSend2e);
                      if(resultCheckoutE) {
                        if(resultCheckoutE.status === "200" && (jsonCharge.status === 'CAPTURED' || jsonCharge.status === 'AUTHORIZED')) {
                          var dataSend2ev = dataSend2e;
                          dataSend2ev.order_docid = resultCheckoutE.values.docid;
                          dataSend2ev.sumorder_docid = resultCheckoutE.values.sumorderid;
                          var resultverified = await this.autoVerified(dataSend2ev);
                          if(resultverified) {
                  
                            this.setState({
                              invoice_id: resultCheckoutE.values.docid,
                              payment_account : jsonCharge.id,
                              payment_result_status: global.config.paymentStatus[1],
                              // instruction_content: instructionContent,
                              card_status: 'success',
                              modal_show: false
                            });
                          }
                        } else {
                          this.setState({
                            card_status: 'declined',        
                          })
                          document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
                          document.getElementById('submit').removeAttribute('disabled');
                          return;
                        }
                      }
                    } else {
                      this.setState({
                        card_status: 'declined',        
                      })
                    }
                  }
                

                } else if (token.status === 'IN_REVIEW') {
                  this.setState({
                    payment_account: token.id,
                    card_url: token.payer_authentication_url,
                    card_status: '3dsecure',
                    modal_show: true
                  });
                  return;
                } else {
                  this.setState({
                    card_status: 'declined',
                    modal_show: true
                  });
                  document.getElementById('submit').innerText = (GetText() ? "Lakukan Pembayaran" : "Make Payment");
                  document.getElementById('submit').removeAttribute('disabled');
                  return;
                }
              }
            });
          } else { // USD - Stripe
            var stripeSend = {
              request: 'create payment',
              order_id: this.state.order_id,
              name: localStorage.getItem('name'),
              email: localStorage.getItem('email'),
              country_code: GetText() ? 'ID' : 'SG',
              currency: GetText() ? 'idr' : 'usd',
              card_number: this.state.payment_card_number,
              card_exp_month: this.state.payment_card_expiry_month,
              card_exp_year: this.state.payment_card_expiry_year,
              card_cvc: this.state.payment_card_cvc,
              amount: this.state.grand_total
            }
            var responseStripe = await fetch(global.config.urlLinkPG + "/paymentus", {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey2
              },
              body: JSON.stringify(stripeSend)
            });
            var jsonStripe = await responseStripe.json();
            if (jsonStripe.values.status !== undefined && jsonStripe.values.status === 'succeeded') {
              this.setState({
                payment_account: jsonStripe.values.id,
                card_status: 'success',
                modal_show: false
              });
              dataSend = {
                order_id: this.state.order_id,
                trmember_id: this.state.member_id,
                subscribe_plan: this.state.order_type,
                subscribe_period: this.state.order_period,
                payment_method: this.state.payment_method,
                payment_bank: '',
                payment_account: jsonStripe.values.id,
                payment_currency: this.state.payment_currency,
                payment_amount: this.state.grand_total,
                payment_info: "Credit Card",
                payment_response: JSON.stringify(jsonStripe.values[2]),              
                subscribe_price: this.state.amount,
                subscribe_gtotal: this.state.grand_total,
                subscribe_vouchercode: this.state.payment_voucher_code,
                subscribe_voucheramount: this.state.payment_voucher_amount,
                users_email: localStorage.getItem("email"),
                payment_status: jsonStripe.values.status,
                subscribe_remarks: "Pay using Credit Card (Stripe)",
                form_id: 'checkout'
              }
              this.createInvoice(dataSend);
            } else if (jsonStripe.values.status === 'requires_action') {
              this.setState({
                payment_account: jsonStripe.values.id,
                card_url: jsonStripe.values.next_action.use_stripe_sdk.stripe_js,
                card_status: '3dsecure',
                modal_show: true
              });
              return;
            } else if (jsonStripe.values.code === 'incorrect_number') {
              this.setState({
                card_status: 'incorrect_number',
                modal_show: true
              });
              return;
            } else if (jsonStripe.values.code === 'incorrect_cvc') {
              this.setState({
                card_status: 'incorrect_cvc',
                modal_show: true
              });
              return;
            } else if (jsonStripe.values.code === 'expired_card' || jsonStripe.values.code === 'invalid_expiry_year' || jsonStripe.values.code === 'invalid_expiry_month') {
              this.setState({
                card_status: 'incorrect_expiry',
                modal_show: true
              });
              return;
            } else {
              this.setState({
                card_status: 'declined',
                modal_show: true
              });
              return;
            }
          }
        } 
        
    }

    

  }


  getAvailableBank = () => {
    return new Promise((resolve) => {
      var xenditRequest = {
        request: 'available banks'
      }
      fetch(global.config.urlLinkPG + "/paymentid", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2
        }, 
        body: JSON.stringify(xenditRequest)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong');
        }
      })
      .then(resultJson => {
        resolve(resultJson.values);
      });
    })
  }
  getCarts = (docid,lang) => {
    return new Promise((resolve) => {
      var dataSend = { docid: docid, types: "carts" }
      fetch(global.config.urlLink+"/traininguserview", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey
        }, 
        body : JSON.stringify(dataSend)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        var email = resJson.values[0].content[0].maincontent.memberEmail;
        var carts = resJson.values[0].scontent5;
        var total = 0;
        var items = [];
        if (carts) {
          for (var i = 0; i < carts.length; i++) {
            if (carts[i].subcontent.courseLanguage === lang) {
              items.push(carts[i]);
              total += carts[i].subcontent.subtotal;
            }
          }
        }
        resolve({
          carts: items,
          total: total,
          emails: email
        });
      })
    })
  }

  async loadData(docid,lang) {
    var [thing1, thing2] = await Promise.all([this.getCarts(docid,lang), this.getAvailableBank()]);
    var arrReturnValue = {};
    arrReturnValue.cartItems = thing1.carts;
    arrReturnValue.subtotal = thing1.total;
    arrReturnValue.emails = thing1.emails;
    arrReturnValue.availableBank = thing2;
    return arrReturnValue;
  }
  
  componentDidMount() {
    this.loadData(localStorage.getItem('doc_id'),localStorage.getItem('language'))
    .then((response) => {


        // ===============
        const script = document.createElement('script');
        script.src = 'https://js.xendit.co/v1/xendit.min.js';
        script.async = true;
        script.onload = () => {
          const xendit = window['Xendit'];
          xendit.setPublishableKey(global.config.xenditKey);
          this.xendit = xendit;
        };
        document.body.appendChild(script);
        // ===============
        this.setState({
          member_email: response.emails,
          available_banks: response.availableBank,
          cart_items: response.cartItems,
          sub_total: response.subtotal,
          grand_total: response.subtotal - this.state.voucher_amount,
          isLoad: true
        }) 
        // document.getElementById('form').classList.remove('d-none');
        // ===============
        
    });
  }
  render() {
    const popover = (
      <Popover id="popover">
        <Popover.Title as="p">{GetText() ? "Nomor disalin" : "Number Copied"}</Popover.Title>
      </Popover>
    );
    return (
      <div className="checkout">
        {
          this.state.invoice_id !== "" ? (
            <Container>
              <div className="py-5">
                <div>
                      <div className="row"  style={{maxWidth:"560px"}}>
                        <div className="col-md-2 d-flex align-items-center justify-content-center">
                          <FaCircleCheck color="green" fontSize="80px" />
                        </div>
                        <div className="col-md-10">
                          <div className="mt-2 ms-2 fs-3"><strong>{ GetText() ? "Selamat!"  : "Congratulations!" }</strong></div>
                          {	GetText() ? 
                            ( <div  className="mt-1 ms-2 fs-5">Pesanan Anda berhasil dipesan.</div>) 
                            : ( <div  className="mt-1 ms-2 fs-5">Your order has been successfully created.</div> ) 
                          }
                        </div>
                      </div>
                </div>
                <div className="row mt-5">
                    <div className={`${
                          this.state.payment_result_status === global.config.paymentStatus[1] ? "" : "col-md-6"
                        }`}>
                      <div id="success">
                        <div  style={{maxWidth:"560px"}}>
                          <div >
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="bg-body rounded shadow p-3 mb-5 w-100">
                                        <div className="row mt-1 mb-1">
                                          <div className="col-6">{GetText() ? "Tanggal Pesanan" : "Order Date"}</div>
                                          <div className="col-6"><strong>{this.state.order_date}</strong></div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                          <div className="col-6">{GetText() ? "Nomor Invoice" : "Invoice No"}</div>
                                          <div className="col-6"><strong>{this.state.order_id}</strong></div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                          <div className="col-6">{GetText() ? "Total Pembayaran" : "Payment Amount"}</div>
                                          <div className="col-6 text-primary"><strong>{this.state.symbol_currency} {NumberFormats(this.state.grand_total)}</strong></div>
                                        </div>
                                        <div className="row mb-1 mt-3">
                                          <div className="col-6">{GetText() ? "Metode Pembayaran" : "Payment Method"}</div>
                                          <div className="col-6"><strong>{this.state.payment_method + ' ' + this.state.payment_bank}</strong></div>
                                        </div>
                                      {
                                      (this.state.payment_result_status === global.config.paymentStatus[1])?
                                          <div className="row mt-1 mb-1">
                                            <div className="col-6">{GetText() ? "Status Pembayaran" : "Payment Status"}</div>
                                            <div className="col-6"><strong>{GetText() ? "LUNAS" : "PAID"}</strong></div>
                                          </div>
                                        // : 
                                          // (this.state.payment_method === global.config.paymentMethod.IDN[3].value)? // QRIS
                                          //   <></>
                                          // : (this.state.payment_method === global.config.paymentMethod.IDN[4].value)? // EWallet
                                          //   <></>
                                          : 
                                            <div className="row mt-3 mb-1">
                                              <div className="col-12">
                                                {GetText() ? "Silakan lakukan pembayaran sebelum " : "Please make a payment before " }
                                                {/* <strong>{Moment().add('1', 'day').format('D MMMM Y HH:mm')}</strong>  */}
                                                <strong>{this.state.payment_expired_date.format('D MMMM Y HH:mm')}</strong> 
                                              </div>
                                            { 
                                              (this.state.payment_method === global.config.paymentMethod.IDN[1].value || this.state.payment_method === global.config.paymentMethod.IDN[2].value)? // Bank Transfer || Virtual Account
                                              <div className="col-12">
                                                {this.state.payment_info}
                                                {this.state.payment_bank}<br/>
                                                <strong>
                                                  {this.state.payment_account}
                                                  {this.state.payment_account !== "" && this.state.payment_account !== undefined && 
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                                      <a href="#!" className="text-secondary ms-2"  onClick={() => {navigator.clipboard.writeText(this.state.payment_account)}}><FaCopy/></a>
                                                    </OverlayTrigger>
                                                  }
                                                </strong>
                                              </div>
                                              : <></>
                                            }
                                            </div>
                                          
                                      }
                                        
                                        <div className="mt-3 mb-4 justify-content-center d-flex">
                                          <a className="btn btn-outline-primary mt-4" href={"/orders/" + this.state.order_id}>{GetText() ? "Lihat Detil Pesanan" : "View Order Detail"}</a>
                                        </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    {
                      (this.state.payment_result_status === global.config.paymentStatus[1])? <></>
                      : 
                        <div className="col-md-6">
                          {/* <div className="mb-4 mt-2">{GetText() ? "Silakan melakukan pembayaran melalui " + this.state.payment_method + " ke akun berikut. Jika Anda telah selesai, mohon tunggu sampai status pesanan berubah." : "Please make your payment through " + this.state.payment_method + " to this account below. If you have made payment, please wait until the order status is changed."}</div> */}
                          {/* <div className="mb-4 mt-2">{GetText() ? "Silakan melakukan pembayaran dengan mengikuti instruksi dibawah ini" : "Please make payment by following the instructions below."}</div> */}
                          { 
                            (this.state.payment_method === global.config.paymentMethod.IDN[3].value)? // QRIS
                                this.state.card_status === 'qrscan' ? (
                                  <>
                                  <div className="mb-4 mt-2">{GetText() ? "Silakan scan QR Code dibawah ini untuk melakukan pembayaran" : "Please scan this QR Code to make the payment."}</div>
                                  <div id="qris" className="text-center">
                                    <div className="qr-container">
                                      <QRCode className="qr-code" value={this.state.qr_string}/>
                                    </div>
                                  </div>
                                  </>
                                ) : <></>

                            : (this.state.payment_method === global.config.paymentMethod.IDN[4].value)? // EWallet
                                this.state.card_status === '3dsecure' ? (<iframe width="100%" height="600" src={this.state.card_url} title="3dsecure"></iframe>) : <></> 
                            : (this.state.payment_method === global.config.paymentMethod.IDN[0].value || this.state.payment_method === global.config.paymentMethod.ENG[0].value)? // Credit Card
                                  <></>
                            : <InstructionPayments value={{data:this.state.instruction_content}}/>
                          }
                        </div>
                      
                    }
                </div>
              </div>

              

            </Container>
          ) : 
            this.state.isLoad === true? 
            (
            <Container>
              <div className="row py-5">
                <div className="col-md-7">
                  <div className="card card-pop">
                    <div className="card-body p-4">
                      <div className="header">
                        <div className="row">
                          <div className="col-6">
                            <h4 className="mt-1 mb-0">{GetText() ? "Konfirmasi Pesanan" : "Confirmation Order"}</h4>
                          </div>
                        </div>
                        <hr/>
                      </div>
                      <div className="table-responsive">
                      {this.state.cart_items.length > 0?  
                        <table className="table mb-0">
                          <thead className="card-header">
                            <tr>
                              <td className="border-0"><strong>{GetText() ? "Item" : "Item"}</strong></td>
                              <td className="text-end border-0"><strong>{GetText() ? "Harga" : "Amount"} ({this.state.symbol_currency.trim()})</strong></td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.cart_items
                              .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                              .map((cart, i) => {
                                return (
                                  <tr key={'item-' + i}>
                                    <td className="border-0">
                                      <div className="cart-item-info text-left">
                                        <img src={cart.subcontent.coursePicture !== "" && cart.subcontent.coursePicture !== undefined? cart.subcontent.coursePicture : NAimage} alt={cart.subcontent.courseTitle} width={40}/>
                                        <p>
                                          {cart.subcontent.courseTitle}
                                          <br/>
                                          <small><strong>{cart.subcontent.categoryName}</strong></small>
                                        </p>
                                      </div>
                                    </td>
                                    <td className="text-end border-0">{ NumberFormats(cart.subcontent.coursePrice)}</td>
                                  </tr>
                                );
                              })
                            }
                            {(this.state.sub_total !== this.state.grand_total)? 
                            <>
                            <tr>
                              <td className="text-end border-bottom-0 border-end-0 border-start-0 border-1"><strong>Subtotal</strong></td>
                              <td className="text-end border-bottom-0 border-end-0 border-start-0 border-1"><strong>{NumberFormats(this.state.sub_total)}</strong></td>
                            </tr>
                            <tr>
                              <td className="text-end border-0"><strong>Voucher</strong></td>
                              <td className="text-end border-0"><strong>{NumberFormats(this.state.voucher_amount)}</strong></td>
                            </tr>
                            </>
                            : <></>
                            }
                          </tbody>
                          
                          <tfoot className="card-footer">
                            <tr>
                              <td className="text-end"><strong>TOTAL</strong></td>
                              <td className="text-end"><strong>{NumberFormats(this.state.grand_total)}</strong></td>
                            </tr>
                          </tfoot>
                        </table>
                        : <div className="mt-5 mb-5 text-center">no item</div> 
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-4 mt-md-0">
                  <div className="bg-dark p-4">
                    
                    <Form onSubmit={this.submitCheckout}>
                      <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                      <div className="row">
                        <div  className="col-md-12 mt-4">
                            <div>
                              <label className="text-white form-label">{GetText() ? "Metode Pembayaran" : "Payment Method"}</label>
                              {this.state.form_error.some(item => item === 'payment_method') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                            </div>
                            <div>
                              <select className="form-select" aria-label=".form-select-lg example" onChange={(e) => this.setMethod(e)}>
                                <option></option>
                                {
                                  GetText() ? 
                                    <>
                                    {/* <option value={global.config.paymentMethod.IDN[1].value}>{global.config.paymentMethod.IDN[1].label}</option> */}
                                    <option value={global.config.paymentMethod.IDN[2].value}>{global.config.paymentMethod.IDN[2].label}</option>
                                    <option value={global.config.paymentMethod.IDN[3].value}>{global.config.paymentMethod.IDN[3].label}</option>
                                    <option value={global.config.paymentMethod.IDN[0].value}>{global.config.paymentMethod.IDN[0].label}</option>
                                    {/* <option value={global.config.paymentMethod.IDN[4].value}>{global.config.paymentMethod.IDN[4].label}</option> */}
                                    </>
                                    : 
                                    <>
                                    <option value={global.config.paymentMethod.ENG[0].value}>{global.config.paymentMethod.ENG[0].label}</option>
                                    </>

                                  }
                              </select>

                            </div>
                          </div>
                          {
                          (this.state.payment_method === global.config.paymentMethod.IDN[1].value)?
                          <div className="col-12 mt-4" id="payment-banktransfer">
                            
                              {
                                this.state.available_bt_banks.length > -0 ? (
                                <div className="row">
                                  <div className="col-md-12">
                                    <label className="text-white form-label">{GetText() ? "Pilih Bank" : "Select Bank"}</label>
                                    {this.state.form_error.some(item => item === 'payment_bank') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                  </div>
                                  <div className="col-md-12">
                                    {
                                        this.state.available_bt_banks.map((bt_bank, j) => {
                                          return (
                                            <div className="box-options mb-2">
                                              <div className="form-check">
                                              <input className="form-check-input" type="radio" name="bank_code" id={"bt-" + bt_bank.BankCode.toLowerCase()} value={j} onChange={(e) => this.setBank(e)} />
                                              <label className="form-check-label" htmlFor={"bt-" + bt_bank.BankCode.toLowerCase()} style={{marginLeft:"5px"}}>
                                                  {bt_bank.BankName}
                                                  <img className="box-options-logo" src={"/img/bank-logo-" + bt_bank.BankCode.toLowerCase() + ".jpg"} alt={"logo-" + bt_bank.BankCode.toLowerCase()}/>
                                              </label>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                  </div>
                                </div>
                                ) : ''
                              }
                            
                          </div>
                          : 
                          (this.state.payment_method === global.config.paymentMethod.IDN[2].value)?
                            <div className="col-12 mt-4" id="payment-virtual-account">
                              
                                {
                                  this.state.available_banks.length > -0 ? (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label className="text-white form-label">{GetText() ? "Pilih Bank" : "Select Bank"}</label>
                                      {this.state.form_error.some(item => item === 'payment_bank') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                    </div>
                                    <div className="col-md-12">
                                      {
                                          this.state.available_banks.map((bank, i) => {
                                            return (
                                              <div className="box-options mb-2">
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="bank_code" id={"va-" + bank.code.toLowerCase()} value={bank.code} onChange={(e) => this.setBank(e)} />
                                                <label className="form-check-label" htmlFor={"va-" + bank.code.toLowerCase()} style={{marginLeft:"5px"}}>
                                                    {bank.name}
                                                    <img className="box-options-logo" src={"/img/bank-logo-" + bank.code.toLowerCase() + ".jpg"} alt={"logo-" + bank.code.toLowerCase()}/>
                                                </label>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                    </div>
                                  </div>
                                  ) : ''
                                }
                              
                            </div>
                          : 
                          (this.state.payment_method === global.config.paymentMethod.IDN[4].value)?
                            <div className="col-12 mt-4" id="payment-ewallet">
                                <div className="col-12" id="payment-ewallet">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label className="text-white form-label">{GetText() ?  "Pilih Akun" : "Select Account"}</label>
                                      {this.state.form_error.some(item => item === 'payment_bank') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}                                        
                                    </div>
                                    <div className="col-md-12">
                                      {
                                          global.config.paymentWallets.map((bank2, i) => {
                                            return (
                                              <div className="box-options mb-2">
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="bank_code" id={"ewallet-" + bank2.name.toLowerCase()} value={bank2.code} onChange={(e) => this.setBank(e)} />
                                                <label className="form-check-label" htmlFor={"ewallet-" + bank2.name.toLowerCase()} style={{marginLeft:"5px"}}>
                                                    {bank2.name}
                                                    <img className="box-options-logo" src={bank2.logo} alt={"logo-" + bank2.name.toLowerCase()}/>
                                                </label>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                    </div>                           
                                    <div className="col-md-12 mt-2">
                                      <div className="col-md-12">
                                        <label className="text-white form-label">{GetText() ?  "Nomor HP" : "Phone Number"}</label>
                                        {this.state.form_error.some(item => item === 'payment_phone') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}                                        
                                      </div>
                                      <input className="form-control" type="text" name="payment_phone" value={this.state.payment_phone} onChange={(e) => this.setPhone(e)} />
                                    </div>
                                  </div>
                                </div>
                            </div>
                          :
                          (this.state.payment_method === global.config.paymentMethod.IDN[0].value || this.state.payment_method === global.config.paymentMethod.ENG[0].value)?
                            <div className="col-12 mt-4" id="payment-credit-card">
                                <div className="row">
                                  <div className="col-md-12">
                                      <div>
                                        <label className="text-white form-label">{GetText() ? "Nomor Kartu" : "Card Number"}</label>
                                        {this.state.form_error.some(item => item === 'payment_card_number') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                      </div>
                                      <input className="form-control" type="text" name="payment_card_number" onChange={(e) => this.setCardNumber(e)}/>
                                  </div>
                                  <div className="col-6 mt-2">
                                      <div>
                                        <label className="text-white form-label">{GetText() ? "Kadaluarsa" : "Expiration"}</label>
                                        {this.state.form_error.some(item => item === 'payment_card_expiry') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                      </div>
                                      <input className="form-control" type="text" name="payment_card_expiry" placeholder="--/--" onChange={(e) => this.setCardExpiry(e)}/>
                                  </div>
                                  <div className="col-6 mt-2">
                                      <div>
                                        <label className="text-white form-label">CVC</label>
                                        {this.state.form_error.some(item => item === 'payment_card_cvc') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                      </div>
                                      <input className="form-control" type="text" name="payment_card_cvc" onChange={(e) => this.setCardCvc(e)}/>
                                  </div>
                                </div>
                            </div>
                          : 
                          (this.state.payment_method === global.config.paymentMethod.IDN[3].value)?
                            <></>
                          : <></>
                        }
                        <div className="col-md-12  mt-4">
                            <div>
                              <label className="text-white form-label">{GetText() ? "Kode Voucher" : "Voucher Code"}</label>
                              {this.state.form_error.some(item => item === 'payment_voucher_code') ? <span className="error-message">{GetText() ? "kode voucher tidak valid" : "invalid voucher code"}</span> : ''}
                            </div>
                            <input className="form-control" type="text" name="voucher_code" onKeyUp={(e) => this.getVoucher(e)} />
                        </div>
                        <div className="col-6">
                          <p className="mt-3 text-white">Grand Total</p>
                        </div>
                        <div className="col-6 text-end">
                          <p className="mt-3 text-white">{this.state.symbol_currency} {NumberFormats(this.state.grand_total)}</p>
                        </div>
                        <div className="col-md-12 mt-3">
                          {/* <p className="mt-3 text-white">{GetText() ? "Anda akan ditagih secara otomatis seminggu sebelum langganan Anda berakhir. Anda juga dapat membatalkan tagihan otomatis kapanpun." : "You will be charged automatically a week before your subscription expires. Cancel renewal at any time."}</p> */}
                          <Button className="w-100" variant="primary" size="lg" type="submit" id="submit" disabled={this.state.cart_items.length > 0 ? false : true} >
                            {GetText() ? "Lakukan Pembayaran" : "Make Payment"}
                          </Button>
                        </div>

                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <Modal animation={false} show={this.state.modal_show}>
                <Modal.Header>
                  {this.state.card_status === 'incorrect_number' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === 'incorrect_cvc' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === 'incorrect_expiry' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === 'declined' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === '3dsecure' ? (<Modal.Title>{GetText() ? 'Diperlukan Kode Otentikasi' : 'Authentication Code Required'}</Modal.Title>) : ''}
                  {this.state.card_status === 'qrscan' ? (<Modal.Title>{GetText() ? 'Pindai QRIS' : 'Please Scan QR Code'}</Modal.Title>) : ''}
                </Modal.Header>
                <Modal.Body>
                  {this.state.card_status === 'incorrect_number' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali nomor kartu Anda.' : 'Your card is declined. Please review your card number.') : ''}
                  {this.state.card_status === 'incorrect_cvc' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali CVC kartu Anda.' : 'Your card is declined. Please review your card CVC.') : ''}
                  {this.state.card_status === 'incorrect_expiry' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali kadaluarsa kartu Anda.' : 'Your card is declined. Please review your card expiration.') : ''}
                  {this.state.card_status === 'declined' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali data kartu Anda.' : 'Your card is declined. Please review your card data.') : ''}
                  {this.state.card_status === '3dsecure' ? (<iframe width="100%" height="600" src={this.state.card_url} title="3dsecure"></iframe>) : <></>}
                  {this.state.card_status === 'qrscan' ? (
                    <div id="qris" className="text-center">
                      <div className="qr-container">
                        <QRCode className="qr-code" value={this.state.qr_string}/>
                      </div>
                    </div>
                  ) : ''}
                </Modal.Body>

                <Modal.Footer>
                {
                  this.state.card_status === '3dsecure' ? 
                    (<Button variant="primary" size="sm" onClick={() => this.closeModal('')}>{GetText() ? "Batal" : "Cancel"}</Button>)
                  : this.state.card_status === 'qrscan' ? 
                    ( <>
                        <span onClick={this.downloadQr} className="btn btn-secondary"><FaDownload/> Download</span>
                        <Button variant="primary" size="sm" onClick={() => this.closeModal(this.state.modal_invoice_id)}>{GetText() ? "Selesai" : "Done"}</Button>
                      </>
                    )
                  :  
                  <>
                    <Button variant="primary" size="sm" onClick={() => this.closeModal('')}>{GetText() ? "Ulangi" : "Retry"}</Button>
                    <Button variant="outline-primary" size="sm" href="/">{GetText() ? "Kembali ke Beranda" : "Back to Home"}</Button>
                  </>
                } 
                </Modal.Footer>
               
              </Modal>

              <Modal animation={false} show={this.state.error_page[0]}>
                <Modal.Header>
                  <Modal.Title>{GetText() ? "Pembayaran Tidak Berhasil" : "Payment Failed"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{GetText() ? "Proses pembayaran Anda tidak berhasil dilakukan. Silakan ulangi kembali proses pembayaran dengan menggunakan metode pembayaran yang sama atau yang lain." : "Your payment process was unsuccessful. Please try again using the same or another payment method."}</Modal.Body>
                <Modal.Footer>
                  <Button ariant="primary" size="sm" onClick={() => window.location.reload()}>{GetText() ? "Ulangi" : "Reload"}</Button>
                  <a className="btn btn-outline-primary" href="/">{GetText() ? "Kembali ke Beranda" : "Back to Home"}</a>
                </Modal.Footer>
            </Modal>

            </Container>
          )
          : (<Loading value={{id:'checkout-area', type: 'screen'}}/> )
        }
      </div>
    );
  }
}