import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';

const authKey = global.config.kindcode.training[5]+global.config.authBearerKey;

export default class CTFeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      feedback_docid: '',
      feedback_text: '',
      feedback_number: '',
      feedback_type: '',
      feedback_status: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: '',
      selectedFileDocument: null,
      formErrors: {feedback_text: '', feedback_number: '', feedback_type: '', feedback_status: '',  option1: '', option2: '', option3: '', option4: '', option5: ''},
      feedbacktextValid: false,
      feedbacknumberValid: false,
      feedbacktypeValid: false,
      feedbackstatusValid: false,
      option1Valid: false,
      option2Valid: false,
      option3Valid: false,
      option4Valid: false,
      option5Valid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => {
        this.validateField(name, value)
    });
  }    
  handleUserSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let feedbacktextValid = this.state.feedbacktextValid;
    let feedbacknumberValid = this.state.feedbacknumberValid;
    let feedbacktypeValid = this.state.feedbacktypeValid;
    let feedbackstatusValid = this.state.feedbackstatusValid;
    switch(fieldName) {
        case 'feedback_text': 
            feedbacktextValid = value.trim().length > 0;
            fieldValidationErrors.feedback_text = feedbacktextValid ? '' : 'question need to be filled';
            break;
        case 'feedback_number': 
            feedbacknumberValid = value.trim().length > 0;
            fieldValidationErrors.feedback_number = feedbacknumberValid ? '' : 'number need to be filled';
            break;
        case 'feedback_type': 
            feedbacktypeValid = value.trim().length > 0;
            fieldValidationErrors.feedback_type = feedbacktypeValid ? '' : 'type need to be filled';
            break;
        case 'feedback_status': 
            feedbackstatusValid = value.trim().length > 0;
            fieldValidationErrors.feedback_status = feedbackstatusValid ? '' : 'status need to be filled';
            break;
        default:
            break;
    }    
    this.setState({
        formErrors: fieldValidationErrors,
        feedbacktextValid: feedbacktextValid,
        feedbacknumberValid: feedbacknumberValid,
        feedbacktypeValid: feedbacktypeValid,
        feedbackstatusValid: feedbackstatusValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({
        formValid: this.state.feedbacktextValid && this.state.feedbacknumberValid && this.state.feedbacktypeValid && this.state.feedbackstatusValid
    });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  submitData() {
    var urlFetch, datatext;
    var feedbackText,feedbackNumber,feedbackType,feedbackStatus;
    var option1 = '',option2 = '',option3 = '',option4 = '',option5 = '';
    if(this.textInputFeedbackText.value === '') { feedbackText = this.state.feedback_text; } else { feedbackText = this.textInputFeedbackText.value; }
    if(this.textInputFeedbackNumber.value === '') { feedbackNumber = this.state.feedback_number; } else { feedbackNumber = this.textInputFeedbackNumber.value; }
    if(this.textInputFeedbackType.value === '') { feedbackType = this.state.feedback_type; } else { feedbackType = this.textInputFeedbackType.value; }
    if(this.textInputFeedbackStatus.value === '') { feedbackStatus = this.state.feedback_status; } else { feedbackStatus = this.textInputFeedbackStatus.value; }
    if(this.textInputFeedbackType.value === 'Multiple Choice') {
        if(this.textInputOption1.value === '') { option1 = this.state.option1; } else { option1 = this.textInputOption1.value; }
        if(this.textInputOption2.value === '') { option2 = this.state.option2; } else { option2 = this.textInputOption2.value; }
        if(this.textInputOption3.value === '') { option3 = this.state.option3; } else { option3 = this.textInputOption3.value; }
        if(this.textInputOption4.value === '') { option4 = this.state.option4; } else { option4 = this.textInputOption4.value; }
        if(this.textInputOption5.value === '') { option5 = this.state.option5; } else { option5 = this.textInputOption5.value; }
    }
    feedbackStatus = feedbackStatus === 'Active' ? 1 : 0;
    feedbackText = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',feedbackText));
    
    if(this.state.feedback_docid !== null && this.state.feedback_docid !== ''  && this.state.feedback_docid !== undefined) {
        datatext ='{"feedback_text":"'+feedbackText+'","feedback_number":"'+feedbackNumber+'","feedback_type":"'+feedbackType+'","feedback_status":"'+feedbackStatus+'","option1":"'+option1+'","option2":"'+option2+'","option3":"'+option3+'","option4":"'+option4+'","option5":"'+option5+'","feedback_docid":"'+this.state.feedback_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/feedbackedit";
    } else {
        datatext ='{"feedback_text":"'+feedbackText+'","feedback_number":"'+feedbackNumber+'","feedback_type":"'+feedbackType+'","feedback_status":"'+feedbackStatus+'","option1":"'+option1+'","option2":"'+option2+'","option3":"'+option3+'","option4":"'+option4+'","option5":"'+option5+'","form_id":"form-add"}';
        // datatext ='{"feedback_text":"'+feedbackText+'","feedback_number":"'+this.state.feedback_number+'","feedback_type":"'+this.state.feedback_type+'","feedback_status":"'+feedbackStatus+'","option1":"'+this.state.option1+'","option2":"'+this.state.option2+'","option3":"'+this.state.option3+'","option4":"'+this.state.option4+'","option5":"'+this.state.option5+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/feedbackadd";
    }
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink+"/view/"+docId;
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.feedback_text !== '' && this.state.feedback_number !== '' && this.state.feedback_type !== '' && this.state.feedback_status !== '') {
        this.submitData();
    } else {
        var arrfieldName = ['feedback_text','feedback_number','feedback_type','feedback_status'];
        var arrvalue = [this.textInputFeedbackText.value,this.textInputFeedbackNumber.value,this.textInputFeedbackType.value,this.textInputFeedbackStatus.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }

  loadData(currpage,typelink,docid) {
    if(typelink === 'edit') {
        if(docid !== '') { 
            fetch(global.config.urlLink+"/feedbackview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                  console.log(resJson);
                  if(resJson.status === '200') {
                    if(resJson.values[0].content[0].maincontent !== undefined) {
                    
                      var resultContent = resJson.values[0].content[0].maincontent;
                      var lText="",lNumber="",lType="",lStatus="";
                      var lOption1="",lOption2="",lOption3="",lOption4="",lOption5="";
                      
                      if(resultContent.feedbackText !== undefined) {
                          lText = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.feedbackText));
                      }
                      if(resultContent.feedbackNumber !== undefined) { lNumber = resultContent.feedbackNumber; }
                      if(resultContent.feedbackType !== undefined) { lType = resultContent.feedbackType; }
                      if(resultContent.feedbackStatus !== undefined) { lStatus = resultContent.feedbackStatus === 1 ? 'Active' : 'Inactive'; }
                      if(resultContent.option1 !== undefined) { lOption1 = resultContent.option1; }
                      if(resultContent.option2 !== undefined) { lOption2 = resultContent.option2; }
                      if(resultContent.option3 !== undefined) { lOption3 = resultContent.option3; }
                      if(resultContent.option4 !== undefined) { lOption4 = resultContent.option4; }
                      if(resultContent.option5 !== undefined) { lOption5 = resultContent.option5; }
                      this.setState({
                          activelink: currpage,
                          stTitle: 'Edit Question',
                          feedback_docid: resJson.values[0].docid,
                          feedback_text: lText,
                          feedback_number: lNumber,
                          feedback_type: lType,
                          feedback_status: lStatus,
                          option1: lOption1,
                          option2: lOption2,
                          option3: lOption3,
                          option4: lOption4,
                          option5: lOption5,
                          formErrors: {feedback_text: '', feedback_number: '', feedback_type: '', feedback_status: '', option1: '', option2: '', option3: '', option4: '', option5: ''},
                          feedbacktextValid: false,
                          feedbacknumberValid: false,
                          feedbacktypeValid: false,
                          feedbackstatusValid: false,
                          option1Valid: false,
                          option2Valid: false,
                          option3Valid: false,
                          option4Valid: false,
                          option5Valid: false,
                          formValid: false,
                      }) 
                    } else {
                      window.location.href=global.config.urlCms+"/"+currpage+"/add";
                    }
                  } else {
                    console.log('Something wrong happened');
                  }
                }).catch(err => err);
        } else {
          this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle: 'Edit Question'
            }
          })
        }
    } else if(typelink === 'add') {
        this.setState( prevState => {
            return {
              ...prevState.activelink, activelink: currpage,
              ...prevState.stTitle, stTitle: 'New Question'
            }
        })
    }    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Feedback" : " Feedback"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div >
                                    <input type="hidden" required name="feedback_docid" value={this.state.feedback_docid}/>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="feedback_number">Number</label>
                                    <div className="col-md-9">
                                        <input type="number" className="form-control" name="feedback_number"
                                            placeholder={this.state.feedback_number}
                                            value={this.state.feedback_number}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputFeedbackNumber = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.feedback_number}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="feedback_text">Question</label>
                                    <div className="col-md-9">
                                        <textarea name="feedback_text" className="form-control" 
                                                rows={5} cols={30} 
                                                value={this.state.feedback_text} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputFeedbackText = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.feedback_text}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="feedback_type">Type</label>
                                    <div className="col-md-9">
                                        <select name="feedback_type" className="form-control" 
                                            value={this.state.feedback_type} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputFeedbackType = input}
                                            disabled={this.state.input_disabled}
                                        >
                                            <option value="" key="">-- please select --</option>
                                            <option value="Multiple Choice" key="multiple_choice">Multiple Choice</option>
                                            <option value="Essay" key="multiple_answer">Essay</option>
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.feedback_type}/>  }</div>
                                    </div>
                                </div>
                                { 
                                    this.state.feedback_type === 'Multiple Choice' ? (
                                        <div className="row">
                                          <div className="col-md-3"></div>
                                          <div className="col-md-9">
                                              <div className="row mt-3"><div className="col-md-12">The answer choices for this question</div></div>
                                              <div className="row mt-3">
                                                  <label className="col-md-3" htmlFor="option1">Option 1</label>
                                                  <div className="col-md-9">
                                                      <input type="text" className="form-control" name="option1"
                                                          placeholder={this.state.option1}
                                                          value={this.state.option1}
                                                          onChange={this.handleUserInput} 
                                                          ref={(input) => this.textInputOption1 = input}
                                                          disabled={this.state.input_disabled}
                                                          required/>
                                                      <div className="mgT05">
                                                          { <FormErrors formErrors={this.state.formErrors.option1}/>  }
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row mt-3">
                                                  <label className="col-md-3" htmlFor="option2">Option 2</label>
                                                  <div className="col-md-9">
                                                      <input type="text" className="form-control" name="option2"
                                                          placeholder={this.state.option2}
                                                          value={this.state.option2}
                                                          onChange={this.handleUserInput} 
                                                          ref={(input) => this.textInputOption2 = input}
                                                          disabled={this.state.input_disabled}
                                                          required/>
                                                      <div className="mgT05">
                                                          { <FormErrors formErrors={this.state.formErrors.option2}/>  }
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row mt-3">
                                                  <label className="col-md-3" htmlFor="option3">Option 3</label>
                                                  <div className="col-md-9">
                                                      <input type="text" className="form-control" name="option3"
                                                          placeholder={this.state.option3}
                                                          value={this.state.option3}
                                                          onChange={this.handleUserInput} 
                                                          ref={(input) => this.textInputOption3 = input}
                                                          disabled={this.state.input_disabled}/>
                                                      <div className="mgT05">
                                                          { <FormErrors formErrors={this.state.formErrors.option3}/>  }
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row mt-3">
                                                  <label className="col-md-3" htmlFor="option4">Option 4</label>
                                                  <div className="col-md-9">
                                                      <input type="text" className="form-control" name="option4"
                                                          placeholder={this.state.option4}
                                                          value={this.state.option4}
                                                          onChange={this.handleUserInput} 
                                                          ref={(input) => this.textInputOption4 = input}
                                                          disabled={this.state.input_disabled}/>
                                                      <div className="mgT05">
                                                          { <FormErrors formErrors={this.state.formErrors.option4}/>  }
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row mt-3">
                                                  <label className="col-md-3" htmlFor="option5">Option 5</label>
                                                  <div className="col-md-9">
                                                      <input type="text" className="form-control" name="option5"
                                                          placeholder={this.state.option5}
                                                          value={this.state.option5}
                                                          onChange={this.handleUserInput} 
                                                          ref={(input) => this.textInputOption5 = input}
                                                          disabled={this.state.input_disabled}/>
                                                      <div className="mgT05">
                                                          { <FormErrors formErrors={this.state.formErrors.option5}/>  }
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                        </div>
                                    ) : ''
                                }
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="feedback_status">Status</label>
                                    <div className="col-md-9">
                                        <select name="feedback_status" className="form-control" 
                                            value={this.state.feedback_status} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputFeedbackStatus = input}
                                            disabled={this.state.input_disabled}
                                        >
                                            <option value="" key="">-- please select --</option>
                                            <option value="Active" key="status_active">Active</option>
                                            <option value="Inactive" key="status_inactive">Inactive</option>
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.feedback_status}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
