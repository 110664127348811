import React, { Component } from 'react';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import { FaPlus, FaTrash, FaPen, FaFileLines } from "react-icons/fa6";
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Popup from '../inc/PopUp';
import CTCategoryForm from './ct-category-form';
import CTCategoryDetail from './ct-category-detail';


const authKey = global.config.kindcode.training[3]+global.config.authBearerKey;

function loadContent(usedfor,keyword,pageshow) {
  return new Promise((resolve) => {
    //document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+keyword+'","pageShow":"'+pageshow+'","usageFor":"'+usedfor+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/categorylist", {
        method: 'POST',
        //mode: 'no-cors', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
            console.log(response);
        }
    }).then(resJson => {
        console.log(resJson);
        // document.getElementById('loading-area').style.display = 'none'; 
        //resJson = '{status: 200, values: { userEmail: "melia@point-star.com",userFullname: "Melia",userId: "111773733087018575892",userLevel: "Admin",userName: "melia@point-star.com",userPassword: ""}}';
        // this.setState({
        //     data: resJson
        // })
        resolve(resJson);
    }).catch(err => {
        // document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
        resolve({"status": "500"});
        
    });
  });
}

export default class CTCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stTitle: '',
      pageShow: '10',
      keyword: '',
      data: {"library": [], "course": []},
      stPopTitle: '',
      isOpen: false,
      isOpen2: false,
      otherContent: '',
      defTabIndex: "",
      usageFor: '',
      activelink:"none"
    }

    this.loadData = this.loadData.bind(this);
    // this.handleSelectTab = this.handleSelectTab.bind(this);
  }

  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
      }
    })
  }  
  urlviewCat(used,id,action) {
    var stPopTitle = "";
    if(used === 'course') { stPopTitle = action+" Categories Workshop"; }
    else if(used === 'library') { stPopTitle = action+" Categories Library"; }
    this.setState( prevState => {
      return {  
        ...prevState.stPopTitle, stPopTitle: stPopTitle,  
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used.toLowerCase()+'","catId":"'+id+'"}'
      }
    })
  }
  urlformCat(used,id,action) {
    var stPopTitle = "";
    if(used === 'course') { stPopTitle = action+" Categories Workshop"; }
    else if(used === 'library') { stPopTitle = action+" Categories Library"; }
    this.setState( prevState => {
      return {  
          ...prevState.stPopTitle, stPopTitle: stPopTitle,
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used.toLowerCase()+'","catId":"'+id+'"}'
      }
    })
  }
  urldeleteCat(used,valId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      //var urlString = global.ctconfig.urlLink+"/categorydelete/"+valId;
      var urlString = global.config.urlLink+"/categorydelete";
      // var datatext ='{"docid":"'+valId+'","numchild":1,"usageFor":"'+used.toLowerCase()+'","childlevel":0}';
      var datatext ='{"docid":"'+valId+'","usageFor":"'+used.toLowerCase()+'","form_id":"delete-cath"}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
          //method: 'DELETE',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              window.location.reload();
              //window.location.href='/listevent';
          } else {
              console.log('Something happened wrong delete');
              console.log(resJson);
          }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
      });
    }
  }
  // handleSelectTab(idx) {
  //   console.log(idx);
  //   var used = "library";
  //   if(idx===1) { used = "course"; }
  //   this.setState( prevState => {
  //     return {  
  //         ...prevState.usageFor, usageFor: used
  //     }
  //   })
  //   this.loadData(used);
  // }
  

  async loadData () {
    var [result1, result2] = await Promise.all([loadContent("library",this.state.keyword,this.state.pageShow), loadContent("course",this.state.keyword,this.state.pageShow)]);
    this.setState({
            data: {
              "library": result1,
              "course": result2
            }
        })
  }

  componentDidMount() {
    // var url = window.location.href;
    // var tabKey = url.substring(url.lastIndexOf('/') + 1);
    // this.setState({
    //   activelink: tabKey
    // });

    var currentURL = window.location.pathname;
    var arrPath = currentURL.split('/');
    //console.log(currentURL);
    // console.log(arrPath);
    // console.log(arrPath[2]);
    var usageFor = 'library'
    var defindex = "cat-1";
    if(arrPath[3] !== undefined && arrPath[3] !== "" && arrPath[3].toLowerCase() === "course") { defindex = "cat-2"; usageFor = "course"; }
    this.setState({
      defTabIndex: defindex,
      usageFor: usageFor,
      activelink: arrPath[2]
    })
    //console.log("did:"+usageFor);
    
    this.loadData(usageFor);

  }

  contentTabs(param) {
    var statedata = this.state.data[param];

    var stLabel = "Library"; if(param === 'course') { stLabel = "Workshop"; }
    var categoryList = [];
    if(statedata.values.length > 0 && statedata.status === '200') {
      for(var ls=0; ls < statedata.values.length; ls++) {
          var retResult = statedata.values[ls];
          categoryList.push(<tr key={retResult.docid}>
              <th scope="row-field" className="text-center">{ls+1}</th>
              <td>{retResult.content.categoryName}</td>
              <td>
                  <FaPen className="styleicons" aria-label="edit" onClick={this.urlformCat.bind(this, param,retResult.docid,'Edit')} />
                  <FaFileLines className="styleicons" aria-label="view" onClick={this.urlviewCat.bind(this, param,retResult.docid,'View')} />
                  <FaTrash className="styleicons" aria-label="delete" onClick={this.urldeleteCat.bind(this, this.state.usageFor, retResult.docid)} />
              </td>
            </tr>)
        }
    } else {
      categoryList.push(<tr key="1">
            <th scope="row"></th>
            <td className="d-flex p-3">- no data -</td>
            <td></td>
          </tr>)
    }
    
    return(<div>
              <div className="row show">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                      <Button variant="secondary" size="sm" onClick={this.urlformCat.bind(this, param.toLowerCase(),'','Add')}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />Add Categories {stLabel}</Button>
                  </div>
              </div>
              <div className="mt-3">
                  <table className="table">
                    <thead className="table-dark">
                      <tr key="0">
                      <th width="50" className="text-center" scope="col">#</th>
                      <th scope="col">Category</th>
                      <th width="150" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        {categoryList}
                    </tbody>
                  </table>
              </div>
           </div>
    )
    
  }  

  render() {
    return (
      <div className="setting">
       <Container>
      {
        (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
        (<>
          <div className="row py-5 mb-5">
            {/* === start - body */}
              <div className="col-md-3 bg-light">
                { 
                  this.state.activelink !== "none" && 
                    <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                
                }
              </div>
              <div className="col-md-9 p-4">
                <div className="row">
                  <div className="mb-4">
                    <h3>{GetText() ? "Kategori" : "Category"}</h3>
                    <span className="text-muted">{GetText() ? "Daftar Kategori" : "List of Category"}</span>
                  </div>
                  <div className="mt-3">
                    {
                    this.state.defTabIndex !== "" && 
                      <Tabs className="nav-scroll" defaultActiveKey={this.state.defTabIndex} variant="tabs" id="tab">
                        <Tab className="pt-4" eventKey="cat-1" title="Library's Category" key="1">
                          <div>
                          {this.contentTabs("library")}
                          </div>
                        </Tab>
                        <Tab className="pt-4" eventKey="cat-2" title="Workshop's Category" key="2">
                          {this.contentTabs("course")}
                        </Tab>

                      </Tabs>
                    }
                  </div>
                </div>
              </div>
            {/* === end - body */}
          </div>
          <div>
                {this.state.isOpen && <Popup
                  content={<><CTCategoryForm/></>}
                  title={this.state.stPopTitle}
                  others={this.state.otherContent}
                  styles={"50%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
                {this.state.isOpen2 && <Popup
                  content={<><CTCategoryDetail/></>}
                  title={this.state.stPopTitle}
                  others={this.state.otherContent}
                  styles={"70%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
          </div>
          </>
        )
      : 
        <div><CTNA/></div>
      }
      <div id="loading-area" className="d-none loading"></div> 
      </Container>
      </div>
    );
  }
}
