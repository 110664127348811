import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { GetText } from './config/helper';

export default class Error204 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refpage : ''
    }
  }
  componentDidMount() {
    if(document.referrer !== "") {
      this.setState({ 
        refpage: document.referrer 
      });
    }
  }
  render() {
    console.log("ref")
    console.log(document.referrer);
    return (
      <div className="error204">
        <Container>
          <h1 className="mb-4">{GetText() ? "Data tidak ditemukan" : "Content does not exist"}</h1>
          <p></p>
          <div className="mt-4">
            <p>{GetText() ? "Maaf, tampaknya alamat situs web yang Anda masukkan salah." : "We're sorry, but it appears the website address you entered was incorrect."}</p>
            {
              (this.state.refpage !== "") ? 
                <a href="#!" className="link-more" onClick={() => window.history.back()}>{GetText() ? "Kembali" : "Go back"} <FaArrowRight/></a> 
              : <a href="#!" className="link-more" onClick={() => window.location.href="/"}>{GetText() ? "Kembali ke beranda" : "Back to Home"} <FaArrowRight/></a> 
            }
          </div>
        </Container>
      </div>
    );
  }
}