import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { FaTrash, FaArrowUpRightFromSquare, FaCircleXmark } from 'react-icons/fa6';
import { GetText } from '../config/helper';
import NumberFormats from '../config/number-format';
import NAimage from '../img/pic-na.png';
import Loading from '../inc/Loading';
import '../config/global';
// import { FaTimesCircle } from "react-icons/fa";

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[10]+global.config.authBearerKey;

// const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
// const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[41];

export default class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      carts: [],
      currency: GetText() ? global.config.currency.name[0] : global.config.currency.name[1],
      total: 0,
      numItem: 0,
      modal_show: false,
      errMessage: ["",""],
      isLoad : false
    };
    // this.deleteItem = this.deleteItem.bind(this);
    // this.deleteAll = this.deleteAll.bind(this);
  }

  closeModal() {
    this.setState({
      modal_show: false
    });
  }
  deleteItem(itemId,amounts,r) {
    // var datatext ='{"docid":"' + this.state.memberId + '","subdocid":"' + itemId + '","numchild":0,"usageFor":"cart"}';
    var datatext ='{"docid":"' + this.state.memberId + '","subdocid":"' + itemId + '","form_id":"delete-cart"}';
    var data = JSON.parse(datatext);
    // console.log(data);
    // console.log(r);
    // carts: items,
    // total
    // var updateItems = this.state.carts.splice(r,1);
    // console.log("res")
    // console.log(items);
    // console.log(total);
    // console.log(amounts);
    // console.log(items.splice(0,1));
    // console.log(items.splice(0,1));
    // var items = 
    
    
    fetch(global.config.urlLink + "/deletecart", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2,
      }, body : JSON.stringify(data)
    }).then(result => {
      if(result.status === 200) 
        return result.json() 
    }).then(resultJson => {
      if(resultJson.status="200") {
        var updateNumItems = this.state.numItem-1;
        var updateAmount = this.state.total-amounts;
        
        var carts = document.getElementById('nav-cart').getElementsByTagName('span')[0];
        carts.classList.remove('empty');
        carts.innerHTML = carts.innerHTML > 0 ? (parseInt(carts.innerHTML) - 1) : 0;
        
        document.getElementById("cart-table").deleteRow(r+1);
        this.setState({
          numItem: updateNumItems,
          total: updateAmount,
        });
      } else {
        this.setState({
          modal_show: true,
          errMessage: ["Tidak berhasil dihapus. <br/> Silakan refresh halaman ini dan coba kembali","Failed to delete. <br/> Please reload this page and try again."]
        });
      }
      // window.location.reload();
    }).catch(err => {
      console.log(err);
    });
    
  }
/*
  deleteAll() {
    var datatext ='{"docid":"' + this.state.memberId + '","numchild":1,"usageFor":"cart"}';
    var data = JSON.parse(datatext);
    
    fetch(global.config.urlLink + "/deletecart", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2,
      }, body : JSON.stringify(data)
    }).then(result => {
      if(result.status === 200) 
        return result.json() 
    }).then(resultJson => {
      window.location.reload();
    }).catch(err => {
      console.log(err);
    });
    
  }
*/

  componentDidMount() {
    // fetch(global.config.urlLink+ "/traininguserview/" + this.state.memberId, {
    //   method: 'GET',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Authorization': authKey
    //   },
    // })
    var dataSend = { docid: this.state.memberId, types: "carts" }
		fetch(global.config.urlLink+"/traininguserview", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey
			}, 
			body : JSON.stringify(dataSend)
		}).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log("Something happened wrong.");
      }
    })
    .then(resultJson => {
      var carts = resultJson.values[0].scontent5;
      var total = 0;
      var items = [];
      if (carts) {
        for (var i = 0; i < carts.length; i++) {
          if (carts[i].subcontent.courseLanguage === localStorage.getItem('language')) {
            items.push(carts[i]);
            total += carts[i].subcontent.subtotal;
          }
        }
      }
      this.setState({
        carts: items,
        total: total,
        numItem: items.length,
        isLoad: true
      });
    });
  }

  render() {
    return (
      <div className="shopping-cart">
      {this.state.isLoad === true? 
        <>
      
        <Modal animation={false} show={this.state.modal_show} onHide={this.closeModal.bind(this)}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="row">
              <div className="col-md-2 text-center"><FaCircleXmark color="red" fontSize="50px"/></div>
              <div className="col-md-10">{GetText() ? parse(this.state.errMessage[0]) : parse(this.state.errMessage[1])}</div>
            </div>
          </Modal.Body>
        </Modal>
        <Container>
          <div className="row py-5">
            <div className="col-md-7">
              <div className="card card-pop">
                <div className="card-body p-4">
                  <div className="header">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-1 mb-0">{GetText() ? "Keranjang Belanja" : "Shopping Cart"}</h4>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  {
                    this.state.carts.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table mb-0" id="cart-table">
                          <thead className="card-header">
                            <tr>
                              <td className="border-0"><strong>{GetText() ? "Item" : "Item"}</strong></td>
                              <td className="text-end border-0"><strong>{GetText() ? "Harga" : "Amount"}</strong></td>
                              <td className="text-end border-0"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.carts
                              .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                              .map((cart, i) => {
                                return (
                                  <tr key={'item-' + i}>
                                    <td className="border-0">
                                      <div className="cart-item-info">
                                        <img src={cart.subcontent.coursePicture !== "" && cart.subcontent.coursePicture !== undefined? cart.subcontent.coursePicture : NAimage} alt={cart.subcontent.courseTitle} width={40}/>
                                        <p>
                                          {cart.subcontent.courseTitle}
                                          <a className="ms-2" href={'/course/' + cart.subcontent.courseId} target="_blank" rel="noreferrer"><FaArrowUpRightFromSquare/></a>
                                          <br/>
                                          <small><strong>{cart.subcontent.categoryName}</strong></small>
                                        </p>
                                      </div>
                                    </td>
                                    <td className="text-end border-0">{(cart.subcontent.courseCurrency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]) + NumberFormats(cart.subcontent.coursePrice)}</td>
                                    <td className="text-end border-0"><FaTrash onClick={this.deleteItem.bind(this, cart.subdocid, cart.subcontent.coursePrice, i)} style={{width:"15px"}} className="styleicons" /></td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                        {/* <Button className="d-block mx-auto bg-transparent" type="button" onClick={() => this.deleteAll()}>{GetText() ? "Bersihkan semua item" : "Clear all items"}</Button> */}
                      </div>
                    ) : (
                      <p>{GetText() ? "Belum ada item di Keranjang Belanja" : "No items in Shopping Cart"}</p>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-4 mt-md-0">
              <div className="bg-dark p-4">
                <Form className="form-payment">
                  <h4 className="text-white"><strong>{GetText() ? "Ringkasan Pesanan" : "Order Summary"}</strong></h4>
                  <div className="row mt-4">
                    <div className="col-6"><p className="text-white">{GetText() ? "Item" : "Items"}</p></div>
                    <div className="col-6 text-end"><p className="text-white"><strong>{this.state.numItem}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-6"><p className="text-white">{GetText() ? "Subtotal" : "Subtotal"}</p></div>
                    <div className="col-6 text-end"><p className="text-white"><strong>{(this.state.currency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]) + NumberFormats(this.state.total)}</strong></p></div>
                  </div>
                  <p className="my-4 text-white">{GetText() ? "Harap cek kembali pesanan Anda sebelum melakukan pembayaran." : "Please review your order before making payments."}</p>
                  <Button className="btn btn-lg btn-primary w-100" disabled={this.state.numItem > 0 ? false : true} onClick={() => window.location.href = '/checkout'}>{GetText() ? "Lanjut ke pembayaran" : "Continue to payment"}</Button>
                </Form>
              </div>
            </div>
          </div>
        </Container>
        </>
        : <Loading value={{id:'cart-area', type: 'screen'}}/> 
      }
      </div>
    );
  }
}