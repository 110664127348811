import React, { Component } from 'react';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import { FaPlus, FaPen, FaFileLines, FaToggleOn, FaToggleOff } from "react-icons/fa6";

// import EditIcon from '@mui/icons-material/Edit';
// import DescriptionIcon from '@mui/icons-material/Description';
// import AddIcon from '@mui/icons-material/Add';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

function loadContent(usedfor,keyword,pageshow) {
  return new Promise((resolve) => {
    //document.getElementById('loading-area').style.display = 'block'; 
    var langz = "English";
    if(usedfor === "indonesia") { langz = "Indonesian"; } 
    var datatext ='{"keyword":"'+keyword+'","pageShow":"'+pageshow+'","lang":"'+langz+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/librarylist", {
        method: 'POST',
        //mode: 'no-cors', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        // document.getElementById('loading-area').style.display = 'none'; 
        //resJson = '{status: 200, values: { userEmail: "melia@point-star.com",userFullname: "Melia",userId: "111773733087018575892",userLevel: "Admin",userName: "melia@point-star.com",userPassword: ""}}';
        // this.setState({
        //     data: resJson
        // })
        resolve(resJson);
    }).catch(err => {
        // document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
        resolve({"status": "500"});
        
    });
  });
}
export default class CTLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      pageShow: '10',
      keyword: '',
      data: {"ENG": [], "IDN": []},
      defTabIndex: "",
      usageFor: '',
    }
    // this.deleteData = this.deleteData.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  urladdLibrary(currpage,param) {
    // var path = "english";
    // if(param === "idn") { path = "indonesia"; }
    // window.location.href=global.config.urlCms+"/"+currpage+"/"+path+'/add';
    window.location.href=global.config.urlCms+"/"+currpage+'/add';
  }
  handlePublish(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block';
    // var datatext ='{"feedback_text":"'+content.feedbackText+'","feedback_number":"'+content.feedbackNumber+'","feedback_type":"'+content.feedbackType+'","feedback_status":"'+nextstat+'","option1":"'+content.option1+'","option2":"'+content.option2+'","option3":"'+content.option3+'","option4":"'+content.option4+'","option5":"'+content.option5+'","feedback_docid":"'+valId+'","form_id":"form-edit"}';
    var datatext ='{"library_docid":"'+valId+'","library_status":"'+nextstat+'","form_id":"form-publish"}';
    var urlFetch = global.config.urlLink+"/libraryedit";
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            // var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink;
        } else {
            console.log('Something happened wrong');
        }
        
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  async loadData () {
    document.getElementById('loading-area').style.display = 'block'; 
    var [result1, result2] = await Promise.all([loadContent("english",this.state.keyword,this.state.pageShow), loadContent("indonesia",this.state.keyword,this.state.pageShow)]);
    this.setState({
            data: {
              "ENG": result1,
              "IDN": result2
            }
        })
    if(result1) { document.getElementById('loading-area').style.display = 'none'; }
  }
  

  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="";
    if(arrUrl.length===3 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-1];
      tabKey2 = arrUrl[arrUrl.length];
    } else if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-2];
        tabKey2 = arrUrl[arrUrl.length-1];
    }
    
    // var url = window.location.href;
    // var arrUrl = url.split("/");
    // // var tabKey = url.substring(url.lastIndexOf('/') + 1);
    // var tabKey = arrUrl[arrUrl.length-1];
    // if(tabKey === "") {
    //   tabKey = arrUrl[arrUrl.length-2];
    // }

    var usageFor = 'english'
    var defindex = "tab-1";
    if(tabKey2 !== undefined && tabKey2 !== "" && tabKey2.toLowerCase() === "indonesia") { defindex = "tab-2"; usageFor = "indonesia"; }

    this.setState({
      activelink: tabKey,
      defTabIndex: defindex,
      usageFor: usageFor
    });
    this.loadData();
  }

  contentTabs(param) {
    var statedata = this.state.data[param];

    // var stLabel = "English"; if(param === 'IDN') { stLabel = "Indonesia"; }
    var libraryList = [];
    if(statedata.values.length > 0 && statedata.status === '200') {
      for(var ls=0; ls < statedata.values.length; ls++) {
          var retResult = statedata.values[ls];
          var editURL = global.config.urlCms+"/"+this.state.activelink+"/detailedit/"+retResult.docid;
          var viewURL = global.config.urlCms+"/"+this.state.activelink+"/view/"+retResult.docid;
          var iconPub = <FaToggleOff style={{color:"#999999"}} className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, global.config.libraryStatus[1])}/>;
          if(retResult.content.status === global.config.libraryStatus[1] ) { iconPub =  <FaToggleOn className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, global.config.libraryStatus[2])}/> }


          libraryList.push(<tr key={retResult.docid}>
              <th scope="row" className="text-center">{ls+1}</th>
              <td>{retResult.content.libraryTitle}</td>
              <td>{retResult.content.categoryName}</td>
              <td>{retResult.content.subcategoryName}</td>
              <td>
                <a href={editURL}><FaPen className="styleicons" title="edit" /></a>
                <a href={viewURL}><FaFileLines className="styleicons" title="view" /></a>
                {iconPub}
              </td>
            </tr>)
        }
    } else {
      libraryList.push(<tr key="1">
            <th scope="row"></th>
            <td className="d-flex p-3">- no data -</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>)
    }
    
    return(<div>
              <div className="row show">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                      <Button variant="secondary" size="sm" onClick={this.urladdLibrary.bind(this, this.state.activelink, param.toLowerCase(),'','Add')}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />Add Library</Button>
                  </div>
              </div>
              <div className="mt-3">
                  <table className="table">
                    <thead className="table-dark">
                      <tr key="0">
                      <th width="50" className="text-center" scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Category</th>
                      <th scope="col">SubCategory</th>
                      <th width="150" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        {libraryList}
                    </tbody>
                  </table>
              </div>
           </div>
    )
    
  }  

  render() {
    // console.log(this.state.activelink);
   
    return (
      <div className="setting">
       <Container>
        {
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    this.state.activelink !== "none" && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3>{GetText() ? "Library" : "Library"}</h3>
                      <span className="text-muted">{GetText() ? "Daftar Library" : "List of Libraries"}</span>
                    </div>
                    <div className="mt-3">
                    {
                      this.state.defTabIndex !== "" && 
                        <Tabs className="nav-scroll" defaultActiveKey={this.state.defTabIndex} variant="tabs" id="tab">
                          <Tab className="pt-4" eventKey="tab-1" title="English" key="1">
                            <div>
                            {this.contentTabs("ENG")}
                            </div>
                          </Tab>
                          <Tab className="pt-4" eventKey="tab-2" title="Indonesia" key="2">
                            {this.contentTabs("IDN")}
                          </Tab>

                      </Tabs>
                    }
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
