import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
// import '../ctinc/ct-Style.css';
import '../config/global';
import Popup from '../inc/PopUp';
import '../inc/PopUp.css'
import CTSubCategoryForm from './ct-categorysub-form';
import { FaPlus, FaTrash, FaPen } from "react-icons/fa6";

// import { FaAngleRight } from "react-icons/fa6";
// import { FaCircleXmark } from "react-icons/fa6";
// import { FaBell } from "react-icons/fa6";
// import { FaPlus } from "react-icons/fa6";
// import { FaTrash } from "react-icons/fa6";
// import { FaDownload } from "react-icons/fa6";
// import { FaPen } from "react-icons/fa6";        
// import { FaMagnifyingGlass } from "react-icons/fa6";
// import { FaPowerOff } from "react-icons/fa6";
// import { FaGear } from "react-icons/fa6";
// import { FaRegBell } from "react-icons/fa6";
// import { FaStar } from "react-icons/fa6";
// import { FaRegStar } from "react-icons/fa6";
// import { FaToggleOff } from "react-icons/fa6";
// import { FaToggleOn } from "react-icons/fa6";
// import { FaUser } from "react-icons/fa6";
// import { FaUserGroup } from "react-icons/fa6";
// import { FaFileLines } from "react-icons/fa6";
// import { FaCartShopping } from "react-icons/fa6";
// import { FaCheck } from "react-icons/fa6";

// import { FaChevronRight } from "react-icons/fa6";
// import { FaFilePdf } from "react-icons/fa6";
// import { FaFileVideo } from "react-icons/fa6";
// import { FaFileImage } from "react-icons/fa6";

// import { FaFolder } from "react-icons/fa6";
// import { FaRegFileImage } from "react-icons/fa6";
// import { FaRegFolder } from "react-icons/fa6";
// import { FaRegFilePdf } from "react-icons/fa6";
// import { FaRegFileVideo } from "react-icons/fa6";
// import { FaRegImage } from "react-icons/fa6";
// import { FaRegSquareCaretRight } from "react-icons/fa6";
// import { FaSistrix } from "react-icons/fa6";

const authKey = global.config.kindcode.training[3]+global.config.authBearerKey;

class CTCategoryDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            category_id: '',
            category_name: '',
            usage_for: '',
            subcategory_list: [],
            st3PopTitle: '',
            styleTb: 'table',
            isOpen3: false,
            otherContent3: ''
        }
        this.loadDetailData = this.loadDetailData.bind(this);
    }

    togglePopClose() {
        this.setState( prevState => {
          return {  
              ...prevState.isOpen3, isOpen3: false
          }
        })
    }  
    urlformSubCat(used,catid,subcatid,action) {
        var st3PopTitle = "";
        if(used === 'course') { st3PopTitle = action+" Sub Categories Workshop"; }
        else if(used === 'library') { st3PopTitle = action+" Sub Categories Library"; }
        this.setState( prevState => {
          return {  
              ...prevState.st3PopTitle, st3PopTitle: st3PopTitle,
              ...prevState.isOpen3, isOpen3: true,
              ...prevState.otherContent3, otherContent3 : '{"usageFor":"'+used.toLowerCase()+'","catId":"'+catid+'","categoryName":"'+this.state.category_name+'","subcatId":"'+subcatid+'"}'
          }
        })
    }
    urldeleteSubCat(used,catid,subcatid) {
        var stat = false;
        stat = window.confirm("Are you sure you want to delete this data?");
        if(stat === true) {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlString = global.config.urlLink+"/categorydelete";
            // var datatext ='{"docid":"'+catid+'","subdocid":"'+subcatid+'","numchild":0,"usageFor":"'+used.toLowerCase()+'","childlevel":1}';
            var datatext ='{"docid":"'+catid+'","subdocid":"'+subcatid+'","usageFor":"'+used.toLowerCase()+'","form_id":"delete-subcath"}';
            var data = JSON.parse(datatext); 
            fetch(urlString, {
                //method: 'DELETE',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
            //}).then(response => {
                // console.log("response");
                // console.log(response);
                document.getElementById('loading-area').style.display = 'none'; 
                // if(response.status === 200) {
                if(resJson.status === "200") {
                    this.loadDetailData();
                    //window.location.reload();
                    //window.location.href='/listevent';
                } else {
                    console.log('Something happened wrong delete');
                    //console.log(response);
                }
            }).catch(err => err);
        }
    }
    loadDetailData() {
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent3 = JSON.parse(document.getElementById("contentoth").innerText);
        if(othersContent3.catId !== '') {
            fetch(global.config.urlLink+"/categoryview/"+othersContent3.usageFor+"/"+othersContent3.catId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        category_id: resJson.values[0].docid,
                        category_name: resJson.values[0].content[0].maincontent.categoryName,
                        usage_for: othersContent3.usageFor,
                        subcategory_list: resJson.values[0].scontent,
                        styleTb: 'table',
                        isOpen: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            this.setState({
                category_id: othersContent3.catId,
                category_name: '',
                usage_for: othersContent3.usageFor,
                subcategory_list: [],
                styleTb: 'table',
                isOpen: false
            }); 
        }
    }
    componentDidMount() {
        this.loadDetailData();
    }

    render() {
        var subcategoryList = [];
        if(this.state.subcategory_list.length > 0) {
            for(var ls=0; ls < this.state.subcategory_list.length; ls++) {
                var retResult3 = this.state.subcategory_list[ls];
                var trcls = '';
                subcategoryList.push(<tr key={ls+1} className={trcls}>
                    <th scope="row">{ls+1}</th>
                    <td>{retResult3.subcontent.subcategoryName}</td>
                    <td>
                        <FaPen className="styleicons" title="edit" onClick={this.urlformSubCat.bind(this, this.state.usage_for,this.state.category_id,retResult3.subdocid,'Edit')}/>
                        <FaTrash className="styleicons" onClick={this.urldeleteSubCat.bind(this, this.state.usage_for, this.state.category_id, retResult3.subdocid)}/> 
                    </td>
                  </tr>)
            }
          } else {
            subcategoryList.push(<tr key="1">
                  <th scope="row"></th>
                  <td className="d-flex justify-content-center p-3">:: no record ::</td>
                  <td></td>
                </tr>)
              //memberList.push(<div className="mgT50 textCenter fontRed">:: no record ::</div>)
          }
        return (
            <div className="container">
                <div className="mt-3">
                    <div className="row d-flex">
                        <label className="col-md-2 textMiddle" htmlFor="category_name">Category Name </label>
                        <div className="col-md-10">{this.state.category_name} </div>
                    </div>
                    
                    <div className="mt-4" id="subcategory-area">
                        <div className="row ">
                            <div className=" col-md-6 "><h6>Sub Category</h6></div>
                            <div className=" col-md-6 d-flex justify-content-end ">
                            <Button variant="secondary" size="sm" onClick={this.urlformSubCat.bind(this, this.state.usage_for, this.state.category_id,'','Add')}><FaPlus style={{color: "#FFFFFF", size: "20px"}} className="me-2" />Add Sub Category</Button>
                            </div>
                        </div>
                        <div className="mt-3">
                            <table className="table">
                            <thead className="table-light">
                                <tr key="0">
                                <th scope="col" width="50" className="text-center">#</th>
                                <th scope="col">Sub Category</th>
                                <th scope="col" width="150">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subcategoryList}
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                        {this.state.isOpen3 && <Popup
                        content={<><CTSubCategoryForm/></>}
                        id={this.state.category_id} 
                        title={this.state.st3PopTitle}
                        others={this.state.otherContent3}
                        styles={"50%"}
                        handleClose={this.togglePopClose.bind(this)}
                        />}
                        
                </div>
                <div id="loading-area" className="d-none loading"></div> 
            </div>
          );
        }
     }
      
export default CTCategoryDetail;
