import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FormErrors } from '../inc/error-form';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

function urlgetTemplate() {
  window.open("https://docs.google.com/spreadsheets/d/1BCF1pEhdYVdN4dAQSKA6ka0fO2nnAqxwA0WP2euN4ZY/","_blank");
}
function insertFunction(libid,examid,content) {
  return new Promise(resolve => {
      var libquestionText, libquestionType, libquestionNumber, libquestionAnswer;
      var libquestionOption1, libquestionOption2, libquestionOption3, libquestionOption4, libquestionOption5;

      if(content.length > 0) {
          var sn=0;
          for(var a=0; a<content.length; a++) {
              var datacontent = content[a];
              libquestionText = datacontent[2];
              libquestionType = datacontent[1]; 
              libquestionNumber = (a+1);
              libquestionAnswer = datacontent[8];
              libquestionOption1 = datacontent[3];
              libquestionOption2 = datacontent[4];
              libquestionOption3 = datacontent[5];
              libquestionOption4 = datacontent[6];
              libquestionOption5 = datacontent[7];
              var datatext2 ='{"library_id":"'+libid+'","libexam_id":"'+examid+'","libquestion_text":"'+libquestionText+'","libquestion_type":"'+libquestionType+'","libquestion_number":"'+libquestionNumber+'","libquestion_answer":"'+libquestionAnswer+'","libquestion_option1":"'+libquestionOption1+'","libquestion_option2":"'+libquestionOption2+'","libquestion_option3":"'+libquestionOption3+'","libquestion_option4":"'+libquestionOption4+'","libquestion_option5":"'+libquestionOption5+'","form_id":"form-addquestion"}';
              var data2 = JSON.parse(datatext2);
              fetch(global.config.urlLink+"/libraryquestionadd", {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': authKey,
                  }, body : JSON.stringify(data2)
              }).then(res => {
                  if(res.status === 200) 
                      return res.json() 
                  // eslint-disable-next-line
              }).then( resJson => {
                  sn++;
                  if(sn===content.length) {
                      resolve("DONE");
                  }
              }).catch(err => err);
              // resolve("DONE");
          }
      } else { resolve("EMPTY"); }
  });
  
  

  
}; 

async function asyncCall(libid,examid,content) {
  const result = await insertFunction(libid,examid,content);
  //const result = "aaaaa";
  console.log('RESULT 2:');
  console.log(result);
  return result;
}

export default class CTLibraryQuestionImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library_id: '',
      library_title: '',
      libexam_id: '',
      libexam_title: '',
      libquestion_file: '',
      formErrors: { libquestion_file: ''},
      libquestionFileValid: false,
      formValid: false,
      input_disabled: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let libquestionFileValid = this.state.libquestionFileValid;
    
    switch(fieldName) {
        case 'libquestion_file': 
            libquestionFileValid = value.trim().length > 0;
            fieldValidationErrors.libquestion_text = libquestionFileValid ? '' : 'field need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
        libquestionFileValid: libquestionFileValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.libexamdescValid && this.state.libexampassscoreValid && this.state.libexamnumquestionValid && this.state.libexamstatusValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  handleSubmit(e) {
    e.preventDefault();
    if(this.state.libquestion_file !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var libquestionFile="";

        if(this.textInputLibquestionFile.value === '') { libquestionFile = this.state.libquestion_file; } else { libquestionFile = this.textInputLibquestionFile.value; }
            var arrLinkPath = libquestionFile.replace("https://","").split('/');
            var fileid = libquestionFile;
            if(arrLinkPath.length > 0) {
                fileid = arrLinkPath[3];
            }
            // var rangez = "Sheet1!A2:I";
            // var datatext = '{"fileid":"'+fileid+'","range":"'+rangez+'","fromset":"libquestion-import"}';
            // var urlFetch = global.ctconfig.urlLink+"/getssdata";
            
            // var datatext2 ='{"library_id":"'+this.state.library_id+'","libexam_id":"'+this.state.libexam_id+'","libquestion_file":"'+libquestionFile+'","form_id":"form-importquestion"}';
            // var data2 = JSON.parse(datatext2);
            
            var rangez = "Sheet1!A2:I";
            var datatext = '{"library_id":"'+this.state.library_id+'","libexam_id":"'+this.state.libexam_id+'","fileid":"'+fileid+'","range":"'+rangez+'","form_id":"form-libquestion-import"}';
            var urlFetch = global.config.urlLink+"/addbulk";
            var data = JSON.parse(datatext); 
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href=global.config.urlCms+"/ctlibrary/detailedit/"+this.state.library_id;

                    // console.log(resJson.values);
                    // var response = asyncCall(this.state.library_id,this.state.libexam_id,resJson.values);
                    // asyncCall(this.state.library_id,this.state.libexam_id,resJson.values)
                    // .then((response) => {
                    //     document.getElementById('loading-area').style.display = 'none'; 
                    //     console.log("response secFunction");
                    //     console.log(response);
                    //     window.location.href=global.config.urlCms+"/ctlibrary/detailedit/"+this.state.library_id;
                    // })
                    
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
            });
        
        
    } else {
        var arrfieldName = ['libquestion_file'];
        var arrvalue = [this.textInputLibquestionFile.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }


  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    document.getElementById('loading-area').style.display = 'block';
    this.setState({
        library_id: othersContent.libraryId,
        library_title: othersContent.libraryTitle,
        libexam_id: othersContent.examId,
        libexam_title: othersContent.examTitle,
        libquestion_file: '',
        formErrors: { libquestion_file: ''},
        libquestionFileValid: false,
        formValid: false,
        input_disabled: false
        
    });
    document.getElementById('loading-area').style.display = 'none'; 
    
  }

  render() {
    return (
        <div className="container mb-4">
          <div id="exam-detail-import">
            <form onSubmit={this.handleSubmit}>
              <div>
                  <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                  <input type="hidden" id="libexam_id" name="libexam_id" value={this.state.libexam_id} />
              </div>
              <div className="mt-3 d-flex">
                <div className="col-md-9"><h5>{this.state.library_title}</h5></div>
                <div className="col-md-3 d-flex justify-content-end"><Button variant="dark" size="sm" onClick={urlgetTemplate}>get a template</Button></div>
              </div>
              
              <div className="mt-4">
                  <div className="row mt-3">
                      <label className="col-md-3" htmlFor="libquestion_option1">Link Drive Url</label>
                      <div className="col-md-9">
                          <input type="text" className="form-control" name="libquestion_file"
                              placeholder={this.state.libquestion_file}
                              value={this.state.libquestion_file}
                              onChange={this.handleUserInput} 
                              ref={(input) => this.textInputLibquestionFile = input}
                              disabled={this.state.input_disabled}
                              required/>
                          <div className="mt-1">
                              { <FormErrors formErrors={this.state.formErrors.libquestion_file}/>  }
                          </div>
                          <div className="mt-2 fieldComments">
                          Set your sharing file to "Anyone on the internet with the link can view"
                          </div>
                      </div>
                  </div>
                  
                  <div className="row mt-4 mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                  </div>
                   
              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
