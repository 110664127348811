import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FaCircleXmark } from "react-icons/fa6";
// import CancelIcon from '@mui/icons-material/Cancel';
import { FormErrors } from '../inc/error-form';
import NAimage from '../img/pic-na.png';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;
// const authKey2 = global.config.kindcode.training[3]+global.config.authBearerKey;
const errmessage = ["please upload image only", "your uploaded file is more than 10Mb."];

export default class CTLibraryAttachEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library_id: '',
      library_title: '',
      library_lesson_id: '',
      library_lesson_name: '',
      library_attach_id: '',
      library_attach_title: '',
      file_url_thumb_curr: '',
      file_url_thumb_curr_name: '',
      file_url_thumb_next: '',
      selectedFileDocument: null,
      formErrors: { selectedFileDocument: '', library_attach_title: '' },
      selectedFileDocumentValid: false,
      file_titleValid: false,
      formValid: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let file_titleValid = this.state.file_titleValid;
    switch(fieldName) {

        
        case 'library_attach_title': 
            // if(this.state.libfile_title === "") {
                file_titleValid = value.trim().length > 0;
                fieldValidationErrors.library_attach_title = file_titleValid ? '' : 'title need to be filled';
            // }
            break;
        case 'selectedFileDocument': 
            selectedFileDocumentValid = value !== null;
            fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'file need to be selected';
            break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    selectedFileDocumentValid: selectedFileDocumentValid,
                    file_titleValid: file_titleValid,
                }, this.validateForm);
  }
  validateForm() {
      this.setState({formValid: this.state.selectedFileDocumentValid && this.state.file_titleValid  });
  }
  errorClass(error) {
      return(error.length === 0 ? '' : 'has-error');
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => { 
        this.validateField(name, value)
    });
  }
  onChangeFileHandler = (e) => {
      if(e.target.files[0] !== undefined) {
          var inputType = e.target.files[0].type;
          var inputValue = e.target.files[0];
          var name = ''; 
          var validname = '';
          let fieldValidationErrors = this.state.formErrors;
          let selectedFileValid = false;
          if(e.target.id === "fileDocument") {
              if(inputType.indexOf('image') > -1) { selectedFileValid = true; }
              else { selectedFileValid = false; }
              name =  'selectedFileDocument';
              validname = 'selectedFileDocumentValid';
              fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' :  errmessage[0];
          } 
          this.setState({
              [name]: inputValue,
              formErrors: fieldValidationErrors,
              [validname] : selectedFileValid
          }, this.validateForm);
      } else {
          this.setState({
              selectedFileDocument: null,
              formErrors: { selectedFileDocument: '' },
              selectedFileDocumentValid: false,
              formValid: false
          }); 
          
      }
  }
  submitData(datasend,title,imgurl,filename) {
    // var datatext = '{"library_docid":"'+this.state.library_id+'","library_lessonid":"'+this.state.library_lesson_id+'","library_attachid":"'+this.state.library_attach_id+'","filetitle":"'+title+'","library_attach_thumb_url":"'+imgurl+'","form_id":"form-edit-attach-thumb"}';
    // var data = JSON.parse(datatext); 
    var data = {
        "library_docid":this.state.library_id,
        "library_lessonid":this.state.library_lesson_id,
        "library_attachid":this.state.library_attach_id,
        "filetitle":title,
        "form_id":"form-edit-attach-thumb"
    }
    if(imgurl !== "" && imgurl !== undefined && imgurl !== null) { data.filethumb = imgurl; }
    if(filename !== "" && filename !== undefined) { data.filethumbname = filename; }

    fetch(global.config.urlLink+"/libraryattachmentedit", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            window.location.href=global.config.urlCms+"/ctlibrary/detailedit/"+this.state.library_id;
        } else {
            console.log('Something happened wrong');
            document.getElementById('error-page').style.display = 'block';
        }
      }).catch(err => err);
    // resolve(res.data.values.linkfile1);
  }
  handleSubmit(e) {
      e.preventDefault();
      var fileTitle="";
      if(this.textInputLibFileTitle.value === '') { fileTitle = this.state.library_attach_title; } else { fileTitle = this.textInputLibFileTitle.value; }     
      if(fileTitle !== '' && this.state.selectedFileDocument !== '' && this.state.selectedFileDocument !== null) {
         if(this.state.selectedFileDocument !== '' && this.state.selectedFileDocument !== null) {
            var selectedFileValue = this.state.selectedFileDocument;
            if(this.state.selectedFileDocument.type.indexOf('image') > -1) { 
              document.getElementById('loading-area').style.display = 'block'; 
              
              if (selectedFileValue.size < 10000000) {
                var inputType = selectedFileValue.type;
                var inputFormat =selectedFileValue.name.split('.').pop();
                var inputName = '';
                const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
                for (let i = 0; i < 12; i++) {
                    const index = Math.floor(Math.random() * chars.length);
                    inputName += chars.charAt(index);
                }
                var fileName = inputName + '.' + inputFormat;
                var datatext ='{"file_name":"' + fileName + '","file_type":"' + inputType + '","form_id":"attach-library","form_type":"buckets"}';
                // var datatext ='{"file_name":"' + e.target.files[0].name + '","file_type":"' + inputType + '","form_id":"attach-library","form_type":"buckets"}';
                var data = JSON.parse(datatext);
                fetch(global.config.urlLink+"/getsignedurl", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then(async (resJson) => {
                    if (resJson.status === "200") {
                        var urlUploadFile = resJson.values;
                        await fetch(urlUploadFile, {
                        method: "PUT",
                        headers: {
                            "content-type": "application/octet-stream",
                        },
                        body: selectedFileValue,
                        }).then(async (res) => {
                        let urlFile = res.url.split("?")[0];
                        await fetch(global.config.urlLink + "/makepublic", {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': authKey,
                            }, body : JSON.stringify(data),
                        }).then((res) => {
                            if (res.status === 200) {
                                this.submitData(this,fileTitle,urlFile,fileName);
                            }
                        });
                        });
                    } else {
                        this.setState({
                            selectedFileDocument: this.state.selectedFileDocument,
                            formErrors: { selectedFileDocument: errmessage[1] },
                            selectedFileDocumentValid : false,
                        }, this.validateForm);
                    }



                    // this.setState({
                    //     [name]: inputValue,
                    //     [selectedFile]: resJson.values,
                    //     [selectedName]: inputName + '.' + inputFormat,
                    //     formErrors: fieldValidationErrors,
                    //     [validname] : selectedFileValid,

                    // }, this.validateForm);
                    document.getElementById('loading-area').style.display = 'none'; 
                });


                /*
                  const data = new FormData() 
                  data.append('file', selectedFileValue);
                  data.append('docid', this.state.library_id);
                  data.append('subdocid', this.state.library_lesson_id);
                  data.append('subsubdocid', this.state.library_attach_id);
                  data.append('index', 1);
                  data.append('source', 'library-attach');
                  data.append('filetype',selectedFileValue.type);

                  axios.post(global.config.urlLink+"/uploadfile", 
                  data, { 
                      headers: { 
                          'content-type': 'multipart/form-data',
                          'Authorization': authKey
                      }
                  }).then(res => { 
                      console.log(res);
                      console.log(res.data.values);
                      console.log(res.data.values.linkfile1);
                      

                      // document.getElementById('loading-area').style.display = 'none'; 
                      if(res.data.status === "200") {
                        this.submitData(this,fileTitle,res.data.values.linkfile1);
                      } else {
                          // document.getElementById('loading-area').style.display = 'none'; 
                          console.log("Something happened wrong");
                          document.getElementById('error-page').style.display = 'block';
                      }
                  
                  }).catch(err => { 
                      document.getElementById('loading-area').style.display = 'none'; 
                      document.getElementById('error-page').style.display = 'block';
                      console.log(err); 
                  })
              */  
              } else {
                  document.getElementById('loading-area').style.display = 'none';
                  this.setState({
                      selectedFileDocument: this.state.selectedFileDocument,
                      formErrors: { selectedFileDocument: errmessage[1] },
                      selectedFileDocumentValid : false,
                  }, this.validateForm);
                  // document.getElementById('file-warning').innerHTML = 'Please upload .pdf document or .mp4 video max 10MB.<br/>File size is ' + Math.round(selectedFileValue.size / 1000000).toFixed(0) + 'MB. Please use shared link instead.';
              }
              
          } else {
              document.getElementById('loading-area').style.display = 'none'; 
              this.setState({
                  selectedFileDocument: this.state.selectedFileDocument,
                  formErrors: { selectedFileDocument: errmessage[0], library_attach_title: fileTitle },
                  selectedFileDocumentValid : false,
              }, this.validateForm);
          }
        } else {
            this.submitData(this,fileTitle,this.state.selectedFileDocument,this.state.file_url_thumb_curr_name);
        }
      } else {
        if(fileTitle !== '') {
            this.submitData(this,fileTitle,this.state.selectedFileDocument,this.state.file_url_thumb_curr_name);
        } else {
            var arrfieldName = ['library_attach_title'];
          var arrvalue = [this.textInputLibFileTitle.value,];
          for(var ck in arrfieldName) {
              this.validateField(arrfieldName[ck], arrvalue[ck]);
          }
        }
        //   var arrfieldName = ['selectedFileDocument','library_attach_title'];
        //   var arrvalue = [this.state.selectedFileDocument, this.textInputLibFileTitle.value,];
        //   for(var ck in arrfieldName) {
        //       this.validateField(arrfieldName[ck], arrvalue[ck]);
        //   }
      }
  }
  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    this.setState({
        library_id: othersContent.libraryId,
        library_title: othersContent.libraryTitle,
        library_lesson_id: othersContent.libraryLessonId,
        library_lesson_name: '',
        library_attach_id: othersContent.libraryAttachId,
        library_attach_title: othersContent.libraryAttachData.fileTitle,
        file_url_thumb_curr: othersContent.libraryAttachData.fileThumbnail !== ""? othersContent.libraryAttachData.fileThumbnail : NAimage,
        file_url_thumb_curr_name: othersContent.libraryAttachData.fileThumbnailName,
        file_url_thumb_next: '',
        selectedFileDocument: null,
        formErrors: { selectedFileDocument: '' },
        selectedFileDocumentValid: false,
        formValid: false
    }); 
    
  }

  render() {
    return (
        <div className="container mb-4">

          <div id="error-page" className="d-none" >
              <FormErrors formErrors={
                  <div className="d-flex justify-content-center">
                          <FaCircleXmark style={{width:"20px",marginTop:"5px", color: "#DC3545"}} />
                          <div className="fieldComments">Failed to upload.</div>
                  </div>}
              />
          </div>
          <div id="attach-detail-edit">
            <form onSubmit={this.handleSubmit}>
              <div>
                  <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                  <input type="hidden" id="library_attach_id" name="library_attach_id" value={this.state.library_attach_id} />
              </div>
              <div className="mt-3"><h5>{this.state.library_title}</h5></div>
              
              <div className="mt-4">
                    <div className="row mt-3">
                        <label className="col-md-3" htmlFor="library_document">Title</label>                            
                        <div className="col-md-9">
                            <input type="text" className="form-control" name="library_attach_title"
                                placeholder={this.state.library_attach_title}
                                value={this.state.library_attach_title}
                                onChange={this.handleUserInput} 
                                ref={(input) => this.textInputLibFileTitle = input}
                            />
                            <div className="mt-1">
                                { <FormErrors formErrors={this.state.formErrors.library_attach_title}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="row">
                            <label className="col-md-3" htmlFor="library_fileurl">Current Thumbnail</label>
                            <div className="col-md-9" ><img width="150" src={this.state.file_url_thumb_curr} alt=""/></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div>Upload Thumbnail</div>
                                <div className="notesText">(only for mp4 file)</div>
                            </div>
                            <div className="col-md-9">
                                    <input id="fileDocument" type="file"
                                        onChange={this.onChangeFileHandler}>
                                    </input> 
                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                    <div className="mt-2 fieldComments">
                                    Please upload file in jpg/jpeg format with 1280 px X 720 px (max 1MB).
                                    </div>
                            </div>
                        </div>
                    </div>  
                    <div className="row mt-4 mb-3">
                          <div className="col-md-3"></div>
                          <div className="col-md-9"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                    </div>

              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
