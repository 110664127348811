import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaFileLines } from "react-icons/fa6";
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import Popup from '../inc/PopUp';
import CTOrderDetail from './ct-payment-pop-detail';
// import CTSubscribeDetail from './ct-payment-pop-subscribe';
// import CTPurchaseDetail from './ct-payment-pop-purchase';

const authKey = global.config.kindcode.training[15]+global.config.authBearerKey;

export default class CTPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      pageShow: '10',
      keyword: '',
      status_payment: global.config.orderStatus[1],
      data: [],
      isOpen: false,
      otherContent: "",
      isOpen2: false,
      otherContent2: ""
    }
    this.paymentContent = this.paymentContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
        ...prevState.isOpen2, isOpen2: false,
      }
    })
  }
  // urldetailSubscribe(valId, memberId, name, email) {
  //   this.setState( prevState => {
  //     return {  
  //       ...prevState.isOpen, isOpen: true,
  //       ...prevState.otherContent, otherContent: '{"subscribe_id":"'+valId+'","member_id":"'+memberId+'","member_fullname":"'+name+'","member_email":"'+email+'"}'
  //     }
  //   })
  // }
  // urldetailPurchase(valId, memberId, name, email) {
  //   this.setState( prevState => {
  //     return {  
  //       ...prevState.isOpen2, isOpen2: true,
  //       ...prevState.otherContent2, otherContent2: '{"purchase_id":"'+valId+'","member_id":"'+memberId+'","member_fullname":"'+name+'","member_email":"'+email+'"}'
  //     }
  //   })
  // }

  urldetailSubscribe (docid,data) {
    data.docid = docid;
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: true,
        ...prevState.otherContent, otherContent: JSON.stringify(data)
      }
    })
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState({
      status_payment: value
    });
    //this.loadData(value);
  }

  
  // handleSubmit(e) {
  //   e.preventDefault();
  //   this.loadData(this.state.status_payment);
  // }

  paymentContent = (dataPayment,status) => {
    var paymentList = [];
    dataPayment
    .sort((a, b) => b.content.orderDate._seconds - a.content.orderDate._seconds)
    .filter(items => status === "" ||  items.content.orderStatus === status)
    .map((items, ls) => {
        paymentList.push(
          <tr key={ls+1}>
            <th scope="row">{ls+1}</th>
            <td>{DateTimeFormats(items.content.orderDate._seconds,"date-long")}</td>
            <td>{items.content.orderId}</td>
            <td>{items.content.paymentMethod} {items.content.paymentBank}</td>
            <td>{items.content.paymentCurrency} {NumberFormats(items.content.paymentAmount)}</td>
            <td>{items.content.orderStatus}</td>
            <td>
                  <FaFileLines className="styleicons" onClick={this.urldetailSubscribe.bind(this, items.docid, items.content)} />
            </td>
          </tr>
        );
    })
    if(paymentList.length === 0) {
        paymentList.push(
          <tr key="1">
            <td></td>
            <td></td>
            <td></td>
            <td className="d-flex p-3">- no data -</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
    }
    return paymentList;
  }
  loadData(currpage,current_status) {
    // this.setState({
    //   data: []
    // }, () => {
      document.getElementById('loading-area').style.display = 'block'; 
      var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","form_id":"list-order-col"}';
      var datasend = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/trainingorderlist", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(datasend)
      }).then(response => {
          if(response.status === 200) {
              return response.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson => {
        this.setState({
          activelink: currpage,
          data: resJson.values
        });
      }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
      });
    // });
  }

  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="";
    if(arrUrl.length===3 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-1];
      tabKey2 = arrUrl[arrUrl.length];
    } else if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-2];
        tabKey2 = arrUrl[arrUrl.length-1];
    }
    this.loadData(tabKey);
  }

 

  render() {
    // console.log(this.state.activelink);
   
    return (
      <div className="setting">
       <Container>
        {
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    this.state.activelink !== "none" && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3>{GetText() ? "Pembayaran" : "Payment"}</h3>
                      <span className="text-muted">{GetText() ? "Daftar Pembayaran" : "List of Payment"}</span>
                    </div>
                    <div className="mt-3">
                      <div className="row" >
                        <select name="status_payment" className="form-control" 
                          value={this.state.status_payment} 
                          onChange={this.handleUserSelect}
                          ref={(input) => this.textInputStatusPayment = input}
                        >
                          <option value="" key="0">-- All --</option>
                          {
                            global.config.orderStatus.map((datas,i) => 	
                              <option value={datas} key={i}>{datas}</option>
                            )
                          }
                          {/* <option value="New" key="0">New</option>
                          <option value="Verifying" key="1">Verifying</option>
                          <option value="Completed" key="2">Completed</option> */}
                        </select>
                      </div>
                      <div className="mt-3">
                        <table className="table">
                        <thead className="table-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Order Date</th>
                              <th scope="col">Order Code</th>
                              <th scope="col">Payment Method</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col" width="80">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.paymentContent(this.state.data,this.state.status_payment)}
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            <div>
              {
                this.state.isOpen && <Popup 
                  content={<CTOrderDetail/>}
                  title={GetText()? "Pembayaran" : "Payment"}
                  others={this.state.otherContent}
                  styles={"70%"}
                  handleClose={this.togglePopClose.bind(this)}
                />
              }
            </div>
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
