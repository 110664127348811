import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
// import DatePicker from 'react-date-picker';
import { FaPlus, FaTriangleExclamation } from "react-icons/fa6";
// import WarningIcon from '@mui/icons-material/Warning';
// import AddIcon from '@mui/icons-material/Add';
import { GetText } from '../config/helper';
import { FormErrors } from '../inc/error-form';
import DateTimeFormats from '../config/date-time-format';
import NumberFormats from '../config/number-format';
import NAimage from '../img/pic-na.png';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[11]+global.config.authBearerKey;

export default class CTPurchaseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stPopTitle: '',
      member_docid: '',
      member_fullname: '',
      member_email: '',
      sumorder_docid: '',
      order_id: '',
      order_docid: '',
      order_date: '',
      order_status: '',
      order_list: [],
      grand_total: 0,
      sub_total: 0,
      payment_status: '',
      payment_status_msg: ["",""],
      payment_amount: '',
      payment_method: '',
      payment_currency: '',
      payment_currency_symbol: '',
      payment_account: '',
      payment_info: '',
      payment_bank: '',
      payment_voucher_code: '',
      payment_voucher_amount: 0,
      
      payment_date: '',
      payment_date_verified: '',
      payment_receivedby_verified: '',
      payment_currency_verified: '',
      payment_currency_symbol_verified: '',
      payment_amount_verified: '',
      payment_remarks_verified: '',

      confirmation_date: '',
      confirmation_dateofpay: '',
      confirmation_remarks: '',
      confirmation_photo: null,

      payment_received_date: '',
      payment_received_price: '',
      payment_received_currency: global.config.currency.name[0],
      payment_received_comment: '',
      formErrors: {payment_received_date: '',payment_received_price: '', payment_received_currency: ''},
      paymentReceivedDateValid: false,
      paymentReceivedPriceValid: false,
      paymentReceivedCurrencyValid: false,
      formValid: false,
      errmsg: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadFormPurchase = this.loadFormPurchase.bind(this);
  }
  
  handlePaymentDate= (e) => {
    this.setState( prevState => {
        return {  
            ...prevState.payment_received_date, payment_received_date: e.target.value
        }
      }, () => { this.validateField('payment_received_date', e.target.value)
    }) 
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    this.setState({[inputname]: value},
        () => { this.validateField(inputname, value)});
  }
  handleUserInput = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      this.setState({[name]: value},
          () => { this.validateField(name, value)});
  } 
  urlcancelForm(divId,divId2) {
    document.getElementById(divId).style.display = "none";
    document.getElementById(divId2).style.display = "block";
  }
  urladdPayment(divId,divId2) {
    document.getElementById(divId).style.display = "none";
    document.getElementById(divId2).style.display = "block";
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let paymentReceivedDateValid = this.state.paymentReceivedDateValid;
    let paymentReceivedPriceValid = this.state.paymentReceivedPriceValid;
    let paymentReceivedCurrencyValid = this.state.paymentReceivedCurrencyValid;
    switch(fieldName) {
        case 'payment_received_date':
            if(this.state.payment_received_date === '') {
                paymentReceivedDateValid = value.trim().length > 0;
                fieldValidationErrors.payment_received_date = paymentReceivedDateValid ? '' : 'payment date received need to be filled';
            } else {
                paymentReceivedDateValid = true;
                fieldValidationErrors.payment_received_date = '';
            }
            break;
        case 'payment_received_price':
            if(this.state.payment_received_price === '') {
                paymentReceivedPriceValid = value.trim().length > 0;
                fieldValidationErrors.payment_received_price = paymentReceivedPriceValid ? '' : 'payment price received need to be filled';
            } else {
                paymentReceivedPriceValid = true;
                fieldValidationErrors.payment_received_price = '';
            }
            break;
        case 'payment_received_currency':
            if(this.state.payment_received_currency === '') {
                paymentReceivedCurrencyValid = value.trim().length > 0;
                fieldValidationErrors.payment_received_price = paymentReceivedCurrencyValid? '' : 'payment price received need to be filled';
            } else {
                paymentReceivedCurrencyValid = true;
                fieldValidationErrors.payment_received_currency = '';
            }
            break;
        default:
            break;
    }
    //alert(fieldName + ':' + JSON.stringify(fieldValidationErrors));
    
    this.setState({formErrors: fieldValidationErrors,
                    paymentReceivedDateValid: paymentReceivedDateValid,
                    paymentReceivedPriceValid: paymentReceivedPriceValid,
                    paymentReceivedCurrencyValid: paymentReceivedCurrencyValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.paymentReceivedDateValid && this.state.paymentReceivedPriceValid && this.state.paymentReceivedCurrencyValid});
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.payment_received_date !== "" && this.state.payment_received_price !== "" && this.state.payment_received_currency !== "") {
          document.getElementById('loading-area').style.display = 'block'; 
          var paymentReceivedPrice = this.state.payment_received_price;
          var paymentReceivedCurrency = this.state.payment_received_currency;
          var paymentReceivedComment = this.state.payment_received_comment; 
          if(this.textInputPaymentReceivedPrice.value !== '') { paymentReceivedPrice = this.textInputPaymentReceivedPrice.value; } 
          if(this.textInputPaymentReceivedCurrency.value !== '') { paymentReceivedCurrency = this.textInputPaymentReceivedCurrency.value; } 
          if(this.textInputPaymentReceivedComment.value !== '') { paymentReceivedComment = this.textInputPaymentReceivedComment.value; } 
          
          var datatext2 = '{"memberdoc_id":"' + this.state.member_docid + '","order_docid":"' + this.state.order_docid + '","sumorder_id":"' + this.state.sumorder_docid + '","received_date":"' + this.state.payment_received_date + '","received_price":"' + paymentReceivedPrice + '","received_currency":"' + paymentReceivedCurrency + '","verified_remarks":"' + paymentReceivedComment + '","form_id": "form-verified-payment"}';
          var data2 = JSON.parse(datatext2); 
          
          fetch(global.config.urlLink+"/trainingupdateorder", {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey2,
              }, body : JSON.stringify(data2)
          }).then(res => {
              if(res.status === 200) 
                  return res.json() 
          }).then( resJson => {
              document.getElementById('loading-area').style.display = 'none'; 
              if(resJson.status === '200') {
                  document.getElementById("form-verification-payment").style.display = "none";
                  document.getElementById("detail-payment").style.display = "block";
                  this.loadFormPurchase(this.state.member_docid,this.state.order_docid,this.state.sumorder_docid);
                  //window.location.href='/IViewLicence/'+this.state.clientdoc_id+'/'+this.state.licencedoc_id;
              } else {
                  console.log('Something happened wrong');
              }
          }).catch(err => err);
    } else {
      var arrfieldName = ['payment_received_date','payment_received_price'];
      var arrvalue = [this.state.payment_received_date,this.state.payment_received_price];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }
  loadFormPurchase(memberId,historyOrderId,sumOrderId) {
    var dataSend = { docid: memberId, subdocid: historyOrderId, types: 'orders', form_id: 'detail-user-order' }
    fetch(global.config.urlLink+"/traininguserorderview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log("Something happened wrong.");
      }
    })
    .then(resultJson => {
      if(resultJson.status === "200" && resultJson.values.length > 0) {
        var id = resultJson.values[0].docid;
        var member = resultJson.values[0].content[0].maincontent;
        var order = resultJson.values[0].scontent6[0];

        var paystatus_msg = ["",""];
        if(order.subcontent.orderStatus === global.config.orderStatus[3]) { paystatus_msg = ["DIBATALKAN","CANCELED"]; }
        else if(order.subcontent.orderStatus === global.config.orderStatus[4]) { paystatus_msg = ["EXPIRED","EXPIRED"]; }
        else if(order.subcontent.orderStatus === global.config.orderStatus[2]) { paystatus_msg = ["LUNAS","PAID"]; }
        else if(order.subcontent.orderStatus === global.config.orderStatus[1]) { paystatus_msg = ["MEMVERIFIKASI","VERIFYING"]; }
        else {
          if(order.subcontent.paymentConfirmationDate) { paystatus_msg = ["MEMVERIFIKASI","VERIFYING"]; } 
          else { paystatus_msg = ["MENUNGGU PEMBAYARAN", "PENDING PAYMENT"]; }
        }

        this.setState({
            member_docid: id,
            member_fullname: member.memberName,
            member_email: member.memberEmail,
            sumorder_docid: sumOrderId,
            order_id: order.subcontent.orderId,
            order_docid: order.subdocid,
            order_date: DateTimeFormats(order.subcontent.orderDate._seconds,"date-long"),
            order_status:  order.subcontent.orderStatus,
            order_list: order.sub2content,
            grand_total: order.subcontent.orderGrandTotal,
            sub_total: order.subcontent.orderSubtotal,
            payment_status: order.subcontent.paymentStatus,
            payment_status_msg: paystatus_msg,
            payment_amount: order.subcontent.paymentAmount,
            payment_method: order.subcontent.paymentMethod,
            payment_currency: order.subcontent.paymentCurrency,
            payment_currency_symbol: order.subcontent.paymentCurrency === global.config.currency.name[1]? global.config.currency.symbol[1] : global.config.currency.symbol[0],
            payment_account: order.subcontent.paymentAccount,
            payment_info: order.subcontent.paymentInfo,
            payment_bank: order.subcontent.paymentBank,
            payment_voucher_code: order.subcontent.voucherCode,
            payment_voucher_amount: order.subcontent.voucherAmount,

            payment_date: (order.subcontent.paymentVerificationDate)? DateTimeFormats(order.subcontent.paymentVerificationDate._seconds,"date-long") : '',
            payment_date_verified: (order.subcontent.paymentVerificationDateSubmit)? DateTimeFormats(order.subcontent.paymentVerificationDateSubmit._seconds,"datetime-short") : '',
            payment_receivedby_verified: order.subcontent.paymentVerificationByName,
            payment_currency_verified: order.subcontent.paymentVerificationCurrency,
            payment_currency_symbol_verified: order.subcontent.paymentVerificationCurrency === global.config.currency.name[1]? global.config.currency.symbol[1] : global.config.currency.symbol[0],
            payment_amount_verified: order.subcontent.paymentVerificationAmount,
            payment_remarks_verified: order.subcontent.paymentVerificationRemarks,

            confirmation_date: (order.subcontent.paymentConfirmationDateSubmit)? DateTimeFormats(order.subcontent.paymentConfirmationDateSubmit._seconds,"datetime-short") : '',
            confirmation_dateofpay: (order.subcontent.paymentConfirmationDate)? DateTimeFormats(order.subcontent.paymentConfirmationDate._seconds,"date-long") : '',
            confirmation_remarks: (order.subcontent.paymentConfirmationRemarks)? order.subcontent.paymentConfirmationRemarks : '',
            confirmation_photo: (order.subcontent.paymentConfirmationPhoto)? order.subcontent.paymentConfirmationPhoto : ''
        });
      } else {

      }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    
    });
    
    
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    document.getElementById("errormsg").style.display = 'none';
    document.getElementById("form-verification-payment").style.display = "none";
    document.getElementById("detail-payment").style.display = "block";
    
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    
    var memberId = othersContent.memberId;
    var historyOrderId = othersContent.orderHistoryId;
    var sumOrderId = othersContent.docid;
    this.loadFormPurchase(memberId,historyOrderId,sumOrderId);
  }

  render() {
    return (
        <div className="container mb-4">
          <div id="order-detail" >
              <div className="d-none mt-3" id="errormsg"><FaTriangleExclamation severity="error" />{this.state.errmsg} </div>
              <div className="mt-4">
                <div className="row">
                  <div className="col-6 col-md-4">{GetText() ? "Tanggal Pemesanan" : "Order Date"}</div>
                  <div className="col-6 col-md-8 text-end text-md-start">{this.state.order_date}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6 col-md-4">{GetText() ? "Kode Pemesanan" : "Order Code"}</div>
                  <div className="col-6 col-md-8 text-end text-md-start">{this.state.order_id}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6 col-md-4">{GetText() ? "Total Pemesanan" : "Total Order"}</div>
                  <div className="col-6 col-md-8 text-end text-md-start fw-bold">{this.state.payment_currency_symbol} {NumberFormats(this.state.payment_amount)}</div>
                </div>
                
                <div className="row mt-3">
                  <div className="col-6 col-md-4">{GetText() ? "Tagihan Kepada" : "Invoiced To"}</div>
                  <div className="col-6 col-md-8 text-end text-md-start">{this.state.member_fullname}<br/>{this.state.member_email}</div>
                </div>
                
              </div>
              <div className="mt-4 border-bottom">
                  <div className="row">
                      <div className="col-md-4 fw-bold "><span className="d-flex align-middle mt-2">Detail Information</span></div>
                      <div className="col-md-8 d-flex justify-content-end">
                      {(this.state.order_status === global.config.orderStatus[0] || this.state.order_status === global.config.orderStatus[1])?
                          <Button variant="link" className="me-2 linkText" style={{ textDecoration: 'none' }} onClick={this.urladdPayment.bind(this, "detail-payment", "form-verification-payment")}><FaPlus className="styleicons" style={{fontSize:"15pt", marginRight:"5px"}} /><span style={{marginTop:"5px"}}>Verified the Payment &raquo;</span></Button>
                          : ''
                      }    
                      </div>
                      
                  </div>
              </div>
              <div id="verified-payment" className="mt-4" >
                      <form id="form-verification-payment" onSubmit={this.handleSubmit}>
                        <div className="smallForm">
                            <div className="row">
                                <div className="col-md-4"><label className="mgT10" htmlFor="payment_received_date">Payment Received On</label></div>
                                <div className="col-md-8">
                                  <input className="form-control" type="date" name="payment_received_date" onChange={this.handlePaymentDate}/>

                                    {/* <DatePicker
                                        onChange={this.handlePaymentDate}
                                        value={this.state.payment_received_date}
                                        customStyles={{dateInput:{borderWidth: 0}}}
                                        /> */}
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.payment_received_date}/>  }</div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-4"><label htmlFor="payment_received_currency">Payment Received</label></div>
                                <div className="col-md-8 d-flex">
                                    <div className="me-2">
                                        <select name="payment_received_currency" className="form-control" 
                                            value={this.state.payment_received_currency} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputPaymentReceivedCurrency = input}
                                        >
                                            {
                                              global.config.currency.name.map((datas,i) => 	
                                                <option value={datas} key={i}>{datas}</option>
                                              )
                                            }
                                        </select> 
                                    </div>
                                    <div>
                                        <input type="number" className="form-control" name="payment_received_price"
                                            placeholder={this.state.payment_received_price}
                                            value={this.state.payment_received_price}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputPaymentReceivedPrice = input}/> 
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.payment_received_price}/>  }</div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="mt-3">Remarks</div>
                            <div className="mt-1">
                                <textarea name="payment_received_comment" className="form-control" 
                                    rows={4} cols={30} 
                                    /*placeholder={this.state.event_name}*/
                                    value={this.state.payment_received_comment} 
                                    onChange={this.handleUserInput} 
                                    ref={(input) => this.textInputPaymentReceivedComment = input}
                                />
                                
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.payment_received_comment}/>  }</div>
                            </div>
                            <div className="mt-3 d-flex justify-content-center">
                                <div className="d-inline-flex">
                                <Button type="submit" variant="secondary">Submit</Button>
                                <Button variant="secondary" className="ms-2" onClick={this.urlcancelForm.bind(this, "form-verification-payment","detail-payment")}>Cancel</Button>
                                </div>
                            </div>

                        </div>
                    </form>
              </div>
              <div id="detail-payment" className="mt-4" >
                <div>
                  <div className="row mt-2">
                    <div className="col-md-4">{GetText() ? "Status Pemesanan" : "Order Status"}</div>
                    {/* <div className="col-6 col-md-8 text-end text-md-start">{GetText() ? this.state.payment_status_msg[0] : this.state.payment_status_msg[1]}</div> */}
                    <div className="col-md-8 text-end text-md-start">{this.state.order_status}</div>
                  </div>
                  
                  <div className="row mt-2">
                    <div className="col-md-4">{GetText() ? "Metode Pembayaran" : "Payment Method"}</div>
                    <div className="col-md-8 text-end text-md-start">
                      {this.state.payment_method}
                      {(this.state.payment_method === global.config.paymentMethod.IDN[1].value || this.state.payment_method === global.config.paymentMethod.IDN[2].value)?
                        <span><br/>{this.state.payment_bank} (acc. {this.state.payment_account})</span> : <></>
                      }
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-12 fw-bold">{GetText() ? "Informasi Pembayaran" : "Payment Information"}</div>
                  </div> */}
                  <div className="row mt-3">
                    {this.state.confirmation_date && <div className="col-6 fw-bold">{GetText() ? "Konfirmasi Pembayaran " : "Confirm Payment"}</div> }
                    {this.state.payment_date_verified && <div className="col-6 fw-bold">{GetText() ? "Verifikasi Pembayaran" : "Verified Payment"}</div> }
                  </div> 
                  <div className="row mt-2">
                  {this.state.confirmation_date && 
                    <div className="col-6 ">
                      {/* <span className="fw-bold">{GetText() ? "Konfirmasi Pembayaran " : "Confirm Payment"}<br/></span> */}
                      {GetText() ? "Pembayaran dikonfirmasikan" : "The Payment has been confirmed"}<br/>
                      {GetText() ? "Pada tanggal " : "On "} : {this.state.confirmation_date}<br/>
                      {GetText() ? "Dibayar pada " : "Paid on "} : {this.state.confirmation_dateofpay}<br/>
                      {
                        (this.state.confirmation_remarks !== "" && this.state.confirmation_remarks !== undefined) ? 
                          <p className="mt-2">{(GetText() ? "Catatan : " : "Remarks : ")} <br/> {this.state.confirmation_remarks}</p>
                        : <></>
                      }
                      {
                        (this.state.confirmation_photo !== "" && this.state.confirmation_photo !== undefined) ? 
                          <a href={this.state.confirmation_photo}>click here &raquo;</a>
                        : <></>
                      }
                      
                    </div>
                  }
                  { this.state.payment_date_verified && 
                    <div className="col-6 ">
                      {/* <span className="fw-bold">{GetText() ? "Verifikasi Pembayaran " : "Verified Payment"}<br/></span> */}
                      {GetText() ? "Pembayaran diverifikasi" : "The Payment has been verified"}<br/>
                      {GetText() ? "Pada tanggal " : "On "} : {this.state.payment_date_verified}<br/>
                      {GetText() ? "Oleh " : " By "} : {this.state.payment_receivedby_verified}<br/>
                      <p className="mt-2">
                        {GetText() ? "Diterima pada " : "Received on "} : {this.state.payment_date}<br/>
                        {GetText() ? "Sebesar " : "With amount "} : {this.state.payment_currency_symbol_verified} {this.state.payment_amount_verified? NumberFormats(this.state.payment_amount_verified) : ''}<br/>
                      {
                        (this.state.payment_remarks_verified !== "" && this.state.payment_remarks_verified !== undefined) ? 
                          <span>{(GetText() ? "Catatan : " : "Remarks : ")} <br/> {this.state.payment_remarks_verified}</span>
                        : <></>
                      }
                      </p>
                      
                    </div>
                  }
                  </div>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table mb-0">
                    <thead className="table-dark">
                      <tr>
                        <td className="border-0"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></td>
                        <td className="text-center border-0" width="80"><strong>{GetText() ? "Jumlah" : "Qty"}</strong></td>
                        <td className="text-end border-0" width="150"><strong>{GetText() ? "Harga" : "Amount"}</strong></td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (this.state.order_list.length > 0)?
                          this.state.order_list.map((course, c) =>{
                            return (
                              <tr key={c}>
                                <td className="text-start border-1">{(course.subsubcontent.courseTitle)?  GetText() ? 'Bergabung di Workshop dengan topik ' : 'Join workshop with topic ' : ''} "{course.subsubcontent.courseTitle}"</td>
                                <td className="text-center border-1">{course.subsubcontent.quantity}</td>
                                <td className="text-end border-1">{(course.subsubcontent.courseCurrency === global.config.currency.name[1])? global.config.currency.symbol[1] : global.config.currency.symbol[0] + NumberFormats(course.subsubcontent.coursePrice)}</td>
                              </tr>
                            )
                          })
                        : 
                          <tr key="1">
                            <td className="text-end border-0">- no data - </td>
                            <td className="text-center border-0"></td>
                            <td className="text-end border-0"></td>
                          </tr>
                      }
                      {(this.state.sub_total !== this.state.grand_total)? 
                      <>
                      <tr key="-1">
                        <td className="text-end border-bottom-0 border-end-0 border-start-0 border-1"><strong>Subtotal</strong></td>
                        <td className="text-end border-bottom-0 border-end-0 border-start-0 border-1"><strong>{NumberFormats(this.state.sub_total)}</strong></td>
                      </tr>
                      <tr key="-2">
                        <td className="text-end border-0"><strong>Voucher</strong> {this.state.payment_voucher_code !== "" && <span>({this.state.payment_voucher_code})</span> }</td>
                        <td className="text-end border-0"><strong>{NumberFormats(this.state.voucher_amount)}</strong></td>
                      </tr>
                      </>
                      : <></>
                      }
                      
                    </tbody>
                    {(this.state.grand_total > 0)? 
                      <tfoot className="card-footer">
                        <tr className="border-1">
                          <td colSpan="2" className="text-end"><strong>Total</strong></td>
                          <td className="text-end border-1"><strong>{this.state.payment_currency_symbol + NumberFormats(this.state.grand_total)}</strong></td>
                        </tr>
                      </tfoot>
                    : <></>
                    }
                  </table>
                </div>
              </div>
              
          </div>

          {/* ========== form verifikasi data disini =========  */}

          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
