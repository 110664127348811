import React, { Component } from 'react';
import { FaRegFolder } from 'react-icons/fa';
import NAimage from '../../img/pic-na.png';

export default class Purchased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      date: '',
      image: '',
      link: '',
      memberStatus: localStorage.getItem('status')
    }
  }

  componentDidMount() {
    var content = this.props.value;
    // var catName="",libraryPic="";
    // if(content.categoryName !== undefined) { catName = content.categoryName; }
    // if(content.libraryPicture !== undefined) { libraryPic = content.libraryPicture; }
    
    this.setState({
      id: content.libraryId,
      title: content.libraryTitle,
      category: (content.libraryCategory !== "" && content.libraryCategory !== undefined)? content.libraryCategory : "",
      image: (content.libraryPicture !== "" && content.libraryPicture !== undefined)? content.libraryPicture : NAimage,
      link: '/library/'+content.libraryId
      // link: (this.state.memberStatus === 'Member' ? '/enroll/' : '/library/') + content.id
    });
  }

  render() { 
    return (
			<a href={this.state.link}>
        <div className="library-list">
          <div className="row">
            <div className="col-md-3">
              <img className="mxw br-8" src={this.state.image} alt={this.state.title} style={{maxWidth:"120px",maxHeight:"110px"}} />
            </div>
            <div className="col-md-9">
              <h5 className="list-title">{this.state.title}</h5>
              <p>
              {this.state.category !== ""? 
                <span className="me-4">
                  <FaRegFolder className="me-1"/>
                  {this.state.category}
                </span>
                : ''
              }
              </p>
            </div>
          </div>
        </div>
      </a>
      )
    }
  }
/*
  render() {
    return (
      <a href={this.state.link}>
        <div className="library-list">
          <div className="row">
          {this.state.image !== ""? 
            <div className="col-md-3">
              <img className="w-100" src={this.state.image} alt={this.state.title}/>
            </div>
            : ''
          }
            <div className="col-md-9">
              <h5 className="list-title">{this.state.title}</h5>
              <p>
              {this.state.category !== ""? 
                <span className="me-4">
                  <FaRegFolder className="me-1"/>
                  {this.state.category}
                </span>
                : ''
              }
              </p>
            </div>
          </div>
        </div>
      </a>
    )
  }
}
*/