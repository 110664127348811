import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FormErrors } from '../inc/error-form';

const authKey = global.config.kindcode.training[2]+global.config.authBearerKey;

export default class CTCourseAgendaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        course_id: '',
        course_title: '',
        course_date: '',
        crsagenda_id: '',
        crsagenda_time_hour: 8,
        crsagenda_time_minute: 0,
        crsagenda_duration: 0,
        crsagenda_subject: '',
        crsagenda_description: '',
        formErrors: { crsagenda_time_hour: '', crsagenda_time_minute: '', crsagenda_duration: '', crsagenda_subject: '', crsagenda_description: '' },
        crsagendatimehourValid: false,
        crsagendatimeminuteValid: false,
        crsagendadurationValid: false,
        crsagendasubjectValid: false,
        crsagendadescriptionValid: false,
        formValid: false,
        input_disabled: true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }

  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    this.setState(prevState => {
        return {  
            ...prevState[inputname], [inputname]: value
        }
    }, () => { 
        this.validateField(inputname, value)
    });
    /*
    if(inputname === 'crsagenda_time_hour') {
      this.setState( prevState => {
          return {  
              ...prevState.crsagenda_time_hour, crsagenda_time_hour: value
          }
        }, () => { this.validateField(inputname, value)
      }) 
    } else if(inputname === 'crsagenda_time_minute') {
        this.setState( prevState => {
            return {  
                ...prevState.crsagenda_time_minute, crsagenda_time_minute: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else {
        this.setState( prevState => {
            return {
                ...prevState.crsagenda_time_hour, crsagenda_time_hour: value,
                ...prevState.crsagenda_time_minute, crsagenda_time_minute: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    }
    */
  }
  validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let crsagendatimehourValid = this.state.crsagendatimehourValid;
      let crsagendatimeminuteValid = this.state.crsagendatimeminuteValid;
      let crsagendadurationValid = this.state.crsagendadurationValid;
      let crsagendasubjectValid = this.state.crsagendasubjectValid;
      let crsagendadescriptionValid = this.state.crsagendadescriptionValid;
      
      switch(fieldName) {                
          case 'crsagenda_time_hour': 
          case 'crsagenda_time_minute': 
              crsagendatimehourValid = value.trim().length > 0;
              crsagendatimeminuteValid = value.trim().length > 0;
              fieldValidationErrors.crsagenda_time = (crsagendatimehourValid || crsagendatimeminuteValid) ? '' : 'start time event need to be filled';
              break;
          case 'crsagenda_duration': 
              crsagendadurationValid = value.trim().length > 0;
              fieldValidationErrors.crsagenda_duration = crsagendadurationValid ? '' : 'duration need to be filled';
              break;
          case 'crsagenda_subject': 
              crsagendasubjectValid = value.trim().length > 0;
              fieldValidationErrors.crsagenda_subject = crsagendasubjectValid ? '' : 'subject need to be filled';
              break;
          case 'crsagenda_description': 
              crsagendadescriptionValid = value.trim().length > 0;
              fieldValidationErrors.crsagenda_description = crsagendadescriptionValid ? '' : 'description need to be filled';
              break;
          default:
              break;
      }
      
      this.setState({
          formErrors: fieldValidationErrors,
          crsagendatimehourValid: crsagendatimehourValid,
          crsagendatimeminuteValid: crsagendatimeminuteValid,
          crsagendadurationValid: crsagendadurationValid,
          crsagendasubjectValid: crsagendasubjectValid,
          crsagendadescriptionValid: crsagendadescriptionValid,
      }, this.validateForm);
  }
  validateForm() {
      this.setState({formValid: this.state.crsagendatimehourValid && this.state.crsagendatimeminuteValid && this.state.crsagendadurationValid && this.state.crsagendasubjectValid && this.state.crsagendadescriptionValid});
  }

  errorClass(error) {
      return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
      e.preventDefault();
      if(this.state.crsagenda_time_hour !== '' && this.state.crsagenda_time_minute !== '' && this.state.crsagenda_duration !== '' && this.state.crsagenda_subject !== '' && this.state.crsagenda_description !== '') {
          document.getElementById('loading-area').style.display = 'block'; 
          var crsagendaTimeHour, crsagendaTimeMinute, crsagendaDuration, crsagendaSubject, crsagendaDescription;
          if(this.textInputcrsagendaTimeHour.value === '') { crsagendaTimeHour = this.state.crsagenda_time_hour; } else { crsagendaTimeHour = this.textInputcrsagendaTimeHour.value; }
          if(this.textInputcrsagendaTimeMinute.value === '') { crsagendaTimeMinute = this.state.crsagenda_time_minute; } else { crsagendaTimeMinute = this.textInputcrsagendaTimeMinute.value; }
          if(this.textInputcrsagendaDuration.value === '') { crsagendaDuration = this.state.crsagenda_duration; } else { crsagendaDuration = this.textInputcrsagendaDuration.value; }
          if(this.textInputcrsagendaSubject.value === '') { crsagendaSubject = this.state.crsagenda_subject; } else { crsagendaSubject = this.textInputcrsagendaSubject.value; }
          if(this.textInputcrsagendaDescription.value === '') { crsagendaDescription = this.state.crsagenda_description; } else { crsagendaDescription = this.textInputcrsagendaDescription.value; }
          
          var datatext = '';
          var urlFetch = global.config.urlLink;
          if(this.state.crsagenda_id !== '') { 
              datatext = '{"course_id":"'+this.state.course_id+'","course_date":"'+this.state.course_date+'","crsagenda_id":"'+this.state.crsagenda_id+'","crsagenda_time_h":"'+crsagendaTimeHour+'","crsagenda_time_m":"'+crsagendaTimeMinute+'","crsagenda_duration":"'+crsagendaDuration+'","crsagenda_subject":'+JSON.stringify(crsagendaSubject)+',"crsagenda_description":'+JSON.stringify(crsagendaDescription)+',"form_id":"form-editagenda"}';
              urlFetch = urlFetch+"/courseagendaedit"; 
          }
          else {                 
              datatext = '{"course_id":"'+this.state.course_id+'","course_date":"'+this.state.course_date+'","crsagenda_id":"'+this.state.crsagenda_id+'","crsagenda_time_h":"'+crsagendaTimeHour+'","crsagenda_time_m":"'+crsagendaTimeMinute+'","crsagenda_duration":"'+crsagendaDuration+'","crsagenda_subject":'+JSON.stringify(crsagendaSubject)+',"crsagenda_description":'+JSON.stringify(crsagendaDescription)+',"form_id":"form-addagenda"}';
              urlFetch = urlFetch+"/courseagendaadd";
          }
          var data = JSON.parse(datatext);
          fetch(urlFetch, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey,
              }, body : JSON.stringify(data)
          }).then(res => {
              if(res.status === 200) 
                  return res.json() 
          }).then( resJson => {
              document.getElementById('loading-area').style.display = 'none'; 
              if(resJson.status === '200') {
                  window.location.href=global.config.urlCms+"/ctcourse/detailedit/"+this.state.course_id
                //   window.location.href=global.config.urlHost+'/cteditdetailcourse/'+this.state.course_id;
              } else {
                  console.log('Something happened wrong');
              }
          }).catch(err => {
              document.getElementById('loading-area').style.display = 'none'; 
          });
      } else {
          var arrfieldName = ['crsagenda_time_hour', 'crsagenda_time_minute', 'crsagenda_duration', 'crsagenda_subject','crsagenda_description'];
          var arrvalue = [this.textInputcrsagendaTimeHour.value, this.textInputcrsagendaTimeMinute.value, this.textInputcrsagendaDuration.value, this.textInputcrsagendaSubject.value, this.textInputcrsagendaDescription.value];
          for(var ck in arrfieldName) {
              this.validateField(arrfieldName[ck], arrvalue[ck]);
          }
      }
  }

  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    console.log("othersContent");
    console.log(othersContent);
    if(othersContent.crsagendaid !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        fetch(global.config.urlLink+"/courseagendaview/"+othersContent.courseId+"/"+othersContent.crsagendaid, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            console.log(resJson);
            console.log(resJson.status);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') { 
                var agendatime = resJson.values[0].scontent[0].subcontent.agendaTime;
                var crsagendaTimeHour = 8;
                var crsagendaTimeMinute = 0;
                if(agendatime !== undefined) { 
                    var crsagendaTime = new Date(agendatime._seconds*1000);
                    crsagendaTimeHour = crsagendaTime.getHours();
                    crsagendaTimeMinute = crsagendaTime.getMinutes();
                }  
                this.setState({
                    course_id: resJson.values[0].docid,
                    course_title: resJson.values[0].content[0].maincontent.courseTitle,
                    course_date: resJson.values[0].content[0].maincontent.courseDate._seconds*1000,
                    crsagenda_id: resJson.values[0].scontent[0].subdocid,
                    crsagenda_time_hour: crsagendaTimeHour,
                    crsagenda_time_minute: crsagendaTimeMinute,
                    crsagenda_duration: resJson.values[0].scontent[0].subcontent.agendaDuration,
                    crsagenda_subject: resJson.values[0].scontent[0].subcontent.agendaTitle,
                    crsagenda_description: resJson.values[0].scontent[0].subcontent.agendaDesc,
                    formErrors: { crsagenda_time: '', crsagenda_duration: '', crsagenda_subject: '', crsagenda_description: '' },
                    crsagendatimeValid: false,
                    crsagendadurationValid: false,
                    crsagendasubjectValid: false,
                    crsagendadescriptionValid: false,
                    formValid: false,
                    input_disabled: false
                }); 
            } else {
                console.log('Something happened wrong');
                console.log(resJson);
            }
          }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
          });
    } else {
        this.setState({
            course_id: othersContent.courseId,
            course_title: othersContent.courseTitle,
            course_date: othersContent.courseDate,
            crsagenda_id: othersContent.crsagendaid,
            crsagenda_time_hour: 8,
            crsagenda_time_minute: 0,
            crsagenda_duration: '',
            crsagenda_subject: '',
            crsagenda_description: '',
            formErrors: { crsagenda_time: '', crsagenda_duration: '', crsagenda_subject: '', crsagenda_description: '' },
            crsagendatimeValid: false,
            crsagendadurationValid: false,
            crsagendasubjectValid: false,
            crsagendadescriptionValid: false,
            formValid: false,
            input_disabled: false
        }); 
    }
  }

  render() {
    var hourList = [];
    for(var h=0; h<24; h++) {
        hourList.push(<option value={h} key={h}>{h.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}</option>)
    }
    var minuteList = [];
    for(var m=0; m<60; m+=15) {
        minuteList.push(<option value={m} key={m}>{m.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}</option>)
    }
    return (
        <div className="container mb-4">
          <div id="lesson-detail-form">
            <form onSubmit={this.handleSubmit}>
              <div>
                  <input type="hidden" id="course_id" name="course_id" value={this.state.course_id} />
                  <input type="hidden" id="crsagenda_id" name="crsagenda_id" value={this.state.crsagenda_id} />
              </div>
              <div className="mt-3"><h5>{this.state.course_title}</h5></div>
              
              <div className="mt-4">
                <div className="row mt-3">
                    <label className="col-md-3" htmlFor="crsagenda_time">Start Time</label>
                    <div className="col-md-9">
                        <div className="d-flex">
                            <select name="crsagenda_time_hour" className="form-control" style={{width:"70px"}}
                                value={this.state.crsagenda_time_hour} 
                                onChange={this.handleUserSelect}
                                ref={(input) => this.textInputcrsagendaTimeHour = input}
                                disabled={this.state.input_disabled}
                            >{hourList}
                            </select>
                            <select name="crsagenda_time_minute" className="form-control mgL10" style={{width:"70px"}}
                                value={this.state.crsagenda_time_minute} 
                                onChange={this.handleUserSelect}
                                ref={(input) => this.textInputcrsagendaTimeMinute = input}
                                disabled={this.state.input_disabled}
                            >{minuteList}
                            </select>
                        </div>
                        <div className="mt-1">
                            { <FormErrors formErrors={this.state.formErrors.crsagenda_time}/>  }
                        </div>
                    </div>
                </div>                    
                <div className="row mt-3">
                    <label className="col-md-3" htmlFor="crsagenda_duration">Duration (minute)</label>
                    <div className="col-md-9">
                        <input type="number" className="form-control" name="crsagenda_duration"
                            style={{width:"70px"}}
                            placeholder={this.state.crsagenda_duration}
                            value={this.state.crsagenda_duration}
                            onChange={this.handleUserInput}  
                            ref={(input) => this.textInputcrsagendaDuration = input}
                            disabled={this.state.input_disabled}/>
                        <div className="mt-1">
                            { <FormErrors formErrors={this.state.formErrors.crsagenda_duration}/>  }
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <label className="col-md-3" htmlFor="crsagenda_subject">Subject</label>
                    <div className="col-md-9">
                        <textarea name="crsagenda_subject" className="form-control" 
                            rows={2} cols={30} 
                            value={this.state.crsagenda_subject} 
                            onChange={this.handleUserInput} 
                            ref={(input) => this.textInputcrsagendaSubject = input}
                            disabled={this.state.input_disabled}/>
                
                        <div className="mt-1">
                            { <FormErrors formErrors={this.state.formErrors.crsagenda_subject}/>  }
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <label className="col-md-3" htmlFor="crsagenda_description">Description</label>
                    <div className="col-md-9">
                        <textarea name="crsagenda_description" className="form-control" 
                            rows={5} cols={30} 
                            value={this.state.crsagenda_description} 
                            onChange={this.handleUserInput} 
                            ref={(input) => this.textInputcrsagendaDescription = input}
                            disabled={this.state.input_disabled}/>
                        <div className="mt-1">
                            { <FormErrors formErrors={this.state.formErrors.crsagenda_description}/>  }
                        </div>
                    </div>
                </div>
                  
                  <div className="row mt-4 mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                  </div>
                   
              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
