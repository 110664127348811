import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaRegCalendarDays, FaRegClock, FaRegFolder } from 'react-icons/fa6';
import NumberFormats from '../config/number-format';
import DateTimeFormats from '../config/date-time-format';
import { GetText } from '../config/helper';
import '../config/global';

// import '../ctinc/ct-Style-2.css';
// import '../ctinc/ct-Style.css';
// import '../ctinc/ct-Global';
// import DateTimeFormats from '../../../config/date-time-format';
// import { FormErrors } from '../ctinc/error-form';
// import Alert from '@material-ui/lab/Alert';
// import PostAddIcon from '@material-ui/icons/PostAdd';
// import DatePicker from 'react-date-picker';
// import NAimage from '../../assets/na.png';

// const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[13];
// const authKey2 = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[34];
export default class CTWorkshopDetail extends Component {
  constructor (props) {
    super(props);
      this.state = {
      stPopTitle: '',
      doc_id: '',
      memberdoc_id: '',
      member_name: '',
      member_email: '',
      course_docid: '',
      course_title: '',
      course_category: '',
      course_picture: '',
      course_language: '',
      course_date: '',
      course_stime: '',
      course_etime: '',
      course_currency: '',
      course_price: '',
      course_ticketid: '',
      course_ticketstatus: '',
      course_checkindate: '',
      registered_course: '',
      certificate_url: '',
      statExpired: false
    }
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.loadFormPurchase = this.loadFormPurchase.bind(this);
  }
    
  
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'none'; 
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    var isExpired = false;
    var dateNow = new Date();
    var courseStartTime = new Date((othersContent.joincourse_content.subcontent.courseStartTime._seconds*1000));
    var courseEndTime = new Date((othersContent.joincourse_content.subcontent.courseEndTime._seconds*1000));
    var mSecDiffStartTime = courseStartTime.valueOf() - dateNow.valueOf();
    var mSecDiffEndTime = courseEndTime.valueOf() - dateNow.valueOf();
    var diffHoursS = mSecDiffStartTime / (1000 * 60 * 60);
    var diffSecE = mSecDiffEndTime / 1000;

    if(diffSecE < 0) { isExpired = true; }
    
    this.setState({
      doc_id: othersContent.docid,
      memberdoc_id: othersContent.member_id,
      member_name: othersContent.member_fullname,
      member_email: othersContent.member_email,
      course_docid: othersContent.joincourse_content.subcontent.courseId,
      course_title: othersContent.joincourse_content.subcontent.courseTitle,
      course_category: othersContent.joincourse_content.subcontent.categoryName,
      course_picture: othersContent.joincourse_content.subcontent.coursePicture,
      course_language: othersContent.joincourse_content.subcontent.courseLanguage,
      course_date: othersContent.joincourse_content.subcontent.courseDate !== undefined? DateTimeFormats(othersContent.joincourse_content.subcontent.courseDate._seconds,"date-long") : '',
      course_stime: othersContent.joincourse_content.subcontent.courseStartTime !== undefined? DateTimeFormats(othersContent.joincourse_content.subcontent.courseStartTime._seconds,"time-24") : '',
      course_etime: othersContent.joincourse_content.subcontent.courseEndTime !== undefined? DateTimeFormats(othersContent.joincourse_content.subcontent.courseEndTime._seconds,"time-24") : '',
      course_currency: othersContent.joincourse_content.subcontent.courseCurrency,
      course_price: othersContent.joincourse_content.subcontent.coursePrice,
      course_ticketid: othersContent.joincourse_content.subcontent.ticketId !== undefined? othersContent.joincourse_content.subcontent.ticketId : '',
      course_ticketstatus: othersContent.joincourse_content.subcontent.ticketStatus !== undefined? othersContent.joincourse_content.subcontent.ticketStatus : '',
      course_checkindate: othersContent.joincourse_content.subcontent.checkInDate !== undefined?  DateTimeFormats(othersContent.joincourse_content.subcontent.checkInDate._seconds,"datetime-short") : '',
      registered_course: othersContent.joincourse_content.subcontent.createdDate !== undefined?  DateTimeFormats(othersContent.joincourse_content.subcontent.createdDate._seconds,"datetime-short") : '',
      certificate_url: othersContent.joincourse_content.subcontent.certificateUrl !== undefined? othersContent.joincourse_content.subcontent.certificateUrl : '',
      statExpired: isExpired
    });
  }
  render() {
    return (
      <div className="container mb-4">
        <div>
          <div className="mt-3">
            <div><h5>{this.state.content_title}</h5></div>
            <div>
              <span className="text-muted mt-2">{this.state.member_name}</span> 
              <span className="ms-2 text-muted">( {this.state.member_email} )</span>
              <span className="ms-4 text-muted">registered on : {this.state.registered_course}</span>
            </div>
          </div>
          <div className="mt-4">
            <div id="joincourse-detail-info">
              <Container>
                <div className="row">
                {(this.state.course_picture !== "" && this.state.course_picture !== undefined)?
                <div className="col-md-4 text-center">
                  <img className="mxw mb-4 br-8" src={this.state.course_picture} alt={this.state.course_title}/>
                </div>
                : <></>
                }
                <div className={`${
                  (this.state.course_picture !== "" && this.state.course_picture !== undefined) ? "col-md-8 " : "col-md-12 "
                  } mt-4 mt-md-0`}>
                
                  <div className="mb-2 fw-bold fs-5">{this.state.course_title}</div>
                  <div className="row">
                      <div className="col-md-3">{GetText() ? "Tanggal" : "Date"}</div>
                      <div className="col-md-9">
                        <FaRegCalendarDays className="me-1"/> {this.state.course_date}
                        <FaRegClock className="ms-2 me-1"/> {this.state.course_stime} <span className="ms-1 me-1">-</span> {this.state.course_etime}
                      </div>
                  </div>
                  <div className="row mt-1">
                      <div className="col-md-3">{GetText() ? "Kategori" : "Category"}</div>
                      <div className="col-md-9">
                        <FaRegFolder className="me-1"/> {this.state.course_category}
                      </div>
                  </div>
                  <div className="row mt-1">
                      <div className="col-md-3">{GetText() ? "Harga" : "Price"}</div>
                      <div className="col-md-9">
                      {this.state.currency === global.config.currency.name[1] ? global.config.currency.symbol[1] : global.config.currency.symbol[0]} {NumberFormats(this.state.course_price)}
                      </div>
                  </div>
                  {
                  this.state.statExpired === true? 
                  <div className="mt-1">
                      <div className="d-inline-flex bg-secondary text-white p-2"><span className="font-weight">{GetText() ? "Workshop telah berakhir" : "The workshop has ended" }</span></div>
                  </div>
                  : <></>
  }
                      {/* <p>
                        
                       
                      </p>
                      <p>
                        <strong>{GetText() ? "Kategori" : "Category"}</strong>
                        <br/>
                        <FaRegFolder className="me-1"/>{this.state.course_category}
                      </p> */}
                    
                 
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="fw-bold fs-6">Ticket </div>
                      <div className="d-inline-flex bg-warning p-2">
                        <span className="font-weight">{this.state.course_ticketid}</span>
                      </div>
                      <div className="mt-1 fieldComments">status ticket : {this.state.course_ticketstatus}</div>
                    </div>
                    <div className="col-md-6">
                      {
                        this.state.course_checkindate !== "" && 
                          <div><span className="fw-bold">Checkin On : </span><br/>{this.state.course_checkindate}</div>
                      }
                      {
                        this.state.certificate_url !== "" && <div className="mt-3"><a className="btn btn-primary" href={this.state.certificate_url} target="_blank" rel="noreferrer" download>Download Certificate</a></div>
                      }
                      
                    </div>
                   
                  </div>

                </div>
              </div>
              </Container>
            </div>
          </div>
          
        </div>
        <div id="loading-area" className="d-none loading"></div> 
      </div>
    );
  }
}
