import React, { Component } from "react";
// import WarningIcon from '@mui/icons-material/Warning';
import { FaTriangleExclamation } from "react-icons/fa6";

export default class CTUC extends Component {
	
	render() {
		return (
			<div className="mt-4">
				<div className="d-flex justify-content-center"><FaTriangleExclamation style={{color:"#ffcc00",fontSize:"120px"}} /></div>
				<div className="d-flex justify-content-center fs-1 mt-4" style={{color:"#89879F"}}>Forbidden Access</div>
				<div className="d-flex justify-content-center mt-3"  style={{color:"#89879F"}}>You don't have permission to access this page.</div>
				<div className="d-flex justify-content-center mt-2"  style={{color:"#89879F"}}>Please contact administrator to request the access.</div>
			</div>
			
		);
	}
}
